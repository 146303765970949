import axios from "axios";
// const baseURL = 'http://95.217.137.227:5000/api';
const baseURL = "https://api-v2.blazpay.com/api";
// const baseURL = "http://localhost:5000/api";

const axiosInstance = axios.create({
  baseURL,
  timeout: 20000,
});

export default axiosInstance;

import { ReactNode } from "react";

import { IoClose } from "react-icons/io5";

interface IProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  minWidth?: number;
  minHeight?: number;
  closeButton?: boolean;
}

export default function CustomModal({
  open,
  onClose,
  children,
  minWidth = 96,
  minHeight = 96,
  closeButton = false,
}: IProps) {
  return (
    <>
      {open ? (
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed sm:inset-0 inset-2 z-50 backdrop-blur">
            <div className={`relative my-6 mx-auto `}>
              <div
                className={`rounded-3xl shadow-lg relative flex flex-col w-full bg-lightBlack border border-primary min-w-${minWidth} min-h-${minHeight} p-5`}
              >
                {closeButton && (
                  <IoClose
                    className="h-4 w-4 cursor-pointer text-white absolute right-5"
                    onClick={onClose}
                  />
                )}
                {children}
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black backdrop-blur"></div>
        </div>
      ) : null}
    </>
  );
}

import React from "react";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const LimitOpenOrders = () => {
  return (
    <div className="w-full  h-fit px-10 pt-4">
      <ul className="flex w-full justify-between text-[14px] font-bold items-center">
        <li>From</li>
        <li>To</li>
        <li>Limit Price</li>
        <li>Status</li>
      </ul>
      <div className="w-full h-[160px] px-10"></div>
      <div className="flex text-xs justify-center items-center w-full gap-x-2">
        <FaArrowLeftLong className="cursor-pointer active:scale-90 hover:opacity-60 transition duration-250 ease-in-out text-base" />
        <span>Page 1 of 10</span>
        <FaArrowRightLong className="cursor-pointer active:scale-90 hover:opacity-60 transition duration-250 ease-in-out text-base" />
      </div>
    </div>
  );
};

export default LimitOpenOrders;

import React from "react";
import { IoClose } from "react-icons/io5";
import { GoAlert } from "react-icons/go";
interface ModalProps {
  isActive: boolean;
  className?: string;
  handleClose: (x: boolean) => void;
  message: string;
}
const RecipientAddModal = ({
  isActive,
  className,
  handleClose,
  message,
}: ModalProps) => {
  return (
    <>
      {isActive ? (
        <>
          <div
            className={`${className} h-fit rounded-3xl px-6 py-8 text-base font-bold bg-[#111] border border-[#333]`}
          >
            <div className="flex items-center justify-between">
              <GoAlert className="text-2xl text-[#ff3503]" />
              <IoClose
                className="text-3xl cursor-pointer hover:opacity-75 "
                onClick={() => handleClose(false)}
              />
            </div>
            <p className="mt-2">{message}</p>
          </div>
        </>
      ) : null}
    </>
  );
};

export default RecipientAddModal;

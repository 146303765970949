import React from "react";

import { ArrowSvg } from "../SvgContainer";
interface TextWrapperProps {
  className?: string;
  text: string;
  highlight?: string;
}

const TextWrapper = ({ className, text, highlight }: TextWrapperProps) => {
  return (
    <div>
      <>
        <div
          className={`w-full relative border border-[rgba(255,255,255,0.24)] ${className} `}
        >
          <p className="font-light w-full h-full top-0 left-0 px-2 py-3 text-xs">
            {highlight && (
              <>
                <span className="font-bold">{highlight}: &nbsp;</span>
              </>
            )}
            {text}
          </p>
          <ArrowSvg className="absolute z-0 left-[-2px] top-[-2px]" />
          <ArrowSvg className="absolute z-0 right-[-2px] top-[-2px] rotate-90" />
          <ArrowSvg className="absolute z-0 left-[-2px] bottom-[-2px] -rotate-90" />
          <ArrowSvg className="absolute z-0 right-[-2px] bottom-[-2px] -rotate-180" />
        </div>
      </>
    </div>
  );
};

export default TextWrapper;

import { IoIosArrowDown } from "react-icons/io";
import { IoIosSettings } from "react-icons/io";
import { IoReload } from "react-icons/io5";
import { useEffect, useRef, useState } from "react";
import TokenInput from "../../component/defi/TokenInput";
import TokenModal from "../../component/defi/TokenModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getChains } from "../../apis/chain.api";
import LoadingButton from "../../component/LoadingButton";
import { useWallet } from "../../hooks/useWallet";
import { InsufficientFundsError } from "viem";
import { getCoinGeckoId } from "../../apis/defi.api";
import { toast } from "react-toastify";
import { InsufficientFundsGasPriceValueError } from "rubic-sdk";
import { capitalize } from "../../utils/case";
import clsx from "clsx";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { handleTransactionError } from "../../utils/chains";
import Settings from "../../component/liquidity/Settings";
import "./style.css";
import { LuHistory } from "react-icons/lu";
import { Link } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import { ethers } from "ethers";
import { TradeManager } from "../../utils/trade";
import { updateQueryString } from "../../utils/functions";
import queryString from "qs";
import { DashboardPath } from "../../routes/path";
import Loader from "../../component/loader";
import { useUserSlippageTolerance } from "../../state/user/hooks";
import RecipientAddModal from "../../component/defi/RecipientAddModal";
import QuotesLoader from "../../component/QuotesLoader";
import bs58check from "bs58check";
import { PublicKey } from "@solana/web3.js";
import tokenAbi from "../../utils/abi/token.json";
import { useTronWallet } from "../../contexts/TronWalletContext";
import { useSolanaWallet } from "../../contexts/SolanaWalletContext";
import { useChain } from "../../contexts/ChainContext";
import BalanceWrapper from "../../component/defi/common/BalanceWrapper";
import RecipientAddressWrapper from "../../component/defi/common/RecipientAddressWrapper";
import QuoteWrapper from "../../component/defi/common/QuoteWrapper";
import SwapGraph from "../../component/defi/swap/SwapGraph";
import ProviderList from "../../component/defi/swap/ProviderList";

const sort = (a: any, b: any) => {
  if (a.source === "1inch" && b.source !== "1inch") return -1;
  if (a.source !== "1inch" && b.source === "1inch") return 1;
  if (a.source === "nitro" && b.source !== "nitro") return -1;
  if (a.source !== "nitro" && b.source === "nitro") return 1;
  return +b.amount - +a.amount;
};

const Swap = () => {
  const [open, setOpen] = useState<string>("");
  const [quotesShowMoreState, setQuotesShowMoreState] =
    useState<boolean>(false);
  const [openProviders, setOpenProviders] = useState(false);
  const [amount, setAmount] = useState("");
  const [fromToken, setFromToken] = useState<any>(null);
  const [toToken, setToToken] = useState<any>(null);
  const [quotes, setQuotes] = useState<any>([]);
  const [selectedQuote, setSelectedQuote] = useState<any>();
  const [isQuoteLoading, setIsQuoteLoading] = useState(false);
  const [isSwapLoading, setIsSwapLoading] = useState(false);
  const [bestProvider, setBestProvider] = useState<boolean>(true);
  const [fromBalance, setFromBalance] = useState<number>(0);
  const [coinGeckoToken, setCoinGeckoToken] = useState<any>();
  const [isCalculateActive, setIsCalculateActive] = useState<boolean>(false);
  const [isActiveRecipientAdd, setIsActiveRecipientAdd] =
    useState<boolean>(false);
  const [recipientAdd, setRecipientAdd] = useState<string>("");
  const [evmCompitable, setEvmCompitable] = useState<string>("");
  const [isActiveCheckbox, setIsActiveCheckbox] = useState<boolean>(false);
  const [btnValue, setBtnValue] = useState<number>(0);
  const { address, chainId, switchChain, provider, isConnected } = useWallet();
  const [isSettingsActive, setIsSettingsActive] = useState<boolean>(false);
  const [validRecipientAdd, setValidRecipientAdd] = useState(true);
  const qs: any = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const [isActiveRAddModal, setIsActiveRAddModal] = useState<boolean>(false);
  const [recipeintAddMandatory, setRecipientAddMandatory] =
    useState<boolean>(false);
  const managerRef = useRef(new TradeManager());
  const manager = managerRef.current;
  const [slippageTolerance, setSlippageToleran] = useUserSlippageTolerance();
  const { connectTronLink, connectSolanaWallet, connectWeb3Modal } = useChain();
  const {
    connected: isSolanaConnected,
    connect: solanaConnect,
    address: solanaAddress,
    disconnect: solanaDisconnect,
  } = useSolanaWallet();
  const {
    wallet,
    address: tronAddress,
    connected,
    select,
    connect,
    disconnect: tronDisconnect,
    signMessage,
    signTransaction,
  } = useTronWallet();
  const chainQuery = useQuery({
    queryKey: ["chains"],
    queryFn: getChains,
  });
  const handleClose = () => {
    setOpen("");
  };

  const handleTokenInterchange = () => {
    if (!fromToken && !toToken) {
      toast.error("Please select tokens!");
    }
    const from = fromToken;
    const to = toToken;

    setFromToken(to);
    setToToken(from);
    setSelectedQuote(null);
    setQuotes([]);
    setIsCalculateActive(false);
  };

  const handleAmountChange = (e: any) => {
    setAmount(e.target.value);
    setSelectedQuote(null);
    setIsQuoteLoading(false);
    setQuotes([]);
    setIsCalculateActive(false);
  };

  const handleTokenChange = (data: any) => {
    setIsQuoteLoading(false);
    setSelectedQuote(null);
    setQuotes([]);
    setIsCalculateActive(false);
    switch (open) {
      case "from": {
        setFromToken(data);
        break;
      }
      case "to":
        setToToken(data);
        break;
    }
  };

  useEffect(() => {
    handleFromTokenChange();
  }, [fromToken]);

  function bytesToHex(byteArray: Uint8Array) {
    return Array.from(byteArray, (byte) => {
      return ("0" + (byte & 0xff).toString(16)).slice(-2).toUpperCase();
    }).join("");
  }

  useEffect(() => {
    console.log("quotes:", quotes);
  }, [quotes]);

  const convertTronToEvm = (address: string) => {
    const bytes = bs58check.decode(address);
    return "0x" + bytesToHex(bytes).substring(2);
  };

  const isValidTronAddress = (address: string) => {
    if (address.startsWith("T") && address.length === 34) {
      setValidRecipientAdd(true);
      setEvmCompitable(convertTronToEvm(address));
    } else setValidRecipientAdd(false);
  };

  const isValidSolanaAddress = (address: String) => {
    try {
      const publicKey = new PublicKey(address);
      PublicKey.isOnCurve(publicKey.toBuffer());
      setValidRecipientAdd(true);
    } catch (e) {
      setValidRecipientAdd(false);
    }
  };

  const isValidEVMAddress = (address: string) => {
    return ethers.utils.isAddress(address);
  };

  useEffect(() => {
    if (recipientAdd && toToken?.chain?.id === 728126428) {
      isValidTronAddress(recipientAdd);
    } else if (recipientAdd && toToken?.chain?.id === 102)
      isValidSolanaAddress(recipientAdd);
    else if (recipientAdd) isValidEVMAddress(recipientAdd);
    !recipientAdd && setValidRecipientAdd(true);
  }, [recipientAdd]);

  const handleFromTokenChange = async () => {
    if (fromToken && fromToken.chain && address) {
      if (Number(fromToken.chain.id) === 728126428 && tronAddress) {
        try {
          const tronWeb = window.tronWeb as any;
          const encodedTokenAddress = tronWeb.address.fromHex(
            "41" + fromToken.address.substring(2)
          );
          const contract = await tronWeb.contract(
            tokenAbi,
            encodedTokenAddress
          );
          const balance = await contract.balanceOf(tronAddress).call();
          setFromBalance(
            Number(ethers.utils.formatUnits(balance, fromToken.decimals))
          );

          return;
        } catch (error) {
          console.error("Failed to fetch tron balance", error);
          setFromToken(null);
          return;
        }
      }
      //  else if(fromToken.chain.id === 102){

      // }
      if (fromToken && fromToken?.balance && fromToken?.usdBalance) {
        setFromBalance(fromToken?.balance);
      }
      // try {
      //   const response = await manager.getFromBalance({
      //     fromToken,
      //     address,
      //   });
      //   console.log("response", response);
      //   let balance = "";
      //   if (
      //     fromToken.address === "0x0000000000000000000000000000000000000000" ||
      //     fromToken.address === "0x0000000000000000000000000000000000001010"
      //   ) {
      //     balance = response["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"];
      //   } else {
      //     balance = response[fromToken.address.toString().toLowerCase()];
      //   }
      //   const decimals = fromToken.decimals || 18;
      //   setFromBalance(
      //     Number(ethers.utils.formatUnits(balance.toString(), decimals))
      //   );
      //   console.log("balance", balance);
      // } catch (error) {
      //   console.error(error);
      // }
    }
  };
  const calculateQuote = async () => {
    // if (Number(fromBalance) <= Number(amount)) {
    //   toast.error("Not enough funds");
    //   return;
    // }
    setQuotes([]);
    setSelectedQuote(null);
    setIsQuoteLoading(true);
    setIsCalculateActive(false);

    await manager.init(chainQuery.data);
    manager.setReceiverAddress(recipientAdd || address!);
    Number(fromToken.chain.id) === 728126428 &&
      manager.setSenderTronNitro(convertTronToEvm(tronAddress!));
    await manager.setWalletAddress(
      fromToken.chain.id === 728126428
        ? convertTronToEvm(tronAddress!)
        : fromToken.chain.id === "102"
        ? solanaAddress
        : address!
    );
    await manager.setEvmCompitable(evmCompitable);
    await manager
      .getBridgeQuotes({
        isCalculateActive,
        selectedQuote,
        fromChainId: fromToken.chain.id.toString(),
        toChainId: toToken.chain.id.toString(),
        fromTokenName: fromToken.name,
        toTokenName: toToken.name,
        fromChangellySymbol: fromToken?.changellySymbol,
        toChangellySymbol: toToken?.changellySymbol,
        fromTokenAddress: fromToken.address,
        toTokenAddress: toToken.address,
        amount,
        onNewQuote: (quote: any) => {
          setQuotes((prev: any) => {
            if (quote) {
              const newQuotes = [...prev, quote].sort(sort);
              // setSelectedQuote(newQuotes[0]);
              return newQuotes;
            } else return [];
          });
        },
        onFirstQuote: () => {
          setIsQuoteLoading(false);
        },
        decimals: fromToken?.decimals,
        toTokenDecimals: toToken?.decimals,
        toTokenSymbol: toToken?.symbol,
        fromtokenSymbol: fromToken?.symbol,
        fromAddress: address,
        toAddress: Boolean(recipientAdd) ? recipientAdd : address,
      })

      .finally(() => {
        if (quotes.length === 0) {
          console.log("length: ", quotes.length);
          setIsQuoteLoading(false);
        }
        setIsCalculateActive(true);
      });
  };

  const saveHistoryMutation = useMutation({
    mutationFn: async (values: any) => {
      const res = await axiosInstance.patch(
        `/defi/transaction/hash/${values?.hash}`,
        { status: values?.status, txHash: values?.txHash }
      );

      return res.data;
    },
  });

  const swap = async (slippageTolerance: number) => {
    setIsSwapLoading(true);

    // manager.setWalletAddress(address!); Todo: Uncomment

    manager.rubicSdk?.updateWalletProvider({
      EVM: {
        address: address!,
        // @ts-ignore
        core: window.ethereum!,
      },
    });

    try {
      const tronweb = window.tronWeb as any;
      const res = await selectedQuote.swap({
        walletAddress: address,
        provider,
        receiver:
          selectedQuote.route === "nitro" ? evmCompitable : recipientAdd,
      });

      //txhash,  from , to, value, router, chainId
      console.log("log: swap confirm ", res);

      const hash = res?.hash;

      localStorage.removeItem("txHash");
      toast.success("Transaction Success!");
      if (res?.id)
        saveHistoryMutation.mutate({
          status: "success",
          hash: res?.id,
          txHash: hash,
        });
      else saveHistoryMutation.mutate({ status: "success", hash });
      setSelectedQuote(null);
      setAmount("");
      setFromToken(null);
      setToToken(null);
      setQuotes([]);
      setIsCalculateActive(false);
      setBestProvider(false);
      updateQueryString(``);

      console.log("log: swap confirm ", res);
    } catch (err: any) {
      console.error("log:::::: error::", err);

      const hash = qs?.hash;
      if (hash) {
        await saveHistoryMutation.mutate({ status: "failed", hash });
        updateQueryString(``);
      }

      if (err instanceof InsufficientFundsError) {
        toast.error("Insufficient funds");
      } else if (
        err instanceof InsufficientFundsGasPriceValueError ||
        err.code === -32000
      ) {
        toast.error("Insuffient funds for gas");
      } else {
        toast.error(
          "Transaction failed. Please select a different provider or verify your balance."
        );
      }
    } finally {
      setIsSwapLoading(false);
    }
  };

  const handleQuoteSelect = (quote: any) => {
    setSelectedQuote(quote);
    // setOpenProviders(false);
  };

  useEffect(() => {
    if (
      fromToken?.chain.coin === "SOL" ||
      fromToken?.chain.coin === "TRX" ||
      toToken?.chain.coin === "SOL" ||
      toToken?.chain.coin === "TRX"
    ) {
      setIsActiveRecipientAdd(true);
      setRecipientAddMandatory(true);
    }
  }, [fromToken, toToken]);
  const getButtonState = () => {
    if (!isConnected && !connected && !isSolanaConnected) {
      return "connect_wallet";
    }
    if (!fromToken) return "select_tokens";
    if (
      fromToken.chain.id !== 728126428 &&
      fromToken.chain.id !== 102 &&
      !isConnected
    ) {
      return "connect_wallet";
    }

    if (fromToken.chain.id === 728126428 && !connected)
      return "connect_tron_wallet";
    if (fromToken.chain.id === 102 && !isSolanaConnected)
      return "connect_solana_wallet";
    if (!toToken) return "select_tokens";
    if (!amount) return "enter_amount";
    if (recipientAdd === "" && recipeintAddMandatory)
      return "Enter Recipient Address";
    if (isQuoteLoading) return "calculating";
    if (!selectedQuote) return "calculate";
    if (
      chainId !== fromToken.chain.id &&
      fromToken.chain.id !== 728126428 &&
      fromToken.chain.id !== 102
    )
      return "change_network";

    if (isSwapLoading) return "loading";

    return "swap";
  };

  const handleButtonClick = async () => {
    const buttonState = getButtonState();

    switch (buttonState) {
      case "connect_wallet":
        if (fromToken?.chain.id === 728126428) {
          return connectTronLink();
        } else if (fromToken?.chain.id === 102) {
          return connectSolanaWallet();
        } else return connectWeb3Modal();
      case "connect_tron_wallet":
        return connectTronLink();
      case "connect_solana_wallet":
        return connectSolanaWallet();
      case "calculate":
        calculateQuote();
        break;
      case "change_network":
        switchChain(fromToken.chain.metamask);
        break;
      case "swap":
        try {
          await swap(slippageTolerance);
        } catch (error) {
          handleTransactionError(error);
        }

        break;
    }
  };

  const buttonData: number[] = [25, 50, 75, 100];
  const handlePercentageClick = (percentage: number) => {
    if (btnValue === 100) {
      const calculateAmount = (fromBalance * 99) / 100;
      setAmount(calculateAmount.toFixed(6));
    } else {
      const calculatedAmount = (fromBalance * percentage) / 100;
      setAmount(calculatedAmount.toFixed(6));
    }
  };

  const coinGeckoTokenQuery = useQuery({
    queryKey: [chainId, fromToken],
    queryFn: () => getCoinGeckoId(chainId ? chainId : 137),
  });
  useEffect(() => {
    if (!coinGeckoTokenQuery.isLoading && coinGeckoTokenQuery?.data) {
      const tokens = coinGeckoTokenQuery?.data;
      // const defaultTokenData = tokens?.filter(
      //   (token: any) =>
      //     token.address === "0x0000000000000000000000000000000000000000"
      // );
      const defaultTokenData = tokens?.filter(
        (token: any) => token?.coingeckoId
      );
      console.log(defaultTokenData[0], "defualt token...3232..");
      setCoinGeckoToken(defaultTokenData[0]);
    }
  }, [chainId, coinGeckoTokenQuery.data, coinGeckoTokenQuery.isLoading]);
  const handleFromTokenClick = () => setOpen("from");
  const handleToTokenClick = () => fromToken && setOpen("to");
  console.log(fromToken, toToken, "from and to tokens");
  return (
    <section className="min-h-svh defi-gradient-container  md:pt-32 pt-44 w-full swap-container text-white flex justify-center">
      <div
        className={`lg:container w-full
          ${
            quotes.length > 0
              ? ` 
             ${
               isActiveRecipientAdd &&
               !isCalculateActive &&
               `${
                 quotesShowMoreState ? "h-[1380px]" : "h-[920px]"
               } 2xl:h-[860px] lg:h-[720px]`
             }  
             ${
               isActiveRecipientAdd &&
               isCalculateActive &&
               `${
                 quotesShowMoreState ? " h-[1360px]" : "h-[840px]"
               } 2xl:h-[800px] lg:h-[660px]`
             }  
             ${
               !isActiveRecipientAdd &&
               isCalculateActive &&
               `${
                 quotesShowMoreState ? "h-[1360px]" : " h-[760px]"
               }  2xl:h-[760px] xl:h-[660px] lg:h-[620px]`
             }
             ${
               !isActiveRecipientAdd &&
               !isCalculateActive &&
               `${
                 quotesShowMoreState ? "h-[1360px]" : "h-[840px]"
               } 2xl:h-[760px] xl:h-[630px] lg:h-[610px] `
             }
            `
              : `
            2xl:h-[700px] h-[600px] pb-5 
            `
          }
           flex lg:flex-row flex-col lg:items-start lg:gap-y-0 gap-y-6 items-center lg:gap-x-4 lg:justify-center pt-8 xl:px-16 lg:px-6 sm:px-12 px-4 mx-auto`}
      >
        {/* bridge Component */}
        <div
          className={`sm:w-[500px] w-full h-full bg-[rgba(0,0,0,0.61)]   ${
            quotes.length > 0 ? "lg:pb-0 pb-8" : "lg:pb-10 pb-28"
          } lg:w-2/5 pt-6 
         rounded-3xl border border-primary flex flex-col text-white xl:mt-0`}
        >
          <div className="px-8">
            <h2 className="xl:text-2xl text-xl">Bridge</h2>
          </div>
          <div className="flex flex-row justify-between items-center w-full 2xl:mt-2 mt-1 text-[18px] px-8">
            <h1>Trade tokens in an instant</h1>
            <div className="flex items-center gap-x-2">
              <Link to={DashboardPath.defi.bridgeHistory}>
                <LuHistory className="cursor-pointer hover:text-[rgba(255,255,255,0.5)] transition-all duration-250 ease-in-out text-2xl" />
              </Link>

              <div className="relative">
                <IoIosSettings
                  onClick={() => setIsSettingsActive((x: boolean) => !x)}
                  className="cursor-pointer text-3xl hover:text-[rgba(255,255,255,0.5)] transition-all duration-250 ease-in-out"
                />
                <Settings
                  isActive={isSettingsActive}
                  closeModal={setIsSettingsActive}
                  className="absolute -right-2 -top-2"
                />
              </div>
              {selectedQuote && (
                <IoReload onClick={calculateQuote} className="cursor-pointer" />
              )}
            </div>
          </div>
          <div className="2xl:mt-8 mt-4 sm:px-8 px-4">
            <div className="relative">
              <TokenInput
                placeholder="Enter amount"
                showChain={true}
                inputClassName="text-white"
                data={
                  fromToken
                    ? {
                        name: fromToken.name,
                        logo: fromToken.logo,
                        chainName: fromToken.chain.name,
                        chainLogo: fromToken.chain.logoURI,
                      }
                    : undefined
                }
                value={amount.toString()}
                onClick={handleFromTokenClick}
                onChange={handleAmountChange}
              />
              {fromToken && (
                <>
                  <div className="bottom-3 right-7 absolute flex items-center gap-x-2">
                    {buttonData.map((item) => (
                      <button
                        key={item}
                        onClick={() => {
                          handlePercentageClick(item);
                          setBtnValue(item);
                        }}
                        className="px-3 py-1 border rounded-full text-[10px] leading-[1.0]"
                      >
                        {item === 100 ? "MAX" : <>{item}%</>}
                      </button>
                    ))}
                  </div>
                </>
              )}
            </div>
            <BalanceWrapper fromToken={fromToken} fromBalance={fromBalance} />
            <div
              onClick={handleTokenInterchange}
              className="mx-auto mb-2 cursor-pointer 2xl:h-10 h-8 2xl:w-10 w-8 border border-white text-white 2xl:rounded-xl rounded-lg flex justify-center items-center text-xl"
            >
              <IoIosArrowDown className="2xl:text-2xl text-base" />
            </div>
            <div>
              <TokenInput
                showChain={true}
                disabled={true}
                value={selectedQuote?.amount || ""}
                data={
                  toToken
                    ? {
                        name: toToken.name,
                        logo: toToken.logo,
                        chainName: toToken.chain.name,
                        chainLogo: toToken.chain.logoURI,
                      }
                    : undefined
                }
                onClick={handleToTokenClick}
              />
            </div>

            {/*  Recipient address */}

            <RecipientAddressWrapper
              recipientAdd={recipientAdd}
              setIsActiveCheckbox={setIsActiveCheckbox}
              setRecipientAdd={setRecipientAdd}
              setIsActiveRecipientAdd={setIsActiveRecipientAdd}
              isActiveCheckbox={isActiveCheckbox}
              isActiveRecipientAdd={isActiveRecipientAdd}
            />
            {selectedQuote && (
              <QuoteWrapper
                selectedQuote={selectedQuote}
                setOpenProviders={setOpenProviders}
              />
            )}
            <LoadingButton
              onClick={handleButtonClick}
              disabled={isQuoteLoading || isSwapLoading}
              isLoading={isQuoteLoading || isSwapLoading}
              loadingText="Loading..."
              className="2xl:mt-6 mt-4 bg-gradient-to-r 2xl:text-base text-[14px] from-primary to-secondary text-white font-bold text-center w-full mx-auto block py-2 rounded-full"
            >
              {capitalize(getButtonState())}
            </LoadingButton>
            {quotes?.length != 0 && !isCalculateActive && (
              <button
                className="mt-6 bg-gradient-to-r from-primary to-secondary text-white font-bold text-center w-full mx-auto block h-10 rounded-full"
                onClick={() => {
                  setIsCalculateActive(true);
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
        <div className="lg:w-3/5 sm:w-[500px] h-full w-full flex relative 2xl:gap-y-4 gap-y-2  flex-col">
          <SwapGraph
            fromToken={coinGeckoToken}
            toToken={toToken}
            graphType="bridge"
          />
          <ProviderList
            isLoading={!isCalculateActive}
            isActive={quotes.length !== 0}
            quotes={quotes}
            tokenSymbol={toToken?.symbol}
            usdPrice={toToken?.usdPrice}
            logoURI={toToken?.logo}
            handleSelect={handleQuoteSelect}
            selectedQuote={selectedQuote}
            setShowMoreState={setQuotesShowMoreState}
            isActiveRecipientAdd={isActiveRecipientAdd}
          />
        </div>
      </div>
      {/* Recipient address alert modal */}
      <RecipientAddModal
        isActive={isActiveRAddModal}
        message="Please enter recipient address"
        handleClose={setIsActiveRAddModal}
        className="absolute top-1/2 left-1/2 -translate-x-1/2 "
      />
      <TokenModal
        open={open}
        chains={chainQuery.data}
        handleClose={handleClose}
        handleChange={handleTokenChange}
        swap={false}
        resetToToken={() => {
          return;
        }}
        bridge={true}
        handleFromTokenClick={handleFromTokenClick}
        handleToTokenClick={handleToTokenClick}
      />
    </section>
  );
};

export default Swap;

export const enum contractErrorMessage {
    EXPIRED = "Expired",
    INVALID_PATH = "Invalid path",
    EXCESSIVE_INPUT_AMOUNT = "Excessive input amount",
    INSUFFICIENT_A_AMOUNT = "Insufficient token amount",
    INSUFFICIENT_B_AMOUNT = "Insufficient token amount",
    INSUFFICIENT_OUTPUT_AMOUNT = "Insufficient output amount",
    INSUFFICIENT_VESTED_LIQUIDITY_WITH_VESTED_TIME_COMPLETED = "Insufficient vested liquidity with vested time completed"
}

export const getErrorMessage = (error: any): String => {
    if(error?.message.includes('EXPIRED'))
        return contractErrorMessage.EXPIRED;
    else if(error?.message.includes('INVALID_PATH'))
        return contractErrorMessage.INVALID_PATH;
    else if(error?.message.includes('EXCESSIVE_INPUT_AMOUNT'))
        return contractErrorMessage.EXCESSIVE_INPUT_AMOUNT;
    else if(error?.message.includes('INSUFFICIENT_A_AMOUNT'))
        return contractErrorMessage.INSUFFICIENT_A_AMOUNT;
    else if(error?.message.includes('INSUFFICIENT_B_AMOUNT'))
        return contractErrorMessage.INSUFFICIENT_B_AMOUNT;
    else if(error?.message.includes('INSUFFICIENT_OUTPUT_AMOUNT'))
        return contractErrorMessage.INSUFFICIENT_OUTPUT_AMOUNT;
    else if(error?.message.includes('INSUFFICIENT_VESTED_LIQUIDITY_WITH_VESTED_TIME_COMPLETED'))
        return contractErrorMessage.INSUFFICIENT_VESTED_LIQUIDITY_WITH_VESTED_TIME_COMPLETED;
    else if(error?.message.includes('User rejected the request.'))
        return "User rejected the request."
    else if(error?.message.includes('User denied transaction signature'))
        return "User denied transaction request."
    else if(error?.message.includes('user rejected transaction'))
        return "User rejected transaction."
    return error?.response?.message || 'Something went wrong.';
}
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { IoSwapHorizontal } from "react-icons/io5";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { GrExpand } from "react-icons/gr";
import {
  LineChart,
  AreaChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Area,
} from "recharts";
import { getCoinGeckoId, getGraphPrice } from "../../../apis/defi.api";
import { useWallet } from "../../../hooks/useWallet";

const currencies = [
  "btc",
  "eth",
  "ltc",
  "bch",
  "bnb",
  "eos",
  "xrp",
  "xlm",
  "link",
  "dot",
  "yfi",
  "usd",
  "aed",
  "ars",
  "aud",
  "bdt",
  "bhd",
  "bmd",
  "brl",
  "cad",
  "chf",
  "clp",
  "cny",
  "czk",
  "dkk",
  "eur",
  "gbp",
  "gel",
  "hkd",
  "huf",
  "idr",
  "ils",
  "inr",
  "jpy",
  "krw",
  "kwd",
  "lkr",
  "mmk",
  "mxn",
  "myr",
  "ngn",
  "nok",
  "nzd",
  "php",
  "pkr",
  "pln",
  "rub",
  "sar",
  "sek",
  "sgd",
  "thb",
  "try",
  "twd",
  "uah",
  "vef",
  "vnd",
  "zar",
  "xdr",
  "xag",
  "xau",
  "bits",
  "sats",
];
const duration = [1, 7, 30, 365];
const SwapGraph = ({
  fromToken,
  toToken,
  graphType,
  className,
}: {
  fromToken: any;
  toToken: any;
  graphType?: "bridge" | "swap" | "limit";
  className?: string;
}) => {
  const [days, setDays] = useState<number>(365);
  const [expand, setExpand] = useState<boolean>(false);
  const [data, setData] = useState([[]]);
  const [defaultToken, setDefaultToken] = useState<any>();

  const [isVsCurrencyExists, setIsVsCurrencyExists] = useState<boolean>(false);
  const [ratio, setRatio] = useState<number>(0);
  const { chainId } = useWallet();
  const effectiveChainId = chainId || 137;
  const formatDate = (date: any) => {
    const [day, month] = date.split("/");
    return `${day}/${month}`;
  };
  const formattedData = data?.map((item: any) => ({
    time: new Date(item[0]).toLocaleDateString(),
    open: item[1],
    high: item[2],
    close: item[4],
  }));
  const handleDaysChange = (d: number) => {
    setDays(d);
  };

  const graphQuery = useQuery({
    queryKey: [
      fromToken?.coingeckoId,
      toToken?.symbol,
      days,
      isVsCurrencyExists,
      defaultToken,
    ],
    queryFn: () =>
      getGraphPrice({
        fromToken: fromToken
          ? fromToken?.coingeckoId
          : defaultToken?.coingeckoId,
        toToken: isVsCurrencyExists ? toToken?.symbol : "usd",
        days: days,
      }),
    enabled: !!(fromToken || defaultToken),
  });
  const defaultTokenQuery = useQuery({
    queryKey: [chainId, fromToken],
    queryFn: () => getCoinGeckoId(effectiveChainId),
  });

  useEffect(() => {
    if (!defaultTokenQuery.isLoading && defaultTokenQuery?.data) {
      const tokens = defaultTokenQuery?.data;
      const defaultTokenData = tokens?.filter(
        (token: any) =>
          token.address === "0x0000000000000000000000000000000000000000"
      );
      setDefaultToken(defaultTokenData[0]);
      console.log(defaultToken?.data, "default tokenData");
    }
  }, [chainId, defaultTokenQuery.data, defaultTokenQuery.isLoading]);
  useEffect(() => {
    if (graphQuery.isSuccess) {
      setData(graphQuery?.data?.data);
      const res = graphQuery?.data?.data.flat(Infinity);
      setRatio(res[res.length - 1]);
    }
  }, [graphQuery.data]);
  useEffect(() => {
    if (!toToken) setIsVsCurrencyExists(false);
    else {
      const toCurrency = currencies.includes(
        toToken?.name.toLowerCase() || toToken?.symbol.toLowerCase()
      );
      setIsVsCurrencyExists(toCurrency);
    }
  }, [toToken]);

  return (
    <div
      style={{ backdropFilter: "blur(28px)" }}
      className={`${
        expand
          ? "fixed h-[calc(100%-80px)] top-20 bottom-2 z-[50] right-0"
          : `${graphType === "limit" ? "h-[300px]" : "h-full"} relative`
      } ${className} ${
        graphType == "limit" ? "p-6" : "px-12 py-11"
      } lg:block hidden rounded-3xl w-full border  bg-[rgba(0,0,0,0.3)] border-primary`}
    >
      {/* Expan button */}
      <div
        className={`absolute ${
          graphType === "limit" ? "top-2 right-4" : "top-3 right-5"
        }`}
      >
        <GrExpand
          onClick={() => setExpand((x) => !x)}
          className={`${
            graphType === "limit" ? "text-xs font-bold" : "2xl:text-2xl text-xl"
          } cursor-pointer`}
        />
      </div>
      <div className="w-full flex justify-between items-center">
        <div className="flex items-center gap-x-2">
          <div className="flex items-center gap-x-1">
            {fromToken ? (
              <>
                <img
                  src={fromToken?.logoURI || fromToken?.logo}
                  className={`rounded-full ${
                    graphType === "limit"
                      ? "h-5 w-5 border"
                      : "h-8 w-8 border-2 "
                  }`}
                  alt=""
                />
              </>
            ) : (
              <>
                <img
                  src={defaultToken?.logoURI}
                  className={`rounded-full ${
                    graphType === "limit"
                      ? "h-5 w-5 border"
                      : "h-8 w-8 border-2 "
                  }`}
                  alt=""
                />
              </>
            )}

            {!isVsCurrencyExists ? (
              <>
                <div
                  className={` ${
                    graphType === "limit" ? "h-5 w-5" : "h-8 w-8"
                  } rounded-full bg-white text-black flex items-center justify-center`}
                >
                  <AiOutlineDollarCircle className="text-xl" />
                </div>
              </>
            ) : (
              <>
                <img
                  src={toToken?.logo}
                  className={`rounded-full ${
                    graphType === "limit"
                      ? "h-5 w-5 border"
                      : "h-8 w-8 border-2 "
                  }`}
                  alt=""
                />
              </>
            )}
          </div>
          <h1 className={`${graphType === "limit" ? "text-xs" : "text-base"}`}>
            {fromToken ? fromToken?.symbol : defaultToken?.symbol} /
            {isVsCurrencyExists ? toToken?.symbol : "USD"}
          </h1>
          <IoSwapHorizontal
            className={`${graphType === "limit" ? "text-base" : "text-2xl"}`}
          />
        </div>
        <div
          className={` border px-1 ${
            graphType === "limit" ? "text-[8px] py-[6px]" : " py-2 text-xs"
          } border-primary rounded-full`}
        >
          {duration.map((d) => {
            return (
              <span
                onClick={() => handleDaysChange(d)}
                className={`${
                  graphType === "limit" ? "px-2 py-0.5" : "px-4 py-1 "
                }  cursor-pointer rounded-full ${
                  days === d && "border border-primary"
                }`}
              >
                {d === 1 && "24Hr"}
                {d === 7 && "1W"}
                {d === 30 && "1M"}
                {d === 365 && "1Y"}
              </span>
            );
          })}
        </div>
      </div>
      <div className="flex mt-1 w-full font-bold items-end gap-x-2">
        <h1 className={`${graphType === "limit" ? "text-base" : "text-2xl"}`}>
          {ratio.toFixed(5)}
        </h1>
        <p
          className={`${
            graphType === "limit" && "text-[10px] relative -top-[2px]"
          }`}
        >
          {fromToken ? fromToken?.symbol : defaultToken?.symbol}/
          {isVsCurrencyExists ? toToken?.symbol : "USD"}
        </p>
        <span className="text-[#ff7c0c] text-base">-0.254(-0.25%)</span>
      </div>
      <div className="w-full relative h-full">
        <ResponsiveContainer
          width="100%"
          height="80%"
          className={`absolute
           ${
             expand
               ? "scale-x-100 2xl:bottom-16 bottom-12 2xl:-right-2 -right-5"
               : `
              ${
                graphType === "limit"
                  ? "-right-4 top-2"
                  : `bottom-12 scale-x-[1.1] -right-5`
              }
            `
           }
          
                 
            `}
        >
          <AreaChart data={formattedData} margin={{ left: 0, right: 0 }}>
            <defs>
              {/* Gradient for Open */}
              <linearGradient id="colorOpen" x1="0" y1="0" x2="0" y2="1">
                <stop offset="10%" stopColor="#ffa500" stopOpacity={0.85} />
                <stop offset="50%" stopColor="#ffa500" stopOpacity={0.1} />
                <stop offset="90%" stopColor="#ffa500" stopOpacity={0} />
              </linearGradient>

              {/* Gradient for High */}
              <linearGradient id="colorHigh" x1="0" y1="0" x2="0" y2="1">
                <stop offset="10%" stopColor="#ffa500" stopOpacity={0.85} />
                <stop offset="50%" stopColor="#ffa500" stopOpacity={0.2} />
                <stop offset="90%" stopColor="#ffa500" stopOpacity={0} />
              </linearGradient>

              {/* Gradient for Close */}
              <linearGradient id="colorClose" x1="0" y1="0" x2="0" y2="1">
                <stop offset="10%" stopColor="#ffa500" stopOpacity={0.85} />
                <stop offset="50%" stopColor="#ffa500" stopOpacity={0.5} />
                <stop offset="90%" stopColor="#ffa500" stopOpacity={0} />
              </linearGradient>
            </defs>

            <XAxis dataKey="time" fontSize={10} />
            <YAxis orientation="right" fontSize={12} />
            <Tooltip
              contentStyle={{
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                borderRadius: "12px",
              }}
              labelStyle={{ color: "#333", fontSize: "12px" }}
              itemStyle={{ color: "#333", fontSize: "12px" }}
              formatter={(value: number, name) => [`${value.toFixed(2)}`, name]}
            />
            <Area
              type="monotone"
              dataKey="open"
              stroke="transparent"
              fill="transparent"
              name="Open"
            />
            <Area
              type="monotone"
              dataKey="high"
              stroke="transparent"
              fill="transparent"
              name="High"
            />
            <Area
              type="monotone"
              dataKey="close"
              stroke="transparent"
              fill="url(#colorClose)"
              name="Close"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SwapGraph;

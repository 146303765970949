import React, { useState } from "react";
import ProviderListCard from "../common/ProviderListCard";
import AllProviders from "../common/AllProviders";
import "../../style.css";
interface ProviderListProps {
  isActive: boolean;
  handleSelect?: (x: any) => void;
  quotes: any;
  isLoading?: boolean;
  tokenSymbol: string;
  usdPrice: any;
  logoURI: string;
  selectedQuote: any;
  setShowMoreState: (x: boolean) => void;
  isActiveRecipientAdd: boolean;
}
const ProviderList = ({
  isActive,
  handleSelect,
  quotes,
  isLoading,
  tokenSymbol,
  usdPrice,
  logoURI,
  selectedQuote,
  setShowMoreState,
  isActiveRecipientAdd,
}: ProviderListProps) => {
  const [isShowMoreActive, setIsShowMoreActive] = useState<boolean>(false);
  return (
    <>
      {isActive ? (
        <div
          className={`${
            isShowMoreActive &&
            "absolute py-10 lg:h-full  h-[600px] top-0 right-0"
          }  rounded-3xl  w-full bg-black  border border-primary py-4 lg:px-8 sm:px-6 px-4`}
        >
          <h1 className="font-bold text-xl">Provider List</h1>
          {isLoading && (
            <div className="flex gap-x-1 text-[14px]">
              <span>
                More Providers can get close, But They are Delaying the answer
              </span>
              <div className="flex text-white gap-x-[1px] text-xl relative -top-1 items-center">
                <span className="dot-loader-1 dot-loader">.</span>
                <span className="dot-loader-2 dot-loader">.</span>
                <span className="dot-loader-3 dot-loader">.</span>
              </div>
            </div>
          )}
          <ProviderListCard
            isActive={!isShowMoreActive}
            handleShowMore={setIsShowMoreActive}
            isShowMoreActive={isShowMoreActive}
            route={quotes[0]?.route}
            amount={quotes[0]?.amount}
            source={quotes[0]?.source}
            tokenSymbol={tokenSymbol}
            usdPrice={usdPrice}
            logoURI={logoURI}
            setShowMoreState={setShowMoreState}
          />

          <AllProviders
            handleSelect={handleSelect}
            selectedQuote={selectedQuote}
            isActive={isShowMoreActive}
            logoURI={logoURI}
            tokenSymbol={tokenSymbol}
            usdPrice={usdPrice}
            quotes={quotes}
            isLoading={isLoading}
            isActiveRecipientAdd={isActiveRecipientAdd}
          />
        </div>
      ) : null}
    </>
  );
};

export default ProviderList;

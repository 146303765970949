import React, { ReactNode } from "react";
import { IoClose } from "react-icons/io5";
import { LinkSvg } from "./SvgContainer";
import { Link } from "react-router-dom";
interface TransactionModalProps {
  className?: string;
  isActive: boolean;
  title: string;
  message: string;
  link?: string;
  handleIsActive: (x: boolean) => void;
  btnValue: string;
  icon: ReactNode;
  handleBtn: any;
}
const TransactionModal = ({
  className,
  isActive,
  title,
  message,
  link,
  handleIsActive,
  btnValue,
  icon,
  handleBtn,
}: TransactionModalProps) => {
  return (
    <>
      {isActive ? (
        <div
          className="w-full min-h-screen overflow-hidden xs:px-0 px-4 z-[10000] fixed top-0 left-0 flex justify-center items-center bg-[rgba(0,0,0,0.24)]"
          style={{ backdropFilter: "blur(10px)" }}
        >
          <div
            style={{ backdropFilter: "blur(25px)" }}
            className={` h-[486px] xs:w-[480px] w-full z-[100] border border-primary rounded-3xl bg-[rgba(0,0,0,1)] p-7 flex flex-col items-center`}
          >
            <div className="flex items-center justify-between w-full">
              <h1 className="text-xl font-bold">{title}</h1>
              <IoClose
                className="text-2xl text-[#ff3503] cursor-pointer"
                onClick={() => handleIsActive(false)}
              />
            </div>
            <div
              style={{
                boxShadow: "0 0 200px rgba(255,85,01,1)",
              }}
              className="mt-32 h-[108px] w-[108px] rounded-full border-[3px] border-primary flex items-center justify-center bg-[#010101]"
            >
              {icon}
            </div>
            <div className="flex gap-x-2 items-center mt-10">
              <h1 className="text-xl font-bold text-white">{message} </h1>
              {link && (
                <a
                  href={link}
                  className="mt-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkSvg />
                </a>
              )}
            </div>
            <Link to="/defi/liquidity">
              <button
                onClick={() => handleIsActive(false)}
                className="px-20 py-3 mt-6 text-base text-white text-center rounded-full bg-gradient-to-r from-primary to-secondary "
              >
                {btnValue}
              </button>
            </Link>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TransactionModal;

import { Outlet } from "react-router-dom";
import MainNav from "./Navbars/MainNav";
import "./style.css";
export default function MainLayout() {
  return (
    <div className="text-white bg-mainlayout">
      <MainNav />

      <Outlet />
    </div>
  );
}

import React from "react";

const LimitExpiredOrders = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      Expired orders
    </div>
  );
};

export default LimitExpiredOrders;

import { IoIosSettings, IoIosArrowDown } from "react-icons/io";
import { IoReload } from "react-icons/io5";
import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import TokenInput from "../../component/defi/TokenInput";
import TokenModal from "../../component/defi/TokenModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getChains } from "../../apis/chain.api";
import LoadingButton from "../../component/LoadingButton";
import { useWallet } from "../../hooks/useWallet";
import { InsufficientFundsError } from "viem";
import { toast } from "react-toastify";
import { InsufficientFundsGasPriceValueError } from "rubic-sdk";
import { capitalize } from "../../utils/case";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { handleTransactionError } from "../../utils/chains";
import { HiOutlineSwitchVertical } from "react-icons/hi";

import { LuHistory } from "react-icons/lu";
import { Link } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import { ethers } from "ethers";
import { TradeManager } from "../../utils/trade";
import { updateQueryString } from "../../utils/functions";
import queryString from "qs";
import { DashboardPath } from "../../routes/path";
import Settings from "../../component/liquidity/Settings";
import Loader from "../../component/loader";
import {
  useUserSlippageTolerance,
  useUserDeadline,
} from "../../state/user/hooks";
import {
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from "../../state/swap/hooks";
import useWrapCallback, { WrapType } from "../../hooks/useWrapCallback";
import { Field } from "../../state/swap/actions";
import {
  Trade,
  JSBI,
  CurrencyAmount,
  Currency,
  ChainId,
  ETHER,
} from "blazpay-sdk";
import {
  ApprovalState,
  useApproveCallbackFromTrade,
} from "../../hooks/useApproveCallback";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import { useSwapCallback } from "../../hooks/useSwapCallback";
import {
  computeTradePriceBreakdown,
  warningSeverity,
} from "../../utils/prices";
import { useCurrency } from "../../hooks/Tokens";
import { ONE_BIPS, NATIVE_SYMBOLS } from "../../constants";
import "./style.css";
import RecipientAddModal from "../../component/defi/RecipientAddModal";
import QuotesLoader from "../../component/QuotesLoader";
import { getCoinGeckoId } from "../../apis/defi.api";
import BalanceWrapper from "../../component/defi/common/BalanceWrapper";
import SwapGraph from "../../component/defi/swap/SwapGraph";
import ProviderList from "../../component/defi/swap/ProviderList";
import QuoteWrapper from "../../component/defi/common/QuoteWrapper";
import RecipientAddressWrapper from "../../component/defi/common/RecipientAddressWrapper";
import { getErrorMessage } from "../../utils/contractErrorHandler";
export const sort = (a: any, b: any) => {
  return +b.amount - +a.amount;
};

const Swap = () => {
  const [open, setOpen] = useState<string>("");
  const [quotesShowMoreState, setQuotesShowMoreState] =
    useState<boolean>(false);
  const [openProviders, setOpenProviders] = useState(false);
  const [amount, setAmount] = useState("");
  const [fromToken, setFromToken] = useState<any>(null);
  const [toToken, setToToken] = useState<any>(null);
  const [quotes, setQuotes] = useState<any>([]);
  const [selectedQuote, setSelectedQuote] = useState<any>();
  const [isQuoteLoading, setIsQuoteLoading] = useState(false);
  const [isSwapLoading, setIsSwapLoading] = useState(false);
  const [bestProvider, setBestProvider] = useState<boolean>(true);
  const [fromBalance, setFromBalance] = useState<number>(0);
  const { open: openWallet } = useWeb3Modal();
  const { address, chainId, switchChain, provider } = useWallet();
  const [isSettingsActive, setIsSettingsActive] = useState<boolean>(false);
  const [isActiveRecipientAdd, setIsActiveRecipientAdd] =
    useState<boolean>(false);
  const [isActiveCheckbox, setIsActiveCheckbox] = useState<boolean>(false);
  const [btnValue, setBtnValue] = useState<number>(0);
  const [recipientAdd, setRecipientAdd] = useState<string>("");
  const qs: any = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const [currencyIdA, setCurrencyIdA] = useState<string>("");
  const [currencyIdB, setCurrencyIdB] = useState<string>("");
  const [foundBlazpayPair, setFoundBlazpayPair] = useState<boolean>(false);
  const [isCalculateActive, setIsCalculateActive] = useState<boolean>(false);
  const [showInverted, setShowInverted] = useState<boolean>(false);
  const loadedChainId: ChainId = useMemo(() => chainId as ChainId, [chainId]);
  const currencyA = useCurrency(currencyIdA);
  const currencyB = useCurrency(currencyIdB);
  // get custom setting values for user
  const [deadline] = useUserDeadline();
  const [coinGeckoToken, setCoinGeckoToken] = useState<any>(null);
  const [allowedSlippage] = useUserSlippageTolerance();
  // swap state
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const trade = showWrap ? undefined : v2Trade;
  const abortController = new AbortController(); // Create a new controller
  const { signal } = abortController; // Extract the signal

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade?.outputAmount,
      };

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();
  const isValid = !swapInputError;
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  // modal and loading
  const [
    { showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash },
    setSwapState,
  ] = useState<{
    showConfirm: boolean;
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ""
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  const route = trade?.route;

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  );
  const noRoute = !route;
  const checkLiqudity = () => {
    if (route?.path[0]?.address === route?.pairs[0].token0.address) {
      const reserve0 =
        Number(route?.pairs[0].reserve0.raw.toString()) /
        10 ** (route?.pairs[0].token0.decimals || 18);
      if (Number(formattedAmounts["INPUT"]) > reserve0) return false;
    } else {
      const reserve1 =
        Number(route?.pairs[0].reserve1.raw.toString()) /
        10 ** (route?.pairs[0].token1.decimals || 18);
      if (Number(formattedAmounts["INPUT"]) > reserve1) return false;
    }
    return true;
  };

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT]
  );
  const atMaxAmountInput = Boolean(
    maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput)
  );

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  );

  const { priceImpactWithoutFee, realizedLPFee } =
    computeTradePriceBreakdown(trade);

  const handleSwap = useCallback(() => {
    // if (priceImpactWithoutFee) {
    //   return
    // }

    if (!swapCallback) {
      return;
    }
    setSwapState((prevState) => ({
      ...prevState,
      attemptingTxn: true,
      swapErrorMessage: undefined,
      txHash: undefined,
    }));

    const isLiquidityValid = checkLiqudity();
    if (!isLiquidityValid) {
      toast.success("Not enough liquidity");
      return;
    }

    swapCallback()
      .then((hash) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: undefined,
          txHash: hash,
        }));
        toast.success("Transaction Success!");
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: error.message,
          txHash: undefined,
        }));
        toast.error(message);
      });
  }, [priceImpactWithoutFee, swapCallback, setSwapState]);

  // errors

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, showConfirm: false }));

    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, "");
    }
  }, [onUserInput, txHash, setSwapState]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, tradeToConfirm: trade }));
  }, [trade]);

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
    },
    [onCurrencySelection, setApprovalSubmitted]
  );

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact());
    }
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency);
    },
    [onCurrencySelection]
  );

  const [isActiveRAddModal, setIsActiveRAddModal] = useState<boolean>(false);
  const [recipientAddMantory, setRecipientAddMantory] =
    useState<boolean>(false);
  const managerRef = useRef(new TradeManager());
  const manager = managerRef.current;
  const [slippageTolerance, setSlippageTolerance] = useUserSlippageTolerance();
  const chainQuery = useQuery({
    queryKey: ["chains"],
    queryFn: getChains,
  });
  const handleClose = () => {
    setOpen("");
  };

  const handleTokenInterchange = () => {
    if (!fromToken && !toToken) {
      toast.error("Please select tokens!");
      return;
    }
    // onSwitchTokens();
    // const from = fromToken;
    // const to = toToken;
    // setFromToken(to);
    // setToToken(from);
    // setCurrencyIdA(to.address);
    // setCurrencyIdB(from.address);
    // setSelectedQuote(null);
    // setQuotes([]);
    // setFoundBlazpayPair(false);
    // setIsCalculateActive(false);
    // setAmount("");
    const from = fromToken;
    const to = toToken;
    setFromToken(to);
    setToToken(from);
    setSelectedQuote(null);
    setQuotes([]);
    setIsCalculateActive(false);
  };

  const handleAmountChange = (e: any) => {
    setAmount(e.target.value);
    setQuotes([]);
    setSelectedQuote(null);
    setIsQuoteLoading(false);
  };

  useEffect(() => {
    handleTypeInput(amount.toString());
  }, [amount]);

  const handleTokenChange = (data: any) => {
    setIsQuoteLoading(false);
    setQuotes([]);
    setSelectedQuote(null);
    switch (open) {
      case "from": {
        setFromToken(data);
        setCurrencyIdA(data.address);
        break;
      }
      case "to": {
        setToToken(data);
        setCurrencyIdB(data.address);
        break;
      }
    }
  };

  useEffect(() => {
    setApprovalSubmitted(false); // reset 2 step UI for approvals
    onCurrencySelection(Field.INPUT, currencyA!);
  }, [currencyA]);

  useEffect(() => {
    setApprovalSubmitted(false); // reset 2 step UI for approvals
    onCurrencySelection(Field.OUTPUT, currencyB!);
  }, [currencyB]);

  useEffect(() => {
    handleFromTokenChange();
  }, [fromToken, toToken]);

  const handleFromTokenChange = async () => {
    if (fromToken && fromToken.chain && address) {
      try {
        const response = await manager.getFromBalance({
          fromToken,
          address,
        });
        let balance = "";
        if (
          fromToken.address === "0x0000000000000000000000000000000000000000" ||
          fromToken.address === "0x0000000000000000000000000000000000001010"
        ) {
          balance = response["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"];
        } else {
          balance = response[fromToken.address.toString().toLowerCase()];
        }
        const decimals = fromToken.decimals || 18;
        setFromBalance(
          Number(ethers.utils.formatUnits(balance.toString(), decimals))
        );
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  const calculateQuote = async () => {
    // if (Number(fromBalance) <= Number(amount)) {
    //   toast.error("Not enough funds");
    //   return;
    // }
    setQuotes([]);
    setFoundBlazpayPair(false);
    setIsCalculateActive(false);
    setSelectedQuote(null);
    setIsQuoteLoading(true);

    const gasPrice = await provider?.getGasPrice();
    const gasPriceInGwei = ethers.utils?.formatUnits(gasPrice!, "gwei");

    const gasPricee = Math.floor(Number(gasPriceInGwei));

    console.log("log::", gasPrice, gasPriceInGwei);

    await manager.init(chainQuery.data);

    await manager
      .getSwapQuotes({
        fromChainId: fromToken.chain.id.toString(),
        toChainId: toToken.chain.id.toString(),
        fromTokenName: fromToken.name,
        toTokenName: toToken.name,
        fromTokenAddress: fromToken.address,
        toTokenAddress: toToken.address,
        amount,
        onNewQuote: (quote: any) => {
          setQuotes((prev: any) => {
            if (quote) {
              const newQuotes = [...prev, quote].sort(sort);
              // setSelectedQuote(newQuotes[0]);
              return newQuotes;
            } else return [];
          });
        },
        onFirstQuote: () => {
          setIsQuoteLoading(false);
        },
        decimals: fromToken?.decimals,
        toTokenDecimals: toToken?.decimals,
        toTokenSymbol: toToken?.symbol,
        fromtokenSymbol: fromToken?.symbol,
        fromAddress: address,
        toAddress: Boolean(recipientAdd) ? recipientAdd : address,
        gasPrice: gasPricee,
      })
      .finally(() => {
        if (quotes.length === 0) {
          setIsQuoteLoading(false);
        }
      });

    manager.setWalletAddress(address!);
    setIsCalculateActive(true);
    const isLiquidityValid = checkLiqudity();
    if (
      isLiquidityValid &&
      formattedAmounts[Field.OUTPUT] &&
      !foundBlazpayPair
    ) {
      setQuotes((prev: any) => {
        const quote = {
          slippage: allowedSlippage / 100,
          priceImpact: priceImpactWithoutFee
            ? priceImpactWithoutFee.lessThan(ONE_BIPS)
              ? "<0.01"
              : `${priceImpactWithoutFee.toFixed(2)}`
            : "-",
          networkFee: "-",
          platformFee: realizedLPFee
            ? `${realizedLPFee.toSignificant(4)} ${
                trade?.inputAmount.currency == ETHER
                  ? NATIVE_SYMBOLS[loadedChainId]
                  : trade?.inputAmount.currency.symbol
              }`
            : "-",
          route: "Blazpay",
          source: "Dex",
          amount: formattedAmounts[Field.OUTPUT],
        };
        const newQuotes = [...prev, quote].sort(sort);
        // setSelectedQuote(newQuotes[0]);
        return newQuotes;
      });
      setFoundBlazpayPair(true);
    }
    console.log("completed setting quotes");
    setIsCalculateActive(true);
  };

  useEffect(() => {
    if (!isCalculateActive) return;
    const isLiquidityValid = checkLiqudity();
    if (
      isLiquidityValid &&
      formattedAmounts[Field.OUTPUT] &&
      !foundBlazpayPair
    ) {
      setQuotes((prev: any) => {
        const quote = {
          slippage: allowedSlippage / 100,
          priceImpact: priceImpactWithoutFee
            ? priceImpactWithoutFee.lessThan(ONE_BIPS)
              ? "<0.01"
              : `${priceImpactWithoutFee.toFixed(2)}`
            : "-",
          networkFee: "-",
          platformFee: realizedLPFee
            ? `${realizedLPFee.toSignificant(4)} ${
                trade?.inputAmount.currency.symbol
              }`
            : "-",
          route: "Blazpay",
          source: "Dex",
          amount: formattedAmounts[Field.OUTPUT],
        };
        const newQuotes = [...prev, quote].sort(sort);
        setSelectedQuote(newQuotes[0]);
        return newQuotes;
      });
      setFoundBlazpayPair(true);
    }
  }, [formattedAmounts[Field.OUTPUT], isCalculateActive]);

  useEffect(() => {
    if (!address) {
      setQuotes([]);
      setFoundBlazpayPair(false);
      setIsCalculateActive(false);
      setSelectedQuote(null);
    }
  }, [address]);
  const saveHistoryMutation = useMutation({
    mutationFn: async (values: any) => {
      const res = await axiosInstance.patch(
        `/defi/transaction/hash/${values?.hash}`,
        { status: values?.status }
      );
      return res.data;
    },
  });
  const swap = async (slippageTolerance: number) => {
    setIsSwapLoading(true);
    try {
      if (selectedQuote.route == "Blazpay") {
        if (address) {
          if (showWrap) {
            if (!Boolean(wrapInputError)) {
              onWrap?.();
            }
          } else {
            if (noRoute && userHasSpecifiedInputOutput) {
              toast.error("Insufficient liquidity for this trade.");
            } else {
              if (
                showApproveFlow &&
                approval === ApprovalState.NOT_APPROVED &&
                !approvalSubmitted
              ) {
                await approveCallback();
              } else {
                if (!(!isValid || !!swapCallbackError)) {
                  handleSwap();
                } else {
                  toast.error("unexpected issues arised");
                }
              }
            }
          }
        }
        setIsSwapLoading(false);
        return;
      }
    } catch (error: any) {
      toast.error(
        error?.message?.includes("user rejected transaction")
          ? "user rejected transaction"
          : "unexpected error"
      );
      return;
    }

    manager.setWalletAddress(address!);

    manager.rubicSdk?.updateWalletProvider({
      EVM: {
        address: address!,
        // @ts-ignore
        core: window.ethereum!,
      },
    });

    try {
      const res = await selectedQuote.swap({
        provider,
        receiver: recipientAdd,
      });

      //txhash,  from , to, value, router, chainId
      const hash = res?.hash;

      const data = {
        moduleName: "swap",
        walletAddress: address,
        route: selectedQuote?.route,
        txHash: res?.hash,
        from: res?.from,
        to: res?.to,
        // value: ethers.utils.formatUnits(res.tx?.value),
        txFrom: {
          chainId: fromToken?.chain?.id,
          tokenName: fromToken?.name,
          // value: ethers.utils.formatUnits(res.tx?.value),
        },
        txTo: {
          chainId: toToken?.chain?.id,
          tokenName: toToken?.name,
          value: res.value,
        },
        status: "success",
      };

      localStorage.removeItem("txHash");
      toast.success("Transaction Success!");
      //@ts-ignore
      saveHistoryMutation.mutate({ status: "success", hash });
      setSelectedQuote(null);
      setAmount("");
      setFromToken(null);
      setToToken(null);
      setQuotes([]);
      setFoundBlazpayPair(false);
      setIsCalculateActive(false);
      setBestProvider(false);
      updateQueryString(``);
    } catch (err: any) {
      const hash = qs?.hash;
      console.log("log: err", err);
      if (hash) {
        await saveHistoryMutation.mutate({ status: "failed", hash });
        updateQueryString(``);
      }

      if (err instanceof InsufficientFundsError) {
        toast.error("Insufficient funds");
      } else if (
        err instanceof InsufficientFundsGasPriceValueError ||
        err.code === -32000
      ) {
        toast.error("Insuffient funds for gas");
      } else {
        toast.error(
          "Transaction failed. Please select a different provider or verify your balance."
        );
      }
    } finally {
      setIsSwapLoading(false);
    }
  };

  const handleQuoteSelect = (quote: any) => {
    setSelectedQuote(quote);
    // setOpenProviders(false);
  };

  const getButtonState = () => {
    if (!address) return "connect_wallet";
    if (!fromToken || !toToken) return "select_tokens";
    if (!amount) return "enter_amount";
    if (recipientAdd === "" && recipientAddMantory)
      return "Enter Recipient Address";
    if (isQuoteLoading) return "calculating";
    if (!selectedQuote) return "calculate";
    if (chainId !== fromToken.chain.id) return "change_network";
    if (isSwapLoading) return "loading";

    return "swap";
  };

  const handleButtonClick = async () => {
    const buttonState = getButtonState();

    switch (buttonState) {
      case "connect_wallet":
        return openWallet({ view: "Connect" });

      case "calculate":
        calculateQuote();
        break;
      case "change_network":
        switchChain(fromToken.chain.metamask);
        break;
      case "swap":
        try {
          await swap(slippageTolerance);
        } catch (error) {
          handleTransactionError(error);
        }

        break;
    }
  };

  const resetToToken = () => {
    setToToken(null);
  };
  useEffect(() => {
    if (fromToken?.name === toToken?.name) {
      setToToken(null);
    }
  }, [fromToken, toToken]);

  useEffect(() => {
    if (fromToken?.chain.coin === "SOL" || fromToken?.chain.coin === "TRX") {
      setIsActiveRecipientAdd(true);
      setRecipientAddMantory(true);
    }
  }, [fromToken, toToken]);

  const buttonData: number[] = [25, 50, 75, 100];
  const handlePercentageClick = (percentage: number) => {
    if (btnValue === 100) {
      const calculateAmount = (fromBalance * 99) / 100;
      setAmount(calculateAmount.toFixed(6));
    } else {
      const calculatedAmount = (fromBalance * percentage) / 100;
      setAmount(calculatedAmount.toFixed(6));
    }
  };
  const handleFromTokenClick = () => setOpen("from");
  const handleToTokenClick = () => fromToken && setOpen("to");

  const coinGeckoTokenQuery = useQuery({
    queryKey: [chainId, fromToken],
    queryFn: () => getCoinGeckoId(chainId ? chainId : 137),
  });
  useEffect(() => {
    if (!coinGeckoTokenQuery.isLoading && coinGeckoTokenQuery?.data) {
      const tokens = coinGeckoTokenQuery?.data;
      // const defaultTokenData = tokens?.filter(
      //   (token: any) =>
      //     token.address === "0x0000000000000000000000000000000000000000"
      // );
      const defaultTokenData = tokens?.filter(
        (token: any) => token?.coingeckoId
      );
      console.log(defaultTokenData[0], "defualt token...3232..");
      setCoinGeckoToken(defaultTokenData[0]);
    }
  }, [chainId, coinGeckoTokenQuery.data, coinGeckoTokenQuery.isLoading]);

  useEffect(() => {
    console.log(
      selectedQuote?.route != "Blazpay",
      isQuoteLoading || isSwapLoading,
      showWrap,
      Boolean(wrapInputError),
      showApproveFlow,
      approval === ApprovalState.PENDING,
      isValid,
      priceImpactSeverity,
      swapCallbackError,
      "conditions"
    );
  });
  return (
    <section className="min-h-svh defi-gradient-container   md:pt-32 pt-44 w-full swap-container text-white flex justify-center">
      <div
        className={`lg:container w-full
          ${
            quotes.length > 0
              ? ` 
             ${
               isActiveRecipientAdd &&
               !isCalculateActive &&
               `${
                 quotesShowMoreState ? "h-[1380px]" : "h-[920px]"
               } 2xl:h-[860px] lg:h-[720px]`
             }  
             ${
               isActiveRecipientAdd &&
               isCalculateActive &&
               `${
                 quotesShowMoreState ? " h-[1360px]" : "h-[840px]"
               } 2xl:h-[800px] lg:h-[660px]`
             }  
             ${
               !isActiveRecipientAdd &&
               isCalculateActive &&
               `${
                 quotesShowMoreState ? "h-[1360px]" : " h-[760px]"
               }  2xl:h-[760px] xl:h-[660px] lg:h-[620px]`
             }
             ${
               !isActiveRecipientAdd &&
               !isCalculateActive &&
               `${
                 quotesShowMoreState ? "h-[1360px]" : "h-[840px]"
               } 2xl:h-[760px] xl:h-[630px] lg:h-[610px] `
             }
            `
              : `
            2xl:h-[700px] h-[600px] pb-5 
            `
          }
           flex lg:flex-row flex-col lg:items-start lg:gap-y-0 gap-y-6 items-center lg:gap-x-4 lg:justify-center pt-8 xl:px-16 lg:px-6 sm:px-12 px-4 mx-auto`}
      >
        <div
          style={{ backdropFilter: "blur(28px)" }}
          className={`sm:w-[500px] w-full h-full bg-[rgba(0,0,0,0.61)]   ${
            quotes.length > 0 ? "lg:pb-0 pb-8" : "lg:pb-10 pb-28"
          } lg:w-2/5 pt-6 
         rounded-3xl border border-primary flex flex-col bg-[rgba(0,0,0,0.3)] text-white xl:mt-0`}
        >
          <div className="px-8">
            <h2 className="xl:text-2xl text-xl">Swap</h2>
          </div>

          <div className="flex flex-row justify-between items-center w-full 2xl:mt-2 mt-1 text-[18px] px-8">
            <h1>Trade tokens in an instant</h1>
            <div className="flex items-center gap-x-2">
              <Link to={DashboardPath.defi.swapHistory}>
                <LuHistory className="cursor-pointer hover:text-[rgba(255,255,255,0.50)] transition-all duration-250 ease-in-out text-2xl" />
              </Link>
              <div className="relative">
                <IoIosSettings
                  onClick={() => setIsSettingsActive((x: boolean) => !x)}
                  className="cursor-pointer  hover:text-[rgba(255,255,255,0.50)] transition-all duration-250 ease-in-out text-2xl"
                />
                <Settings
                  isActive={isSettingsActive}
                  closeModal={setIsSettingsActive}
                  className="absolute -right-2 -top-2"
                />
              </div>
              {selectedQuote && (
                <IoReload
                  onClick={calculateQuote}
                  className="cursor-pointer text-2xl hover:text-[rgba(255,255,255,0.24)]"
                />
              )}
            </div>
          </div>
          <div className="2xl:mt-8 mt-4 sm:px-8 px-4">
            <div className="relative">
              <TokenInput
                placeholder="Enter amount"
                showChain={true}
                inputClassName="text-white"
                data={
                  fromToken
                    ? {
                        name: fromToken.symbol,
                        logo: fromToken.logo,
                        chainName: fromToken.chain.name,
                        chainLogo: fromToken.chain.logoURI,
                      }
                    : undefined
                }
                value={amount.toString()}
                onClick={() => setOpen("from")}
                onChange={handleAmountChange}
              />
              {fromToken && (
                <>
                  <div className="bottom-3 right-7 absolute flex items-center gap-x-2">
                    {buttonData.map((item) => (
                      <button
                        key={item}
                        onClick={() => {
                          setQuotes([]);
                          setSelectedQuote(null);
                          setIsQuoteLoading(false);
                          handlePercentageClick(item);
                          setBtnValue(item);
                        }}
                        className="px-3 py-1 border rounded-full 2xl:text-[10px] text-[8px] leading-[1.0]"
                      >
                        {item === 100 ? "MAX" : <>{item}%</>}
                      </button>
                    ))}
                  </div>
                </>
              )}
            </div>
            <BalanceWrapper fromToken={fromToken} fromBalance={fromBalance} />
            <div
              onClick={handleTokenInterchange}
              className="mx-auto mb-2 cursor-pointer 2xl:h-10 h-8 2xl:w-10 w-8 border border-white text-white 2xl:rounded-xl rounded-lg flex justify-center items-center text-xl"
            >
              <HiOutlineSwitchVertical className="2xl:text-2xl text-base" />
            </div>
            <div>
              <TokenInput
                showChain={false}
                disabled={true}
                value={selectedQuote?.amount || ""}
                data={
                  toToken
                    ? {
                        name: toToken.symbol,
                        logo: toToken.logo,
                        chainName: toToken.chain.name,
                        chainLogo: toToken.chain.logoURI,
                      }
                    : undefined
                }
                onClick={() => fromToken && setOpen("to")}
              />
            </div>

            {/*  Recipient address */}
            <RecipientAddressWrapper
              setIsActiveRecipientAdd={setIsActiveRecipientAdd}
              setRecipientAdd={setRecipientAdd}
              setIsActiveCheckbox={setIsActiveCheckbox}
              recipientAdd={recipientAdd}
              isActiveCheckbox={isActiveCheckbox}
              isActiveRecipientAdd={isActiveRecipientAdd}
            />
            <QuoteWrapper
              selectedQuote={selectedQuote}
              setOpenProviders={setOpenProviders}
            />
            <LoadingButton
              onClick={handleButtonClick}
              disabled={
                selectedQuote?.route != "Blazpay"
                  ? isQuoteLoading || isSwapLoading
                  : showWrap
                  ? Boolean(wrapInputError)
                  : showApproveFlow && approval === ApprovalState.PENDING
              }
              isLoading={
                selectedQuote?.route != "Blazpay"
                  ? isQuoteLoading || isSwapLoading
                  : approval === ApprovalState.PENDING
              }
              loadingText={
                selectedQuote?.route != "Blazpay"
                  ? "Loading..."
                  : approval === ApprovalState.PENDING
                  ? "Approving..."
                  : "Loading..."
              }
              className="2xl:mt-6 mt-4 bg-gradient-to-r 2xl:text-base text-[14px] from-primary to-secondary text-white font-bold text-center w-full mx-auto block py-2 rounded-full"
            >
              {selectedQuote?.route != "Blazpay"
                ? capitalize(getButtonState())
                : showApproveFlow
                ? approval === ApprovalState.PENDING
                  ? "Approving..."
                  : approvalSubmitted && approval === ApprovalState.APPROVED
                  ? "Swap"
                  : `Approve ${currencies[Field.INPUT]?.symbol}`
                : "Swap"}
            </LoadingButton>
            {quotes?.length != 0 && !isCalculateActive && (
              <button
                className="2xl:mt-6 mt-4 bg-gradient-to-r from-primary to-secondary text-white font-bold text-center w-full mx-auto block h-10 rounded-full"
                onClick={() => {
                  setIsCalculateActive(true);
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
        <div className="lg:w-3/5 sm:w-[500px] h-full w-full flex relative 2xl:gap-y-4 gap-y-2  flex-col">
          <SwapGraph
            fromToken={coinGeckoToken}
            toToken={toToken}
            graphType="swap"
          />
          <ProviderList
            isLoading={!isCalculateActive}
            isActive={quotes.length !== 0}
            quotes={quotes}
            tokenSymbol={toToken?.symbol}
            usdPrice={toToken?.usdPrice}
            logoURI={toToken?.logo}
            handleSelect={handleQuoteSelect}
            selectedQuote={selectedQuote}
            setShowMoreState={setQuotesShowMoreState}
            isActiveRecipientAdd={isActiveRecipientAdd}
          />
        </div>
      </div>
      {/* Recipient address alert modal */}
      <RecipientAddModal
        isActive={isActiveRAddModal}
        message="Please enter recipient address"
        handleClose={setIsActiveRAddModal}
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      />
      {/* Token list modal */}
      <TokenModal
        open={open}
        chains={chainQuery.data}
        handleClose={handleClose}
        handleChange={handleTokenChange}
        swap={true}
        resetToToken={resetToToken}
        handleFromTokenClick={handleFromTokenClick}
        handleToTokenClick={handleToTokenClick}
      />
    </section>
  );
};

export default Swap;

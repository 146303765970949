import React, { useState } from 'react'
import { SwapAiIcon } from '../../liquidity/SvgContainer';
import Pen from '../../../assets/pen.svg';
import Chat from '../../../assets/chat.svg';
import HistoryIcon from '../../../assets/history.svg';
import Eye from '../../../assets/eye.svg';
import Flag from '../../../assets/flag.svg';
import SwapAiHero from './SwapAiHero';
import SendIcon from '../../../assets/SendIcon.svg';
import SwapAILogo from '../../../assets/SwapAI Icon.svg';
import SwapAiChat from './SwapAiChat';

interface Message {
    text: string;
    sender: "user" | "bot";
}


const MainContainer = () => {

    const[messages, setMessages] = useState<Message[]>([]);
    const[userInput, setUserInput] = useState<string>('');
    const[isChatting, setIsChatting] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInput(e.target.value);
    }

    const sendMessage = (): void => {
        if (userInput.trim()) {
          // Add user's message to state
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: userInput, sender: "user" },
          ]);
          setIsChatting(true)
          setUserInput("");
    
          // Simulate bot response
          setTimeout(() => {
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: "Hello! How can I help you?", sender: "bot" },
            ]);
          }, 1000);
        }
      };

  return (
    <div className='flex mt-40 w-[1318px] h-[580px] xl:h-[600px] 2xl:h-[650px] border border-[#FF5500] mx-auto rounded-xl '>
        <div className='w-[320px] h-full border-r border-[#7A7A7A] '>

            <div>
                <div className='mt-6 ml-6 flex gap-3 items-center'>
                    <h1 className='text-xl font-bold'>Swap AI</h1>
                    <span><SwapAiIcon/></span>
                </div>

                <div className='w-full flex justify-center mt-12 '>
                    <button className='w-[232px] h-[46px] border border-[#FF5500] rounded-[43px] text-xl font-medium shadow-lg shadow-[#FF5500]'>+  New Swap</button>
                </div>
            </div>

            <div className=' flex flex-col gap-5 mt-16 ml-6'>
                <h1 className='text-sm font-normal'>Menu</h1>
                <div className=''>
                    <ul className='flex flex-col gap-5 text-base font-normal '>
                        <li className='flex items-center gap-3'><img src={Pen} alt='eye_logo'/>Ai writes </li>
                        <li className='flex items-center gap-3'><img src={Chat} alt='eye_logo'/>Chats</li>
                        <li className='flex items-center gap-3'><img src={HistoryIcon} alt='eye_logo'/>History</li>
                        <li className='flex items-center gap-3'><img src={Eye} alt='eye_logo'/>Explore Swap AI</li>
                        <li className='flex items-center gap-3'><img src={Flag} alt='eye_logo'/>Support</li>
                    </ul>
                </div>
            </div>

        </div>
        <div className='w-full'>
            {
                isChatting ? <SwapAiChat messages={messages}/> : <SwapAiHero/>
            }
            <div className='w-[747px] flex items-center h-14 border border-[#FF5500] rounded-[49px] mx-auto lg:mt-[24px] xl:mt-7 2xl:mt-10'>
                <input 
                    className='w-10/12 h-[50px] ml-8 bg-inherit outline-none '
                    type='text'
                    placeholder='Ask a question'
                    value={userInput}
                    onChange={handleChange}
                    onKeyDown={(e) => e.key === "Enter" && sendMessage()}

                />
                <div className='w-[14%] flex items-center justify-between mr-4 '>
                    <img className='cursor-pointer' onClick={sendMessage} src={SendIcon} alt='send' />
                    <img className='h-14 cursor-pointer' onClick={() => setIsChatting(false)} src={SwapAILogo} alt='swap-ai' />
                </div>
                
            </div>
            <div className=' mx-auto mt-2'>
                <p className='text-[10px] text-center text-[#FFFFFF]'>Swap Ai can make mistakes. Consider checking important Information.</p>
            </div>
            
        </div>
    </div>
  )
}

export default MainContainer;
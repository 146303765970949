import React from "react";

const QuoteWrapper = ({
  selectedQuote,
  setOpenProviders,
}: {
  selectedQuote: any;
  setOpenProviders: (x: boolean) => void;
}) => {
  return (
    <>
      {selectedQuote ? (
        <div className="flex 2xl:text-base text-xs flex-col gap-y-1 mt-3">
          <div className="flex flex-row justify-between w-full px-5">
            <p>Slippage</p>
            <p>{selectedQuote?.slippage} %</p>
          </div>
          <div className="flex flex-row justify-between w-full px-5">
            <p>Price Impact</p>
            <p>{selectedQuote?.priceImpact} %</p>
          </div>
          {/* <div className="flex flex-row justify-between w-full px-5">
      <p>Network Fee</p>
      <p>{selectedQuote.networkFee} $</p>
    </div> */}
          <div className="flex flex-row justify-between w-full px-5">
            <p>Platform Fee</p>
            <p>
              {selectedQuote?.platformFee}{" "}
              {selectedQuote?.route != "Blazpay" ? "$" : ""}
            </p>
          </div>
          <div className="flex flex-row justify-between w-full px-5">
            <p>Provider</p>
            <div
              className="cursor-pointer"
              onClick={() => setOpenProviders(true)}
            >
              {selectedQuote?.route || "Change"}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default QuoteWrapper;

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import { useDispatch } from 'react-redux'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import transactions from './transactions/reducer'
import application from './application/reducer'
import swap from './swap/reducer'
import mint from './mint/reducer'
import multicall from './multicall/reducer'
import lists from './lists/reducer'
import user from './user/reducer'
import burn from './burn/reducer'

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'notifications']

const persistConfig = {
  key: 'primary',
  whitelist: PERSISTED_KEYS,
  blacklist: ['profile'],
  storage,
  version: 1,
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    transactions,
    application,
    swap,
    mint,
    multicall,
    lists,
    user,
    burn
  }),
)

// eslint-disable-next-line import/no-mutable-exports
// let store: ReturnType<typeof makeStore>

// export function makeStore(preloadedState = undefined) {
//   return configureStore({
//     reducer: persistedReducer,
//     middleware: (getDefaultMiddleware) =>
//       getDefaultMiddleware({
//         thunk: true,
//         serializableCheck: {
//           ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//         },
//       }),
//     devTools: true,
//     preloadedState,
//   })
// }

// export const initializeStore = (preloadedState: any = undefined) => {
//   let _store = store ?? makeStore(preloadedState)

//   // After navigating to a page with an initial Redux state, merge that state
//   // with the current state in the store, and create a new store
//   if (preloadedState && store) {
//     _store = makeStore({
//       ...store.getState(),
//       ...preloadedState,
//     })
//     // Reset the current store
//     store = undefined as any
//   }

//   // For SSG and SSR always create a new store
//   if (typeof window === 'undefined') return _store

//   // Create the store once in the client
//   if (!store) {
//     store = _store
//   }

//   return _store
// }

export const store = configureStore({
  reducer: persistedReducer,
});

/*
  @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
*/
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch<AppDispatch>()

// export const persistor = persistStore(store, undefined, () => {
//   store.dispatch(updateVersion())
// })

export const persistor = persistStore(store);

// export function useStore(initialState: any) {
//   return useMemo(() => initializeStore(initialState), [initialState])
// }
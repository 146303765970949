import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useWallet } from "../../hooks/useWallet";
import { fetchHistory } from "../../apis/defi.api";
import { getChains } from "../../apis/chain.api";
import { getAllCurrencies } from "../../apis/transak.api";
import { HistorySvg } from "../../component/liquidity/SvgContainer";

type Image = {
  large: string;
  small: string;
  thumb: string;
};

type Network = {
  name: string;
  fiatCurrenciesNotSupported: any[];
  chainId: string;
};

type Token = {
  _id: string;
  coinId: string;
  address: string;
  addressAdditionalData: boolean;
  createdAt: string;
  decimals: number;
  image: Image;
  image_bk: Image;
  isAllowed: boolean;
  isIgnorePriceVerification: boolean;
  isPayInAllowed: boolean;
  isPopular: boolean;
  isStable: boolean;
  kycCountriesNotSupported: any[];
  maxAmountForPayIn: number | null;
  minAmountForPayIn: number | null;
  name: string;
  network: Network;
  roundOff: number;
  symbol: string;
  tokenIdentifier: string | null;
  tokenType: string;
  uniqueId: string;
  [key: string]: any;
};

interface HistoryRecord {
  coinPath: string;
  down: number;
  downCoinName: string;
  up: number;
  upCoinName: string;
  date: string;
}

const History = (params: any) => {
  const { address } = useWallet();
  const navigate = useNavigate();
  const [cryptoCurrency, setCryptoCurrency] = useState<Token[]>([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState("");

  const startDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);
  const [selectOption, setSelectedOption] = useState<string>("success");
  const handleStartDateClick = (e: any) => {
    console.log(e);
    startDateRef.current?.showPicker();
  };
  const handleEndDateClick = (e: any) => {
    console.log(e);
    endDateRef.current?.showPicker();
  };
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedStartDate = e.target.value;
    setStartDate(selectedStartDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedEndDate = e.target.value;
    setEndDate(selectedEndDate);
  };

  useEffect(() => {
    const getCurrencies = async () => {
      try {
        const res = await getAllCurrencies();
        setCryptoCurrency(res?.crypto || []);
      } catch (error) {
        console.log(error);
      }
    };
    getCurrencies();
  }, []);

  const findTokenBySymbol = (symbol: string): string => {
    let url = "/assets/icon.png";
    cryptoCurrency.forEach((token) => {
      if (token.symbol === symbol) {
        url = token.image.small;
      }
    });
    return url;
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [
      "history",
      address,
      page,
      limit,
      params.module,
      selectOption,
      startDate,
      endDate,
    ],
    queryFn: fetchHistory,
    enabled: !!address,
  });

  const chainQuery = useQuery({
    queryKey: ["chains"],
    queryFn: getChains,
  });

  function getChainName(chainId: number) {
    return chainQuery.data?.find((chain: any) => chain.id === chainId)?.name;
  }

  const count = data?.count;
  const filteredData = useMemo(() => {
    return data?.data;
  }, [data?.data]);

  return (
    <section className="min-h-svh swap-container md:pt-32 pt-44 w-full">
      <div className="container mx-auto text-white px-6 w-full pt-8">
        <div className="flex">
          <div className="flex justify-start md:w-fit w-full items-center">
            <button onClick={() => navigate(-1)}>
              <IoIosArrowBack className="text-3xl  cursor-pointer hover:text-[rgba(255,255,255,0.5)] transition-all duration-250 ease-in-out" />
            </button>
            <h1 className="text-3xl xl:ml-4 ml-2">
              {params.module.charAt(0).toUpperCase() + params.module.slice(1)}{" "}
              History
            </h1>
          </div>
        </div>
      </div>
      <ul className=" container mt-10 mx-auto flex flex-col lg:flex-row xl:gap-y-0 gap-y-4 sm:px-0 px-4  justify-between items-center w-full list-none lg:text-2xl md:text-xl md:px-8 text-white text-center">
        <ul className="flex lg:justify-start justify-between w-full">
          <li
            onClick={handleStartDateClick}
            className="flex sm:flex-row flex-col items-center relative"
          >
            <div className="flex items-center gap-x-2 sm:text-base text-xs cursor-pointer">
              <span>Start:</span>
              <p className="px-4 py-2 rounded-full border border-white text-center ">
                {startDate ? startDate : "mm/dd/yyyy"}
              </p>
            </div>
            <input
              ref={startDateRef}
              type="date"
              id="start"
              value={startDate}
              onChange={handleStartDateChange}
              className="hidden absolute top-12 right-0"
            />
          </li>

          <li
            onClick={handleEndDateClick}
            className="flex sm:flex-row flex-col items-center xl:ml-5 ml-0 relative"
          >
            <div className="flex items-center gap-x-2 sm:text-base text-xs cursor-pointer">
              <span>End:</span>
              <p className="px-4 py-2 rounded-full border border-white text-center ">
                {endDate ? endDate : "mm/dd/yyyy"}
              </p>
            </div>
            <input
              type="date"
              ref={endDateRef}
              value={endDate}
              onChange={handleEndDateChange}
              className="hidden absolute top-12 right-0"
            />
          </li>
        </ul>
        <ul className="flex flex-row xl:3/5 w-full justify-between  2xl:text-xl lg:text-base sm:text-xl text-base">
          <li>
            <button
              className={`bg-transparent 2xl:px-12 md:px-8 2xl:py-3 sm:py-2 py-1 px-4 border rounded-full active:border-primary ${
                selectOption == "all" ? "border-primary" : ""
              }`}
              onClick={() => {
                setSelectedOption("all");
                setPage(0);
              }}
            >
              All
            </button>
          </li>
          <li>
            <button
              className={`bg-transparent 2xl:px-12 md:px-8 2xl:py-3 sm:py-2 py-1 px-4 border rounded-full active:border-primary ${
                selectOption == "pending" ? "border-primary" : ""
              }`}
              onClick={() => {
                setSelectedOption("pending");
                setPage(0);
              }}
            >
              Pending
            </button>
          </li>
          <li>
            <button
              className={`bg-transparent 2xl:px-12 md:px-8 2xl:py-3 sm:py-2 py-1 px-4 border rounded-full active:border-primary ${
                selectOption == "success" ? "border-primary" : ""
              }`}
              onClick={() => {
                setSelectedOption("success");
                setPage(0);
              }}
            >
              Success
            </button>
          </li>
          <li>
            <button
              className={`bg-transparent 2xl:px-12 sm:px-8 2xl:py-3 sm:py-2 py-1 px-4 border rounded-full active:border-primary ${
                selectOption == "failed" ? "border-primary" : ""
              }`}
              onClick={() => {
                setSelectedOption("failed");
                setPage(0);
              }}
            >
              Failed
            </button>
          </li>
        </ul>
      </ul>

      {!!count ? (
        <>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-10 min-h-[500px] uppercase">
            <hr className="border-b border-primary" />
            {filteredData?.map((item: any, index: number) => (
              <div
                key={item._id}
                className="flex flex-row w-full items-center border-b border-primary"
              >
                <Link to={`/defi/history/${index}`} className="w-full">
                  <div className="flex flex-wrap w-full justify-between px-4 md:px-20 lg:px-36">
                    <div className="flex items-center">
                      <img
                        src={findTokenBySymbol(item.txTo?.tokenName)}
                        className="p-2 overflow-auto"
                      />
                      <div className="flex flex-col">
                        <div className="flex flex-row">
                          -{item.txFrom?.value} {item.txFrom?.tokenName}
                        </div>
                        <div className="flex flex-row text-xl ">
                          +{item.txTo?.value} {item.txTo?.tokenName}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-end p-3">
                      {item?.createdAt.slice(0, 10)}{" "}
                      {item?.createdAt.slice(11, 19)}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <section className="flex flex-col w-full items-center text-center sm:px-0 px-6 pb-12 sm:mt-20 mt-12">
            <HistorySvg className="sm:scale-100 scale-75" />
            <h1 className="sm:text-3xl text-2xl">No History Found</h1>
            <p className="sm:text-base text-[14px]">
              when you make a transaction transaction history will be found here
            </p>
          </section>
        </>
      )}

      <div className="flex items-center justify-end gap-3 md:pb-3">
        {page > 0 && (
          <div onClick={() => setPage(page - 1)} className="flex items-center">
            <IoIosArrowBack className="text-white 2xl:text-3xl text-xl ml-5 cursor-pointer" />
            <label htmlFor="next" className="text-xl cursor-pointer">
              Prev Page
            </label>
          </div>
        )}
        {data?.count > limit * (page + 1) && (
          <div className="flex items-center" onClick={() => setPage(page + 1)}>
            <label htmlFor="next" className="text-xl cursor-pointer">
              Next Page
            </label>
            <IoIosArrowForward className="text-white 2xl:text-3xl text-xl ml-5 cursor-pointer" />
          </div>
        )}
      </div>
    </section>
  );
};

export default History;

import React, { useEffect, useRef } from 'react'
import './Scrollbar.css'
import SwapAiLogo from '../../../assets/SwapAI Icon.svg'
import UserIcon from '../../../assets/UserIcon.svg'
import UserMessage from './UserMessage';
import BotMessage from './BotMessage';

interface Message {
    text: string;
    sender: "user" | "bot";
  }
  
interface ChatWindowProps {
    messages: Message[];
  }

const SwapAiChat = ({messages} : ChatWindowProps ) => {

    const chatWindowRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
          }
    }, [messages])

  return (
    <div className=''>

        {/* <div className='w-full h-20 flex justify-between items-center'>
            <div className='flex items-center ml-5 '>
                <h1 className='text-[13px] font-bold'>Swap AI</h1>
                <img className='h-10' src={SwapAiLogo} alt='swap-ai' />
            </div>

            <img className='h-[60px] mr-5' src={UserIcon} alt='user-img' />
        </div> */}

        <div ref={chatWindowRef} className="flex-grow h-[515px] p-4 overflow-y-auto overflow-x-scroll no-scrollbar">
            {messages.map((message, index) => (
            <div key={index}>
            {message.sender === "user" ? (
                <UserMessage text={message.text} />
            ) : (
                <BotMessage text={message.text} />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default SwapAiChat;
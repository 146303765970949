import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { Switch } from "../forms";
import {
  useUserSlippageTolerance,
  useUserDeadline,
} from "../../state/user/hooks";

const MAX_SLIPPAGE = 5000;

const Modal = ({
  className,
  closeModal,
}: {
  className?: string;
  closeModal: (x: boolean) => void;
}) => {
  const slippageValues = [0.1, 0.5, 1.0];
  const transactionDeadlineValues = [5, 10, 15];
  const [isAuto, setIsAuto] = useState(true);
  const [isMaxSlippageDropdownActive, setIsMaxSlippageDropdownActive] =
    useState<boolean>(false);
  const [isTransactionDeadlineActive, setIsTransactionDeadlineActive] =
    useState<boolean>(false);
  const [userSlippageTolerance, setUserslippageTolerance] =
    useUserSlippageTolerance();
  const [maxSlippageValue, setMaxSlippageValue] = useState(
    userSlippageTolerance / 100
  );
  const [deadline, setDeadline] = useUserDeadline();
  const [minutes, setMinutes] = useState(deadline / 60); // deadline in minutes
  const handleInputChange = (e: number) => {};
  const changeMinutes = (e: any) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setMinutes(value);
    }
  };
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = evt.target;
    setMaxSlippageValue(parseFloat(inputValue));
  };

  const handleMiniuteChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = evt.target;
    setMinutes(parseInt(inputValue, 10));
  };

  useEffect(() => {
    try {
      const rawValue = maxSlippageValue * 100;
      if (!Number.isNaN(rawValue) && rawValue > 0 && rawValue < MAX_SLIPPAGE) {
        setUserslippageTolerance(rawValue);
        // setError(null)
      } else {
        // setError(translateString(1144, 'Enter a valid slippage percentage'))
      }
    } catch {
      // setError(translateString(1144, 'Enter a valid slippage percentage'))
    }
  }, [maxSlippageValue, setUserslippageTolerance]);
  // Updates local storage if value is valid
  useEffect(() => {
    try {
      const rawValue = minutes * 60;
      if (!Number.isNaN(rawValue) && rawValue > 0) {
        setDeadline(rawValue);
        // setError(null)
      } else {
        // setError(translateString(1150, 'Enter a valid deadline'))
      }
    } catch {
      // setError(translateString(1150, 'Enter a valid deadline'))
    }
  }, [minutes, setDeadline]);

  useEffect(() => {
    if (isMaxSlippageDropdownActive) {
      setIsTransactionDeadlineActive(false);
    }
    if (isTransactionDeadlineActive) {
      setIsMaxSlippageDropdownActive(false);
    }
  }, [isMaxSlippageDropdownActive, isTransactionDeadlineActive]);

  return (
    <div
      className={`h-[266px] w-[316px] rounded-3xl border border-primary p-4 bg-black z-50  ${className} `}
    >
      <div className="flex justify-between relative flex flex-col w-full">
        <div className="flex justify-between items-center w-full">
          <h1 className="text-sm font-bold">Default trade options</h1>
          <IoMdClose
            className="text-xl font-bold cursor-pointer"
            onClick={() => closeModal(false)}
          />
        </div>

        <div className="flex justify-between items-start gap-x-4 mt-4">
          <p className="text-xs text-gray-500">
            The blazpay client selects the chepest trade option factoring price
            and network costs.
          </p>
          <Switch />
        </div>
      </div>

      <div className="flex justify-between my-2 relative">
        <p className="text-sm">Max Slippage</p>

        <p className="text-xs flex items-center gap-x-2 curosr-pointer relative">
          <span> Auto </span>{" "}
          <IoIosArrowDown
            onClick={() => setIsMaxSlippageDropdownActive((x: boolean) => !x)}
            className={`text-xl mt-1 hover:text-[rgba(255,255,255,0.75)] ${
              isMaxSlippageDropdownActive && "rotate-180"
            } 
            transition-all ease-in-out duration-150
            `}
          />
          <ul
            className={`bg-[#222] rounded-2xl ${
              !isMaxSlippageDropdownActive && "hidden"
            } absolute top-7 right-0 z-50`}
          >
            {slippageValues.map((value: number) => {
              return (
                <li
                  onClick={() => {
                    setMaxSlippageValue(value);
                    setIsMaxSlippageDropdownActive(false);
                  }}
                  className="px-8 py-2 text-xs text-white text-center cursor-pointer hover:bg-[#111] rounded-xl"
                >
                  {value}
                </li>
              );
            })}
          </ul>
        </p>
      </div>

      <div className="flex  gap-2 relative">
        <div className="border border-primary rounded-3xl py-1 flex justify-between px-1 items-center text-xs w-full">
          <button
            onClick={() => setIsAuto(true)}
            className={`${
              isAuto ? "border border-primary" : "border border-transparent"
            } rounded-full px-[10px]  pt-[2px] py-[4px] leading-[1.1]`}
          >
            Auto
          </button>
          <button
            onClick={() => setIsAuto(false)}
            className={`${
              !isAuto ? "border border-primary" : "border border-transparent"
            } rounded-full px-[10px] pt-[2px] py-[4px] leading-[1.1]`}
          >
            Custom
          </button>
        </div>

        <input
          type="number"
          step="0.1"
          min="0.1"
          className="border rounded-3xl py-1 flex justify-center text-center items-center px-2 text-xs w-full outline-none bg-transparent"
          value={maxSlippageValue}
          onChange={handleChange}
        />
        <p className="text-xs text-white font-bold absolute top-2 right-2">%</p>
      </div>

      <div className="flex justify-between my-2">
        <p className="text-sm">Transaction Deadline</p>

        <p className="text-xs flex items-center gap-1 curosr-pointer relative">
          <span> {minutes}m </span>{" "}
          <IoIosArrowDown
            onClick={() => setIsTransactionDeadlineActive((x: boolean) => !x)}
            className={`text-xl mt-1 hover:text-[rgba(255,255,255,0.75)] ${
              isTransactionDeadlineActive && "rotate-180"
            } 
                    transition-all ease-in-out duration-150
                    `}
          />
          <ul
            className={`bg-[#222] rounded-2xl ${
              !isTransactionDeadlineActive && "hidden"
            } absolute top-7 right-0`}
          >
            {transactionDeadlineValues.map((value: number) => {
              return (
                <li
                  onClick={() => {
                    setMinutes(value);
                    setIsTransactionDeadlineActive(false);
                  }}
                  className="px-8 py-2 text-xs text-white text-center cursor-pointer hover:bg-[#111] rounded-xl"
                >
                  {value}
                </li>
              );
            })}
          </ul>
        </p>
      </div>

      <div className="border rounded-3xl py-1 flex items-center px-2">
        <input
          className=" w-full outline-none bg-transparent flex-1 text-center "
          value={minutes}
          onChange={handleMiniuteChange}
        />
        <p className="text-end text-xs">Minutes</p>
      </div>
    </div>
  );
};

const Settings = ({
  isActive,
  className,
  closeModal,
}: {
  isActive: boolean;
  className?: string;
  closeModal: (x: boolean) => void;
}) => {
  return (
    <>
      {isActive ? (
        <Modal className={className} closeModal={closeModal} />
      ) : null}
    </>
  );
};

export default Settings;

import React from "react";

const LimitOrderHistory = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      Order History
    </div>
  );
};

export default LimitOrderHistory;

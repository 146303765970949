import clsx from 'clsx';
import { ButtonHTMLAttributes, ReactNode } from 'react';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode | string | undefined;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  variant?: 'outlined' | 'contained' | 'text' | 'containedNew';
  type?: 'button' | 'submit' | 'reset';
}

export default function Button({
  children,
  startIcon,
  endIcon,
  variant = 'text',
  type = 'button',
  className,
  ...others
}: IButtonProps) {
  return (
    <button
      {...others}
      className={clsx(
        'align-middle select-none  font-semibold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-2 px-6 rounded-full text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none flex items-center justify-center gap-3',
        variant === 'outlined' && 'bg-none border border-primary',
        variant === 'contained' && 'bg-gradient',
        variant === 'containedNew' && 'bg-primary',
        className
      )}
      type={type}
    >
      {startIcon && startIcon}
      {children}
      {endIcon && endIcon}
    </button>
  );
}

export function TokenBtn() {
  return (
    <div>
      <svg
        width="343"
        height="57"
        viewBox="0 0 343 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M341.5 43L333 55L24.5 53L2 15.5L11 2L318.5 4L341.5 43Z"
          fill="url(#paint0_linear_3946_575)"
          stroke="url(#paint1_linear_3946_575)"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3946_575"
            x1="-205"
            y1="29"
            x2="178"
            y2="29"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF7A00" />
            <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3946_575"
            x1="4.5"
            y1="34"
            x2="379.5"
            y2="34"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFF268" />
            <stop offset="1" stop-color="#E64914" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export function NextArrow(props: any) {
  return (
    <svg
      width="17"
      height="30"
      viewBox="0 0 17 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      className={props.className}
    >
      <path
        d="M0.578867 16.3897C-0.188431 15.5951 -0.166331 14.329 0.62823 13.5617L13.5763 1.05785C14.3709 0.290546 15.637 0.312647 16.4043 1.10721C17.1716 1.90177 17.1495 3.16791 16.355 3.9352L4.84554 15.0497L15.9601 26.5592C16.7274 27.3537 16.7053 28.6199 15.9107 29.3872C15.1162 30.1545 13.85 30.1324 13.0827 29.3378L0.578867 16.3897ZM4.98219 17.0524L1.98264 17.0001L2.05245 13.0007L5.05199 13.053L4.98219 17.0524Z"
        fill="#FDB005"
      />
    </svg>
  );
}

export function PrevArrow(props: any) {
  return (
    <svg
      width="17"
      height="30"
      viewBox="0 0 17 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      className={props.className}
    >
      <path
        d="M16.4382 16.3897C17.2055 15.5951 17.1834 14.329 16.3889 13.5617L3.44074 1.05785C2.64618 0.290546 1.38004 0.312647 0.612745 1.10721C-0.154553 1.90177 -0.132453 3.16791 0.662107 3.9352L12.1715 15.0497L1.05701 26.5592C0.289713 27.3537 0.311813 28.6199 1.10637 29.3872C1.90093 30.1545 3.16707 30.1324 3.93437 29.3378L16.4382 16.3897ZM12.0349 17.0524L15.0344 17.0001L14.9646 13.0007L11.9651 13.053L12.0349 17.0524Z"
        fill="#FDB005"
      />
    </svg>
  );
}

export function RoadmapCard(props: any) {
  return (
    <svg
      className={props.className}
      viewBox="0 0 213 309"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_4781_110)">
        <path
          d="M12.3791 1L1 11.5613L3.5287 257.489L6.0574 259.375V290.305L26.287 308.033H92.876L113.949 289.551H192.76L207.089 277.103V260.884L204.982 258.244L203.717 125.85L211.725 119.438V16.0876L200.346 1H12.3791Z"
          fill="url(#paint0_linear_4781_110)"
          fill-opacity="0.22"
        />
        <path
          d="M12.3791 1L1 11.5613L3.5287 257.489L6.0574 259.375V290.305L26.287 308.033H92.876L113.949 289.551H192.76L207.089 277.103V260.884L204.982 258.244L203.717 125.85L211.725 119.438V16.0876L200.346 1H12.3791Z"
          stroke="#FFFDFD"
        />
      </g>
      <rect
        x="14.5"
        y="12.5"
        width="180"
        height="264"
        stroke="white"
        stroke-opacity="0.09"
      />
      <path d="M15 29V13H31" stroke="#FF3503" stroke-width="3" />
      <path d="M194 29V13H178" stroke="#FF3503" stroke-width="3" />
      <path d="M194 259V275H178" stroke="#FF3503" stroke-width="3" />
      <path d="M15 260V276H31" stroke="#FF3503" stroke-width="3" />
      <defs>
        <filter
          id="filter0_b_4781_110"
          x="-3.50244"
          y="-3.5"
          width="219.727"
          height="316.033"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_4781_110"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_4781_110"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4781_110"
          x1="153.986"
          y1="292.568"
          x2="153.986"
          y2="-167.981"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

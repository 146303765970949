import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ethers, providers } from "ethers";
// import { useQuery } from "@tanstack/react-query";
// import { getChains } from "../apis/chain.api";
// import { IChain } from "../types/chain.type";

type HexString = `0x${string}`;

interface IWalletContext {
  address: HexString | undefined;
  chainId: number | undefined;
  isConnected: boolean;
  provider: ethers.providers.Web3Provider | null;
  switchChain: (chain: any) => Promise<void>;
}

export const WalletContext = createContext<IWalletContext | null>(null);

interface IProps {
  children: ReactNode;
}

export const WalletProvider = ({ children }: IProps) => {
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const provider = useMemo(() => {
    if (!walletProvider) return null;
    return new providers.Web3Provider(walletProvider, "any");
  }, [walletProvider]);

  const switchChain = useCallback(
    async (chain: any) => {
      if (!provider) return open({ view: "Connect" });

      try {
        await provider.send("wallet_switchEthereumChain", [
          { chainId: chain.chainId },
        ]);
        return chain.chainId;
      } catch (switchError: any) {
        try {
          var decodedError = JSON.parse(switchError.message);
        } catch (err) {
          // ignore
        }
        if (
          switchError.code === 4902 ||
          decodedError?.data?.originalError?.code === 4902
        ) {
          try {
            await provider.send("wallet_addEthereumChain", [chain]);
            return chain.id;
          } catch (addError) {
            console.error(addError);
          }
        } else {
          console.debug(switchError);
          console.error(switchError);
        }
      }
    },
    [provider]
  );

  const value = useMemo(
    () => ({
      address,
      chainId,
      isConnected,
      provider,
      switchChain,
    }),
    [address, chainId, isConnected, provider, switchChain]
  );

  return (
    <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
  );
};

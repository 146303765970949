import React, { useState } from "react";
import { AiOutlineSwap } from "react-icons/ai";
import { IoIosArrowBack, IoIosSettings } from "react-icons/io";
import ConfirmOrderModal from "./ConfirmOrderModal";
import { Link } from "react-router-dom";
import Settings from "./Settings";
import TransactionModal from "./TransactionModal";
import { FaArrowUp } from "react-icons/fa6";
import { BsExclamation } from "react-icons/bs";
const SVGWrapper = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      width="418"
      height="282"
      viewBox="0 0 418 282"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.5 281H417V52L361 1H1V228L52.5 281Z"
        fill="url(#paint0_linear_254_887)"
        fill-opacity="0.22"
        stroke="#FF3503"
      />
      <defs>
        <linearGradient
          id="paint0_linear_254_887"
          x1="1"
          y1="141"
          x2="548.87"
          y2="141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2C2C2C" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const ConfirmOrder = () => {
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [isSettingActive, setIsSettingActive] = useState<boolean>(false);
  return (
    <section className="w-full min-h-screen pt-40">
      <div className="rounded-3xl w-[480px] h-[800px] border border-primary mx-auto py-4 px-6 text-white relative">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-4">
            <Link to="/defi/liquidity/addliquidity">
              <IoIosArrowBack className="font-bold text-3xl cursor-pointer" />
            </Link>
            <h1 className="text-2xl font-bold">Confirm Order</h1>
          </div>
          {/* <div className="relative">
            <IoIosSettings
              onClick={() => setIsSettingActive((x: boolean) => !x)}
              className="text-3xl font-bold cursor-pointer text-gray-300"
            />
            <Settings
              isActive={isSettingActive}
              className="absolute top-0 right-0"
              closeModal={setIsSettingActive}
            />
          </div> */}
        </div>
        <div className="flex justify-between items-center mt-5">
          <div className="flex items-center gap-x-2">
            <img
              src="/assets/btc.png"
              className="h-7 w-7 rounded-full"
              alt="token"
            />
            <img
              src="/assets/btc.png"
              className="h-7 w-7 rounded-full"
              alt="token"
            />
            <h2 className="uppercase text-xl">ETH-POLY</h2>
          </div>
          <button className="rounded-full px-4 py-1 border border-[#14D204] text-[12px] cursor-pointer">
            Active
          </button>
        </div>
        <div className="relative h-[280px] w-full mt-9">
          <div className="w-full h-full px-8 py-11 absolute top-0 left-0 z-50 flex flex-col items-center">
            <div className="flex justify-between items-start w-full">
              <div className="flex items-center gap-x-2">
                <img
                  src="/assets/btc.png"
                  className="h-7 w-7 rounded-full"
                  alt="token"
                />
                <h2 className="uppercase text-xl">eth</h2>
              </div>
              <div className="flex flex-col items-end gap-y-[10px]">
                <h1 className="text-xl font-bold">2.652</h1>
                <p className="text-[#7a7a7a] text-xs">$-58.55</p>
              </div>
            </div>
            <div className="flex justify-between items-start w-full mt-4">
              <div className="flex items-center gap-x-2">
                <img
                  src="/assets/btc.png"
                  className="h-7 w-7 rounded-full"
                  alt="token"
                />
                <h2 className="uppercase text-xl">poly</h2>
              </div>
              <div className="flex flex-col items-end gap-y-[10px]">
                <h1 className="text-xl font-bold">2.652</h1>
                <p className="text-[#7a7a7a] text-xs">$-58.55</p>
              </div>
            </div>
            <div className="my-2"></div>
            <div className="flex items-center justify-between text-base mt-6 w-full">
              <p>Free tier</p>
              <span>0.25%</span>
            </div>
          </div>
          <SVGWrapper className="absolute top-0 left-0 h-full w-full z-50" />
        </div>
        <div className="flex gap-x-4 text-[12px] items-center mt-4">
          <span>Prices shown in</span>
          <button className="px-3 py-1 rounded-full border border-primary flex justify-center items-center gap-x-2">
            <span>BNB</span>
            <AiOutlineSwap />
          </button>
        </div>
        <div className="flex flex-col gap-y-4 mt-4">
          <div className="flex justify-between gap-x-4">
            <div className="w-full h-[100px] rounded-3xl border border-primary flex flex-col items-center py-4">
              <div className="flex gap-x-3 justify-center items-center">
                <p className="text-[12px] text-[#ff3503]">BNB Deposited</p>
                <h1 className="font-bold text-lg">0.00812</h1>
              </div>
              <div className="flex gap-x-3 justify-center items-center">
                <p className="text-[12px] text-[#ff3503]">BTC Deposited</p>
                <h1 className="font-bold text-lg">0.00012</h1>
              </div>
             
            </div>
            <div className="w-full h-[100px] rounded-3xl border border-primary flex flex-col items-center py-2">
              <p className="text-[12px] text-[#ff3503]">Rates</p>
              <h1 className="font-bold text-m">1 BNB = 0.004331 BTC</h1>
              <h1 className="font-bold text-m">1 BTC = 230.9 BNB</h1>
            </div>
          </div>
          <div className="rounded-3xl border border-primary h-[100px] w-full flex flex-col items-center py-4">
            <p className="text-[12px] text-[#ff3503]">Share Of Pool</p>
            <h1 className="font-bold text-2xl">57.54%</h1>
          </div>
        </div>
        <button
          onClick={() => setIsModalActive(true)}
          className="w-full rounded-full text-center py-3 mt-6 bg-gradient-to-r from-primary to-secondary outline-none border-none text-white font-bold text-base"
        >
          Add
        </button>
        {/* <ConfirmOrderModal
          isActive={isModalActive}
          setIsActive={setIsModalActive}
        /> */}
        {/* when ransaction successfull */}
        <TransactionModal
          isActive={false}
          title="Transaction Submitted"
          message="View on BscScan"
          btnValue="Close"
          handleIsActive={() => {}}
          link=" "
          icon={
            <FaArrowUp
              className="text-5xl text-[#ff3503]"
              style={{ filter: "box-shadow(0 0 20px rgba(255,35,3,0.2))" }}
            />
          }
          handleBtn={() => {}}
        />
        {/* when transaction failed */}
        <TransactionModal
          isActive={false}
          title="Claim Fees"
          message="Transaction Rejected"
          btnValue="Dismiss"
          handleIsActive={() => {}}
          icon={
            <BsExclamation
              className="text-9xl text-[#ff3503]"
              style={{ filter: "box-shadow(0 0 20px rgba(255,35,3,0.2))" }}
            />
          }
          handleBtn={() => {}}
        />
      </div>
    </section>
  );
};

export default ConfirmOrder;

import { useRouteError } from 'react-router-dom';

export function RootErrorBoundary() {
  let error = useRouteError() as Error;
  return (
    <div className="text-center">
      <h1>Uh oh, something went terribly wrong 😩</h1>
      <pre>{error.message || JSON.stringify(error)}</pre>
      <button
        className="bg-red-500 px-4 py-2 rounded-full"
        onClick={() => (window.location.href = '/')}
      >
        Click here to reload the app
      </button>
    </div>
  );
}

import { IoChevronBack } from "react-icons/io5";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosSettings,
  IoMdClose,
} from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";
import { Switch } from "../forms";
import { useMemo, useEffect, useState, useCallback } from "react";
import clsx from "clsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useWallet } from "../../hooks/useWallet";
import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import TokenInput from "../defi/TokenInput";
import { useQuery } from "@tanstack/react-query";
import { getChains, getTokens } from "../../apis/chain.api";
import TokenModal from "../../component/defi/TokenModal";
import { Dots, RowBetween, AutoColumn } from "../../component/styleds";
import LoadingButton from "../LoadingButton";
import { capitalize } from "../../utils/case";
import Settings from "./Settings";
import { WrapperSvg } from "./SvgContainer";
import TextWrapper from "./common/TextWrapper";
import { BigNumber } from "@ethersproject/bignumber";
import {
  Currency,
  currencyEquals,
  ETHER,
  TokenAmount,
  WETH,
  ChainId,
  Token,
  Pair,
  // DEFAULT_VESTING_TIME,
} from "blazpay-sdk";
import {
  useDerivedMintInfo,
  useMintActionHandlers,
  useMintState,
} from "../../state/mint/hooks";
import { useCurrency, useTokenLogoURI } from "../../hooks/Tokens";
import { Field } from "../../state/mint/actions";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import {
  ApprovalState,
  useApproveCallback,
} from "../../hooks/useApproveCallback";
import {
  ROUTER_ADDRESS,
  ONE_BIPS,
  NATIVE_SYMBOLS,
  TRANSFER_FILTER_ID,
} from "../../constants";
import { escapeRegExp, getContract } from "../../utils";
import ConfirmOrderModal from "./ConfirmOrderModal";
import TransactionModal from "./TransactionModal";
import { BsExclamation } from "react-icons/bs";
import { FaArrowUp } from "react-icons/fa6";
import { TransactionResponse } from "@ethersproject/providers";
import {
  calculateGasMargin,
  calculateSlippageAmount,
  getRouterContract,
} from "../../utils";
import { wrappedCurrency } from "../../utils/wrappedCurrency";
import { useTransactionAdder } from "../../state/transactions/hooks";
import {
  useUserSlippageTolerance,
  useUserDeadline,
  usePairAdder,
  usePossiblePair,
  useTrackedTokenPairs,
  toV2LiquidityToken,
} from "../../state/user/hooks";
import { zeroAddress } from "viem";
import { WrappedTokenInfo } from "@pancakeswap/token-lists";
import { useTokenBalancesWithLoadingIndicator } from "../../state/wallet/hooks";
import { usePairs } from "../../data/Reserves";
import axiosInstance from "../../utils/axios";
import { ethers } from "ethers";
import IBlazPayFactortyAbi from "../../constants/factory/abi.json";
import { getErrorMessage } from "../../utils/contractErrorHandler";

export default function AddLiquidity() {
  const navigate = useNavigate();
  const { param1, param2 } = useParams();
  const [currency0, currency1] = [
    useCurrency(param1) ?? undefined,
    useCurrency(param2) ?? undefined,
  ];

  const { address } = useWallet();
  const [range, setRange] = useState<number | string>(365);
  const ranges = [7, 14, 30, 60, 365];
  const [openModal, setOpenModal] = useState<string>("");
  const [openSettings, setOpenSettings] = useState(false);
  const [firstCrypto, setFirstCrypto] = useState<any>(null);
  const [amountFirst, setAmountFirst] = useState("");
  const [amountSecond, setAmountSecond] = useState("");
  const [secondCrypto, setSecondCrypto] = useState<any>(null);
  const [slippage, setSlippage] = useState(10);
  const [isAuto, setIsAuto] = useState(true);
  const [minutes, setMinutes] = useState(5);
  const [isSettingsActive, setIsSettingsActive] = useState<boolean>(false);
  let chainQuery = useQuery({
    queryKey: ["chains"],
    queryFn: getChains,
  });
  const [selectedLiquidityDuration, setSelectedLiquidityDuration] = useState(7);
  const [isVestingTimeVisible, setIsVestingTimeVisible] = useState(false);
  const chains = chainQuery?.data?.filter((chain: any) =>
    [56, 137, 167000].includes(chain.id)
  );
  chainQuery = { ...chainQuery, data: chains };

  const {
    address: account,
    provider: library,
    chainId,
    switchChain,
  } = useWallet();

  const loadedChainId: ChainId = useMemo(() => chainId as ChainId, [chainId]);
  const tokenQuery = useQuery({
    queryKey: ["tokens", chainId],
    queryFn: () => getTokens(chainId!),
    enabled: !!chainId,
  });
  const { open } = useWeb3Modal();

  const { currencyIdA, currencyIdB } = useParams();

  useEffect(() => {
    if (firstCrypto && (!chainId || chainId !== firstCrypto.chain.id)) {
      setFirstCrypto(null);
      setSecondCrypto(null);
    }
  }, [chainId]);

  useEffect(() => {
    if (chainId && currencyIdA && currencyIdB) {
      const chain = chainQuery.data?.find((chain: any) => chain.id === chainId);
      const tokenA: any = tokenQuery.data?.find(
        (token: any) => token.address === currencyIdA
      );
      const tokenB: any = tokenQuery.data?.find(
        (token: any) => token.address === currencyIdB
      );

      if (tokenA) {
        setFirstCrypto((prev: any) => {
          if (
            !prev ||
            prev?.address !== tokenA.address ||
            prev?.usdPrice !== tokenA.priceUSD
          ) {
            return {
              chain,
              name: tokenA.name,
              symbol: tokenA.symbol,
              address: tokenA.address,
              logo: tokenA.logoURI,
              usdPrice: tokenA.priceUSD,
              decimals: tokenA.decimals,
            };
          }
          return prev;
        });
      }

      if (tokenB) {
        setSecondCrypto((prev: any) => {
          if (
            !prev ||
            prev?.address !== tokenB.address ||
            prev?.usdPrice !== tokenB.priceUSD
          ) {
            return {
              chain,
              name: tokenB.name,
              symbol: tokenB.symbol,
              address: tokenB.address,
              logo: tokenB.logoURI,
              usdPrice: tokenB.priceUSD,
              decimals: tokenB.decimals,
            };
          }
          return prev;
        });
      }
    }
  }, [chainId, currencyIdA, currencyIdB, chainQuery.data, tokenQuery.data]);

  const currencyA = useCurrency(firstCrypto?.address);
  const currencyB = useCurrency(secondCrypto?.address);

  const oneCurrencyIsWBNB = Boolean(
    chainId &&
      ((currencyA && currencyEquals(currencyA, WETH[loadedChainId])) ||
        (currencyB && currencyEquals(currencyB, WETH[loadedChainId])))
  );
  console.log(WETH[loadedChainId],"weth")
  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState();
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined);
  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity);
  const addPossiblePair = usePossiblePair();

  const isValid = !error;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false); // clicked confirm
  const [isModalActive, setIsModalActive] = useState<boolean>(false);

  // txn values
  const [deadline] = useUserDeadline(); // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance(); // custom from users
  const [txHash, setTxHash] = useState<string>("");

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity
      ? otherTypedValue
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`); // match escaped "." characters via in a non-capturing group

  const enforcer = (nextUserInput: string, onFieldInput: any) => {
    if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
      onFieldInput(nextUserInput);
    }
  };

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [
    Field.CURRENCY_A,
    Field.CURRENCY_B,
  ].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmountSpend(currencyBalances[field]),
    };
  }, {});

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [
    Field.CURRENCY_A,
    Field.CURRENCY_B,
  ].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmounts[field]?.greaterThan(parsedAmounts[field] ?? "0"),
    };
  }, {});

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(
    parsedAmounts[Field.CURRENCY_A],
    ROUTER_ADDRESS[loadedChainId]
  );
  const [approvalB, approveBCallback] = useApproveCallback(
    parsedAmounts[Field.CURRENCY_B],
    ROUTER_ADDRESS[loadedChainId]
  );

  const approveAWrapper = async () => {
    try {
      if (!atMaxAmounts["CURRENCY_A"]) {
        toast.error(
          "Not enough funds for " + maxAmounts["CURRENCY_A"]?.currency.symbol
        );
        return;
      }
      await approveACallback();
    } catch (error: any) {
      toast.error(
        error?.message?.includes("user rejected transaction")
          ? "user rejected transaction"
          : "unexpected error"
      );
    }
  };
  const approveBWrapper = async () => {
    try {
      if (!atMaxAmounts["CURRENCY_B"]) {
        toast.error(
          "Not enough funds for " + maxAmounts["CURRENCY_B"]?.currency.symbol
        );
        return;
      }
      await approveBCallback();
    } catch (error: any) {
      toast.error(
        error?.message?.includes("user rejected transaction")
          ? "user rejected transaction"
          : "unexpected error"
      );
    }
  };

  const addTransaction = useTransactionAdder();

  const addLiquidity = () => {
    navigate("/defi/liquidity/addliquidity/confirm");
  };

  // useEffect(() => {
  //   if (firstCrypto) {
  //     const handleNetwork = async () => {
  //       try {
  //         // console.log(firstCrypto.chain.metamask)
  //         const id = await switchChain(firstCrypto.chain.metamask);
  //         // console.log(id)
  //         if (id !== firstCrypto.chain.metamask.chainId) {
  //           setFirstCrypto(null);
  //         }
  //       } catch (err) {
  //         setFirstCrypto(null);
  //       }
  //     };
  //     handleNetwork();
  //   }
  //   setSecondCrypto(null);
  // }, [firstCrypto]);

  const getButtonState = () => {
    if (!account) return "connect_wallet";
    if (!firstCrypto || !secondCrypto) return "select_tokens";
    if (chainId !== firstCrypto.chain.id) return "change_network";
    if (chainId !== secondCrypto.chain.id) return "change_network";
    const {
      [Field.CURRENCY_A]: parsedAmountA,
      [Field.CURRENCY_B]: parsedAmountB,
    } = parsedAmounts;
    if (!parsedAmountA?.raw.toString()) return "enter_amount";
    if (!parsedAmountB?.raw.toString()) return "enter_amount";
    return "supply";
  };

  const handleButtonClick = async () => {
    const buttonState = getButtonState();

    switch (buttonState) {
      case "connect_wallet":
        return open({ view: "Connect" });
      case "change_network":
        switchChain(firstCrypto.chain.metamask);
        break;
      case "supply":
        setIsModalActive(true);
        break;
    }
  };

  const handleClose = () => {
    setOpenModal("");
  };

  const resetToToken = () => {
    setSecondCrypto(null);
  };

  useEffect(() => {
    if (isAuto) {
      setSlippage(10);
    } else {
      setSlippage(1);
    }
  }, [isAuto]);
  const handleInputChange = (e: any) => {
    const value = e.target.value.replace("%", "");
    if (/^\d*\.?\d*$/.test(value) && +value <= 100) {
      setSlippage(value);
    }
  };
  const changeMinutes = (e: any) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setMinutes(value);
    }
  };

  const handleAmountFirstChange = (e: any) => {
    setAmountFirst(e.target.value);
    // Set Second according to first
  };
  const handleAmountSecondChange = (e: any) => {
    setAmountSecond(e.target.value);
    // Set first according to first
  };
  const handleTokenChange = (data: any) => {
    switch (openModal) {
      case "first": {
        setFirstCrypto(data);
        break;
      }
      case "second":
        setSecondCrypto(data);
        break;
    }
  };

  async function onAdd() {
    console.log(atMaxAmounts,"atMaxAmounts")
    if (!atMaxAmounts["CURRENCY_A"]) {
      toast.error(
        "Not enough funds for " + maxAmounts["CURRENCY_A"]?.currency.symbol
      );
      return;
    }
    if (!atMaxAmounts["CURRENCY_B"]) {
      toast.error(
        "Not enough funds for " + maxAmounts["CURRENCY_B"]?.currency.symbol
      );
      return;
    }
    if (!chainId || !library || !account) return;
    const router = getRouterContract(chainId, library, account);
    const factoryAddress = await router.factory();
    const factory = getContract(
      factoryAddress,
      IBlazPayFactortyAbi,
      library,
      account
    );
    const {
      [Field.CURRENCY_A]: parsedAmountA,
      [Field.CURRENCY_B]: parsedAmountB,
    } = parsedAmounts;
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB) {
      return;
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(
        parsedAmountA,
        noLiquidity ? 0 : allowedSlippage
      )[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(
        parsedAmountB,
        noLiquidity ? 0 : allowedSlippage
      )[0],
    };

    const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline;
    let estimate;
    let method: (...args: any) => Promise<TransactionResponse>;
    let args: Array<string | string[] | number>;
    let value: BigNumber | null;
    if (currencyA === ETHER || currencyB === ETHER) {
      const tokenBIsBNB = currencyB === ETHER;
      estimate = router.estimateGas.addLiquidityETH;
      method = router.addLiquidityETH;
      args = [
        wrappedCurrency(tokenBIsBNB ? currencyA : currencyB, chainId)
          ?.address ?? "", // token
        (tokenBIsBNB ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[
          tokenBIsBNB ? Field.CURRENCY_A : Field.CURRENCY_B
        ].toString(), // token min
        amountsMin[
          tokenBIsBNB ? Field.CURRENCY_B : Field.CURRENCY_A
        ].toString(), // eth min
        account,
        deadlineFromNow,
        range, //DEFAULT_VESTING_TIME
      ];
      console.log(args,"args")
      value = BigNumber.from(
        (tokenBIsBNB ? parsedAmountB : parsedAmountA).raw.toString()
      );
    } else {
      estimate = router.estimateGas.addLiquidity;
      method = router.addLiquidity;
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? "",
        wrappedCurrency(currencyB, chainId)?.address ?? "",
        parsedAmountA.raw.toString(),
        parsedAmountB.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadlineFromNow,
        365, //DEFAULT_VESTING_TIME
      ];
      value = null;
    }

    setAttemptingTxn(true);
    // const aa = await estimate(...args, value ? { value } : {})
    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit: BigNumber) =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then(async (response) => {
          const { logs } = await response.wait();
          setAttemptingTxn(false);
          setTxHash(response.hash);
          const token0 = wrappedCurrency(currencyA!, chainId);
          const token1 = wrappedCurrency(currencyB!, chainId);
          const pair = await factory.getPair(token0?.address, token1?.address);
          const liquidityTransferLog = logs.filter(
            (log) =>
              log.address === pair && log.topics[0] === TRANSFER_FILTER_ID
          );
          const data = {
            moduleName: "add",
            walletAddress: address,
            pair: pair,
            txHash: response?.hash,
            to: router.address,
            tokenOne: {
              address: token0?.address,
              amount:
                parseInt(parsedAmountA?.raw.toString()!) /
                Math.pow(10, token0?.decimals ? token0.decimals : 18),
            },
            tokenTwo: {
              address: token1?.address,
              amount:
                parseInt(parsedAmountB?.raw.toString()!) /
                Math.pow(10, token1?.decimals ? token1.decimals : 18),
            },
            status: "success",
            value: Number(ethers.BigNumber.from(liquidityTransferLog[0].data)),
          };
          await axiosInstance.post("/liquidity/transaction", { data });
          console.log("backend complete");
          addPossiblePair(token0 as Token, token1 as Token);
          addTransaction(response, {
            summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(
              3
            )} ${
              currencies[Field.CURRENCY_A] === ETHER
                ? NATIVE_SYMBOLS[loadedChainId]
                : currencies[Field.CURRENCY_A]?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${
              currencies[Field.CURRENCY_B] === ETHER
                ? NATIVE_SYMBOLS[loadedChainId]
                : currencies[Field.CURRENCY_B]?.symbol
            }`,
          });

          setTxHash(response.hash);
        }).catch((e:any)=>{
          setAttemptingTxn(false);
          const message = getErrorMessage(e)
          toast.error(message)
          return
        })
      )
      .catch((e:any) => {
        setAttemptingTxn(false);
        const message = getErrorMessage(e)
        toast.error(message)
      });
  }

  const findNearestValue = (value: number) => {
    return ranges.reduce((prev, curr) => {
      return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
    });
  };

  const changeCallback = useCallback(
    (e: any) => {
      const value = parseInt(e.target.value, 10);
      const nearestValue = findNearestValue(value);
      setRange(nearestValue);
      setSelectedLiquidityDuration(nearestValue);
    },
    [setSelectedLiquidityDuration]
  );

  const toggleVestingTime = () => {
    setIsVestingTimeVisible(!isVestingTimeVisible);
  };

  if (chainQuery.isLoading) return null;

  return (
    <>
      <div className="relative md:pt-40 sm:pt-44 pt-52 w-full swap-container sm:px-0 px-5  text-white flex justify-center">
        <div className="relative sm:w-[480px] w-full border border-primary py-4 px-6 rounded-3xl">
          <div className="flex justify-between py-1 items-center w-full">
            <div className="flex gap-x-3 items-center">
              <Link to="/defi/liquidity">
                <IoChevronBack className="sm:text-3xl text-2xl" type="button" />
              </Link>
              <h1 className="text-xl font-bold">Add Liquidity</h1>
            </div>
          </div>
          <TextWrapper
            className="mt-4"
            highlight="Tip"
            text={`When you add liquidity, you will receive pool tokens representing
              your position. These tokens automatically earn fees proportional
              to your share of the pool, and can be redeemed at any time.`}
          />

          <div className="flex flex-col justify-stretch w-full  items-center">
            <TokenInput
              className="sm:mt-6 mt-4"
              showChain={true}
              placeholder="0.0"
              data={
                firstCrypto
                  ? {
                      name: firstCrypto.name,
                      logo: firstCrypto.logo,
                      chainName: firstCrypto.chain.name,
                      chainLogo: firstCrypto.chain.logoURI,
                    }
                  : undefined
              }
              value={formattedAmounts[Field.CURRENCY_A]}
              onClick={() => setOpenModal("first")}
              onChange={(event) => {
                // replace commas with periods, because uniswap exclusively uses period as the decimal separator
                enforcer(event.target.value.replace(/,/g, "."), onFieldAInput);
              }}
            />
            <div className="flex w-full items-center justify-center sm:mt-4 mb-3 mt-3">
              <div className="h-10 w-10 rounded-[13px] border border-white bg-dark text-white flex justify-center items-center">
                <IoIosArrowDown className="text-3xl" />
              </div>
            </div>
            <TokenInput
              className=""
              showChain={false}
              placeholder="0.0"
              data={
                secondCrypto
                  ? {
                      name: secondCrypto.name,
                      logo: secondCrypto.logo,
                      chainName: secondCrypto.chain.name,
                      chainLogo: secondCrypto.chain.logoURI,
                    }
                  : undefined
              }
              value={formattedAmounts[Field.CURRENCY_B]}
              onClick={() => setOpenModal("second")}
              onChange={(event) => {
                // replace commas with periods, because uniswap exclusively uses period as the decimal separator
                enforcer(event.target.value.replace(/,/g, "."), onFieldBInput);
              }}
            />
          </div>
          {secondCrypto && (
            <section className="w-full  xs:mt-4 mt-1 ">
              <div className="w-full h-full   pt-1 z-20 flex gap-2 justify-center items-center">
                <h1 className="xs:text-3xl text-2xl font-bold ">
                  Vesting Time
                </h1>
                {!isVestingTimeVisible ? (
                  <IoIosArrowDown
                    className="text-3xl relative top-1"
                    onClick={toggleVestingTime}
                  />
                ) : (
                  <IoIosArrowUp
                    className="text-3xl relative top-1"
                    onClick={toggleVestingTime}
                  />
                )}
              </div>

              {isVestingTimeVisible && (
                <div className="w-full relative xs:mt-4 my-2 min-h-[120px]  ">
                  <div className="w-full h-full  pt-1 z-30 flex flex-col items-center">
                    <h1 className="xs:text-3xl text-2xl font-bold">{range}</h1>
                    <div className="w-full px-8">
                      <input
                        type="range"
                        min="0"
                        max="360"
                        value={range}
                        onChange={(e) => {
                          changeCallback(e);
                        }}
                      />
                    </div>
                    <div className="flex w-full xs:px-[52px]  px-8 justify-between items-center mt-3">
                      {ranges.map((value, index) => {
                        return (
                          <button
                            onClick={() => {
                              setRange(value);
                            }}
                            key={index}
                            className="cursor-pointer px-4 py-1 rounded-full bg-transparent xs:text-xs text-[10px] border border-white text-white font-bold"
                          >
                            <>
                              <span>{value == 365 ? "Max" : value}</span>
                            </>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <WrapperSvg className="w-full -z-10 absolute top-0 left-0 xs:scale-y-100 scale-y-125" />
                </div>
              )}
            </section>
          )}

          <div className="border border-primary rounded-3xl sm:my-6 my-3 py-4 sm:px-4 px-4 flex justify-between">
            <div className="text-center">
              <p className="sm:text-xl text-base font-bold">
                {price?.toSignificant(6) ?? "-"}
              </p>
              <p className="sm:text-sm text-[10px] text-slate-300">
                {currencyB === ETHER
                  ? NATIVE_SYMBOLS[loadedChainId]
                  : currencies[Field.CURRENCY_B]?.symbol}{" "}
                per{" "}
                {currencyA === ETHER
                  ? NATIVE_SYMBOLS[loadedChainId]
                  : currencies[Field.CURRENCY_A]?.symbol}
              </p>
            </div>
            <div className="text-center">
              <p className="sm:text-xl text-base font-bold">
                {price?.invert()?.toSignificant(6) ?? "-"}
              </p>
              <p className="sm:text-sm text-[10px]  text-slate-300">
                {currencyA === ETHER
                  ? NATIVE_SYMBOLS[loadedChainId]
                  : currencies[Field.CURRENCY_A]?.symbol}{" "}
                per
                {currencyB === ETHER
                  ? NATIVE_SYMBOLS[loadedChainId]
                  : currencies[Field.CURRENCY_B]?.symbol}
              </p>
            </div>
            <div className="text-center">
              <p className="sm:text-xl text-base font-bold">
                {noLiquidity && price
                  ? "100"
                  : (poolTokenPercentage?.lessThan(ONE_BIPS)
                      ? "<0.01"
                      : poolTokenPercentage?.toFixed(2)) ?? "0"}
                %
              </p>
              <p className="sm:text-sm text-[10px] text-slate-300">
                Share of Pool
              </p>
            </div>
          </div>

          <ConfirmOrderModal
            attemptingTxn={attemptingTxn}
            hash={txHash}
            isActive={isModalActive}
            setIsActive={(x: boolean) => {
              if (x == false) {
                setTxHash("");
              }
              setIsModalActive(x);
            }}
            firstData={
              firstCrypto
                ? {
                    name: firstCrypto.name,
                    logo: firstCrypto.logo,
                    chainName: firstCrypto.chain.name,
                    chainLogo: firstCrypto.chain.logoURI,
                  }
                : undefined
            }
            secondData={
              secondCrypto
                ? {
                    name: secondCrypto.name,
                    logo: secondCrypto.logo,
                    chainName: secondCrypto.chain.name,
                    chainLogo: secondCrypto.chain.logoURI,
                  }
                : undefined
            }
            price={price}
            currencies={currencies}
            parsedAmounts={parsedAmounts}
            noLiquidity={noLiquidity}
            onAdd={onAdd}
            poolTokenPercentage={poolTokenPercentage}
            liquidityMinted={liquidityMinted?.toSignificant(6)}
          />

          {/* when transaction successfull */}
          <TransactionModal
            isActive={false}
            title="Transaction Submitted"
            message="View on BscScan"
            btnValue="Close"
            handleIsActive={() => {}}
            link=" "
            icon={
              <FaArrowUp
                className="text-5xl text-[#ff3503]"
                style={{ filter: "box-shadow(0 0 20px rgba(255,35,3,0.2))" }}
              />
            }
            handleBtn={() => {}}
          />
          {/* when transaction failed */}
          <TransactionModal
            isActive={false}
            title="Claim Fees"
            message="Transaction Rejected"
            btnValue="Dismiss"
            handleIsActive={() => {}}
            icon={
              <BsExclamation
                className="text-9xl text-[#ff3503]"
                style={{ filter: "box-shadow(0 0 20px rgba(255,35,3,0.2))" }}
              />
            }
            handleBtn={() => {}}
          />

          {!account ? (
            <LoadingButton
              onClick={() => open({ view: "Connect" })}
              className=" bg-gradient-to-r from-primary to-secondary text-white font-bold text-center w-full mx-auto block h-12 rounded-full"
            >
              Connect Wallet
            </LoadingButton>
          ) : (
            <AutoColumn gap="md">
              {
                (approvalA === ApprovalState.NOT_APPROVED ||
                  approvalA === ApprovalState.PENDING ||
                  approvalB === ApprovalState.NOT_APPROVED ||
                  approvalB === ApprovalState.PENDING) && (
                  // isValid && (
                  <RowBetween>
                    {approvalA !== ApprovalState.APPROVED && (
                      <LoadingButton
                        className=" bg-gradient-to-r from-primary to-secondary text-white font-bold text-center w-full mx-auto block h-12 rounded-full"
                        onClick={approveAWrapper}
                        disabled={approvalA === ApprovalState.PENDING}
                        isLoading={approvalA === ApprovalState.PENDING}
                        loadingText={`Approving ${
                          currencies[Field.CURRENCY_A] === ETHER
                            ? NATIVE_SYMBOLS[loadedChainId]
                            : currencies[Field.CURRENCY_A]?.symbol
                        }`}
                        style={{
                          width:
                            approvalB !== ApprovalState.APPROVED
                              ? "48%"
                              : "100%",
                        }}
                      >
                        {approvalA === ApprovalState.PENDING ? (
                          <Dots>
                            Approving{" "}
                            {currencies[Field.CURRENCY_A] === ETHER
                              ? NATIVE_SYMBOLS[loadedChainId]
                              : currencies[Field.CURRENCY_A]?.symbol}
                          </Dots>
                        ) : (
                          `Approve ${
                            currencies[Field.CURRENCY_A] === ETHER
                              ? NATIVE_SYMBOLS[loadedChainId]
                              : currencies[Field.CURRENCY_A]?.symbol
                          }`
                        )}
                      </LoadingButton>
                    )}
                    {approvalB !== ApprovalState.APPROVED && (
                      <LoadingButton
                        onClick={approveBWrapper}
                        className=" bg-gradient-to-r from-primary to-secondary text-white font-bold text-center w-full mx-auto block h-12 rounded-full"
                        disabled={approvalB === ApprovalState.PENDING}
                        isLoading={approvalB === ApprovalState.PENDING}
                        loadingText={`Approving ${
                          currencies[Field.CURRENCY_B] === ETHER
                            ? NATIVE_SYMBOLS[loadedChainId]
                            : currencies[Field.CURRENCY_B]?.symbol
                        }`}
                        style={{
                          width:
                            approvalA !== ApprovalState.APPROVED
                              ? "48%"
                              : "100%",
                        }}
                      >
                        {approvalB === ApprovalState.PENDING ? (
                          <Dots>
                            Approving{" "}
                            {currencies[Field.CURRENCY_B] === ETHER
                              ? NATIVE_SYMBOLS[loadedChainId]
                              : currencies[Field.CURRENCY_B]?.symbol}
                          </Dots>
                        ) : (
                          `Approve ${
                            currencies[Field.CURRENCY_B] === ETHER
                              ? NATIVE_SYMBOLS[loadedChainId]
                              : currencies[Field.CURRENCY_B]?.symbol
                          }`
                        )}
                      </LoadingButton>
                    )}
                  </RowBetween>
                )
                // )
              }
              <LoadingButton
                onClick={handleButtonClick}
                // disabled={
                //   !isValid ||
                //   approvalA !== ApprovalState.APPROVED ||
                //   approvalB !== ApprovalState.APPROVED
                // }
                className=" bg-gradient-to-r from-primary to-secondary text-white font-bold text-center w-full mx-auto block h-12 rounded-full"
                style={
                  !isValid &&
                  !!parsedAmounts[Field.CURRENCY_A] &&
                  !!parsedAmounts[Field.CURRENCY_B]
                    ? { background: "red" }
                    : {}
                }
                // variant={
                //   !isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]
                //     ? 'danger'
                //     : 'primary'
                // }
                // width="100%"
              >
                {capitalize(getButtonState())}
              </LoadingButton>
            </AutoColumn>
          )}
          <TextWrapper
            className="mt-4"
            text={`⭐ By adding liquidity you'll earn 0.3% of all trades on this pair
              proportional to your share of the pool. Fees are added to the
              pool, accrue in real time and can be claimed by withdrawing your
              liquidity.`}
          />
        </div>
      </div>
      <TokenModal
        open={openModal}
        chains={chainQuery.data}
        handleClose={handleClose}
        handleChange={handleTokenChange}
        swap={true}
        resetToToken={resetToToken}
      />
    </>
  );
}

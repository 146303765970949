import React from "react";
import { FaBarsStaggered } from "react-icons/fa6";
import { IoClose, IoSettingsOutline } from "react-icons/io5";
import { CiGlobe } from "react-icons/ci";
import Logo from "../../component/Logo";
import { Button } from "../../component/forms";
import { DashboardPath } from "../../routes/path";
import { Link as RouterLink } from "react-router-dom";
import { useWallet } from "../../hooks/useWallet";
import { useDisconnect, useWeb3Modal } from "@web3modal/ethers5/react";
import { useTronWallet } from "../../contexts/TronWalletContext";
import { useSolanaWallet } from "../../contexts/SolanaWalletContext";
import { useChain } from "../../contexts/ChainContext";

function formatHex(hexString: string): string {
  if (hexString.length <= 10) {
    return hexString;
  }

  const start = hexString.substring(0, 6);
  const end = hexString.substring(hexString.length - 4);

  return `${start} ... ${end}`;
}

const NAV_MENU = [
  {
    name: "DeFi",
    href: DashboardPath.defi.root,
  },
  {
    name: "NFTs",
    href: DashboardPath.nft,
  },
  {
    name: "Games",
    href: DashboardPath.games,
  },

  {
    name: "Pools",
    href: DashboardPath.pools,
  },
];

interface NavItemProps {
  children: React.ReactNode;
  href?: string;
  className?: string;
}

function NavItem({ children, href, className }: NavItemProps) {
  return (
    <li className={className}>
      {href ? (
        <RouterLink
          color="gray"
          className="flex items-center gap-2 font-medium text-white"
          to={href}
        >
          {children}
        </RouterLink>
      ) : (
        <p
          color="gray"
          className="flex items-center gap-2 font-medium text-white"
        >
          {children}
        </p>
      )}
    </li>
  );
}
const MobileNav = ({
  NAV_MENU,
  className,
}: {
  NAV_MENU: any;
  className?: string;
}) => {
  return (
    <div
      className={`w-full px-4 fixed top-20 left-1/2 -translate-x-1/2 flex justify-center z-50 ${className}`}
    >
      <ul className="border border-primary bg-[#000] flex flex-col items-center z-50 py-20 sm:w-[680px] w-full rounded-3xl bg-dark">
        {NAV_MENU.map(({ name, href }: { name: string; href: string }) => (
          <NavItem key={name} href={href} className="py-8 hover:text-[#ff3503]">
            {name}
          </NavItem>
        ))}
      </ul>
    </div>
  );
};
export function Navbar() {
  const { isConnected, address } = useWallet();
  const { open } = useWeb3Modal();

  const { disconnect } = useDisconnect();

  const [openNav, setOpen] = React.useState(false);

  const handleOpen = () => setOpen((cur) => !cur);
  const {
    wallet,
    address: tronAddress,
    connected,
    select,
    connect,
    disconnect: disConnectTronWallet,
    signMessage,
    signTransaction,
  } = useTronWallet();
  const {
    disconnect: disconnectSolanaWallet,
    connected: isSolanaConnected,
    address: solanaAddress,
  } = useSolanaWallet();
  const { chainId, connectTronLink, connectSolanaWallet, connectWeb3Modal } =
    useChain();
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpen(false)
    );
  }, []);

  const handleConnect = () => {
    if (!chainId) {
      connectWeb3Modal();
    } else if (chainId === 728126428) {
      connectTronLink();
    } else if (chainId === 102) {
      connectSolanaWallet();
    } else {
      connectWeb3Modal();
    }
  };

  const handleDisconnect = () => {
    if (connected) {
      disConnectTronWallet();
    }
    if (isSolanaConnected) {
      disconnectSolanaWallet();
    }
    if (isConnected) {
      disconnect();
    }
  };

  return (
    <nav className="flex justify-center lg:px-0">
      <div className=" fixed z-[1000] bg-black text-white w-full py-2 border-b border-b-primary">
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex items-center">
            <div>
              <Logo />
            </div>

            <ul className="ml-10 hidden items-center gap-8 lg:flex">
              {NAV_MENU.map(({ name, href }) => (
                <NavItem key={name} href={href}>
                  {name}
                </NavItem>
              ))}
            </ul>
          </div>

          <div className="hidden items-center gap-2 lg:flex">
            {(isConnected || connected || isSolanaConnected) && (
              <>
                <Button variant="outlined">
                  {connected
                    ? formatHex(tronAddress || "")
                    : isConnected
                    ? formatHex(address || "")
                    : formatHex(solanaAddress || "")}
                </Button>
              </>
            )}
            {!isConnected && !connected && !isSolanaConnected && (
              <Button variant="contained" onClick={handleConnect}>
                Connect Wallet
              </Button>
            )}

            {(connected || isSolanaConnected || isConnected) && (
              <Button variant="outlined" onClick={handleDisconnect}>
                Disconnect
              </Button>
            )}
          </div>
          <Button
            variant="outlined"
            color="gray"
            onClick={handleOpen}
            className="ml-auto inline-block lg:hidden mr-6"
          >
            {openNav ? (
              <IoClose strokeWidth={2} className="h-6 w-6" />
            ) : (
              <FaBarsStaggered strokeWidth={2} className="h-6 w-6" />
            )}
          </Button>
          <MobileNav
            NAV_MENU={NAV_MENU}
            className={`${!openNav && "hidden"}`}
          />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

import React, { useState } from 'react';

export default function Switch() {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div className="inline-flex items-center">
        <div className="relative inline-block w-10 h-5 rounded-full cursor-pointer rounded-full border border-primary">
          <input
            id="switch-component"
            type="checkbox"
            className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-blue-gray-100 checked:bg-gray-900 peer-checked:border-gray-900 peer-checked:before:bg-gray-900"
            defaultChecked
          />
          <label
            htmlFor="switch-component"
            className="before:content[''] absolute top-2/4 left-[1px] h-[18px] w-[18px] -translate-y-2/4 cursor-pointer rounded-full bg-secondary shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-gray-900 peer-checked:before:bg-gray-900"
          >
   
          </label>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";

const LineLoader = ({ className }: { className: string }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = oldProgress + 5;
        return newProgress >= 100 ? 100 : newProgress;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={styles.loaderContainer} className={`${className}`}>
      <div style={{ ...styles.loader, width: `${progress}%` }}></div>
    </div>
  );
};

const styles = {
  loaderContainer: {
    width: "80%",
    height: "2px", // Adjust height as needed
    backgroundColor: "#ffffff",
    borderRadius: "2px",
    overflow: "hidden",
  },
  loader: {
    height: "100%",
    backgroundColor: "#ff3503",
    transition: "width 0.5s ease",
  },
};

export default LineLoader;

import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { DashboardPath } from "../../routes/path";
import { SwapAiIcon } from "../../component/liquidity/SvgContainer";

const NavItem = [
  {
    title: "Swap",
    url: DashboardPath.defi.swap,
  },
  {
    title: "Swap(AI)",
    url: DashboardPath.defi.swapAi,
  },
  {
    title: "Bridge",
    url: DashboardPath.defi.bridge,
  },
  {
    title: "Liquidity",
    url: DashboardPath.defi.liquidity,
  },

  {
    title: "Perpetual",
    url: DashboardPath.defi.perpetual,
  },
  {
    title: "Limit",
    url: DashboardPath.defi.limit,
  },

  {
    title: "Buy Sell Crypto",
    url: DashboardPath.defi.buySell,
  },
  // {
  //   title: 'Borrow',
  //   url: DashboardPath.defi.borrow,
  // },
];

export default function Defi() {
  const { pathname } = useLocation();

  return (
    <div className="fixed w-full z-20 top-24 left-1/2 translate-x-[-50%] flex justify-center items-center">
      <ul className="flex flex-wrap justify-center  lg:gap-5  text-md font-medium text-center text-white">
        {NavItem.map((item) => (
          <li
            key={item.url}
            className={clsx(
              "me-2",
              pathname.includes(item.url) && "border-b border-primary"
            )}
          >
            <Link to={item.url}>
              <p role="button" className="px-4 py-2 flex items-center gap-x-2">
                {item.title}
                {item.title === "Swap(AI)" && <SwapAiIcon />}
              </p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './utils/walletConnect';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WalletProvider } from './contexts/WalletContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import ApplicationUpdater from './state/application/updater';
import MulticallUpdater from './state/multicall/updater';
import TransactionUpdater from './state/transactions/updater';
import ListsUpdater from './state/lists/updater';
import { Provider, ProviderProps } from 'react-redux';
import { store, persistor } from './state';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const providerProps: ProviderProps = {
  store: store,
};

root.render(
  <BrowserRouter>
    <ToastContainer
      position="top-right"
      className={'fixed z-[1000000]'}
      theme="dark"
    />
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

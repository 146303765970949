import { ethers } from 'ethers';

export const INFURA_API_KEY = '3c9fa3a153354fd4aa2b388a34eee313';

export const InfuraProvider = new ethers.providers.JsonRpcProvider(
  `https://sepolia.infura.io/v3/${INFURA_API_KEY}`
);

// https://mainnet.infura.io/v3/

const PRIVATE_KEY =
  '3e79351246c9bc84d58af6f33285c0fdf372229a9fb3a7c6f5e45f00f59c9eb8';

export const infuraWallet = new ethers.Wallet(PRIVATE_KEY, InfuraProvider);

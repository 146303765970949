import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { CopySvg, WrapperSvg2 } from "./SvgContainer";
import { AiOutlineSwap } from "react-icons/ai";
import { Link } from "react-router-dom";
import "./style.css";
import TransactionModal from "./TransactionModal";
import { FaArrowUp } from "react-icons/fa6";

interface InputProps {
  className?: string;
  balance: number;
  token: { imgURL: string; name: string };
  inputValue: number;
  handleInput: Function;
}

const CustomInput = ({
  className,
  balance,
  token,
  inputValue,
  handleInput,
}: InputProps) => {
  const ranges = [25, 50, 75, "MAX"];

  return (
    <div>
      <div className="flex justify-between items-center w-full mt-6">
        <div className="flex gap-x-2 items-center">
          <img
            src={token.imgURL}
            alt={token.name}
            className="h-7 w-7 rounded-full"
          />
          <h1 className="uppercase text-xl font-bold">{token.name}</h1>
          <CopySvg />
        </div>
        <p className="text-[14px]">
          Balance: <span>{balance}</span>
        </p>
      </div>
      <div className="h-[90px] w-full rounded-3xl border border-white mt-2 flex flex-col items-end px-6 py-4">
        <input
          type="number"
          step="any"
          value={inputValue}
          onChange={(e: any) => handleInput(Number(e.target.value))}
          className="bg-transparent outline-none border-none text-end text-2xl text-white placeholder:text-white"
          placeholder="0.0"
        />
        <div className="flex items-center gap-x-1 mt-2">
          {ranges.map((range, index) => {
            return (
              <button
                key={index}
                className="px-3 py-[2px] text-xs leading-[1.1] text-white rounded-full bg-transparent border border-white"
              >
                {range}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Add = () => {
  const tokenInfo = {
    imgURL: "/bnb.svg",
    name: "eth",
  };
  const [inputValue, setInputValue] = useState<number>(0);
  const [inputValue2, setInputValue2] = useState<number>(0);
  return (
    <div className="relative md:pt-40 pt-44 w-full  text-white flex justify-center lg:px-0 px-5 overflow-hidden">
      <div className="lg:w-[930px] md:w-full xs:w-[480px] w-full bg-[rgba(0,0,0,0.60)] border border-primary xs:px-6 px-3 2xl:py-12 py-6 rounded-3xl">
        <div className="flex gap-x-4 w-full justify-start">
          <Link to="/defi/liquidity">
            <IoIosArrowBack className="lg:text-3xl text-2xl  cursor-pointer" />
          </Link>

          <h1 className="lg:text-2xl text-xl font-bold">Add Liquidity</h1>
        </div>
        <div className="flex justify-between  items-end">
          <div className="flex gap-x-2 items-center 2xl:mt-6 mt-4 relative">
            <img
              src="/assets/btc.png"
              alt="token"
              className="h-7 w-7 rounded-full"
            />
            <img
              src="/assets/btc.png"
              alt="token"
              className="h-7 w-7 rounded-full"
            />
            <h1 className="uppercase text-xl font-bold">eth-poly</h1>
          </div>
          <button className="bg-transparent border border-[#14D204] px-5 py-[6px] rounded-full text-xs text-white font-bold">
            Active
          </button>
        </div>
        <div className="flex md:flex-row flex-col lg:gap-x-16 gap-x-8 w-full">
          {/* left */}
          <div className="flex flex-col w-full">
            <div className="relative h-[280px] w-full mt-4">
              <div className="w-full h-full px-8 py-11 absolute top-0 left-0 z-20 flex flex-col items-center">
                <div className="flex justify-between items-start w-full">
                  <div className="flex items-center gap-x-2">
                    <img
                      src="/bnb.svg"
                      className="h-7 w-7 rounded-full"
                      alt="token"
                    />
                    <h2 className="uppercase text-xl">eth</h2>
                  </div>
                  <div className="flex flex-col items-end gap-y-[10px]">
                    <h1 className="text-xl font-bold">2.652</h1>
                    <p className="text-[#7a7a7a] text-xs">$-58.55</p>
                  </div>
                </div>
                <div className="flex justify-between items-start w-full mt-4">
                  <div className="flex items-center gap-x-2">
                    <img
                      src="/bnb.svg"
                      className="h-7 w-7 rounded-full"
                      alt="token"
                    />
                    <h2 className="uppercase text-xl">poly</h2>
                  </div>
                  <div className="flex flex-col items-end gap-y-[10px]">
                    <h1 className="text-xl font-bold">2.652</h1>
                    <p className="text-[#7a7a7a] text-xs">$-58.55</p>
                  </div>
                </div>
                <div className="my-2"></div>
                <div className="flex items-center justify-between text-base lg:mt-6 w-full">
                  <p>Free tier</p>
                  <span>0.25%</span>
                </div>
              </div>
              <WrapperSvg2 className="absolute top-0 left-0 h-full w-full z-0 lg:scale-y-100 md:scale-y-110 xs:-mt-0 -mt-4" />
            </div>

            <div className="flex flex-col lg:gap-y-4 gap-y-3 lg:mt-4 mt-2">
              <div className="flex justify-between gap-x-4">
                <div className="w-full lg:h-[100px] h-20 rounded-3xl border border-primary flex flex-col items-center py-4">
                  <p className="text-[12px] text-[#ff3503]">BTC per BNB</p>
                  <h1 className="font-bold lg:text-2xl xs:text-xl text-base">
                    0.00433109
                  </h1>
                </div>
                <div className="w-full lg:h-[100px] h-20 rounded-3xl border border-primary flex flex-col items-center py-4">
                  <p className="text-[12px] text-[#ff3503]">BNB per BTC</p>
                  <h1 className="font-bold lg:text-2xl xs:text-xl text-base">
                    230.889
                  </h1>
                </div>
              </div>
              <div className="rounded-3xl border border-primary lg:h-[100px] h-20 w-full flex flex-col items-center py-4">
                <p className="text-[12px] text-[#ff3503]">Share of Pool</p>
                <h1 className="font-bold lg:text-2xl xs:text-xl text-base">
                  0%
                </h1>
              </div>
            </div>
          </div>
          {/* right */}
          <div className="w-full flex flex-col">
            <CustomInput
              inputValue={inputValue}
              balance={0.2338484}
              handleInput={setInputValue}
              token={tokenInfo}
            />
            <CustomInput
              inputValue={inputValue2}
              balance={0.2338484}
              handleInput={setInputValue2}
              token={tokenInfo}
            />
            <button
              className={` ${
                inputValue && inputValue2 ? "opacity-1" : "opacity-50"
              } w-full rounded-full bg-gradient-to-r from-primary to-secondary border-none outline-none text-white font-bold text-base mt-6 text-center py-3`}
            >
              Enter an amount
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;

import React, { useEffect, useState, useMemo } from "react";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowDropleft,
  IoMdSettings,
} from "react-icons/io";
import { FaClockRotateLeft } from "react-icons/fa6";
import { MdOutlineCheckBox } from "react-icons/md";
import { AiOutlineSwap } from "react-icons/ai";
import { Link } from "react-router-dom";
import Settings from "./Settings";
import { YourLiquiditySvgWrapper } from "./SvgContainer";
import { DashboardPath } from "../../routes/path";
import { FaRegQuestionCircle } from "react-icons/fa";
import ConfirmationLoader from "./ConfirmationLoader";

import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { LightCard, Dots } from "../Card";
import { useWallet } from "../../hooks/useWallet";
import { PairState, usePairs } from "../../data/Reserves";
import {
  useTokenBalancesWithLoadingIndicator,
  useTokenBalance,
} from "../../state/wallet/hooks";
import {
  useTrackedTokenPairs,
  toV2LiquidityToken,
  useUserSlippageTolerance,
  usePossiblePair,
} from "../../state/user/hooks";
import {
  JSBI,
  Percent,
  Pair,
  ETHER,
  ChainId,
  FACTORY_ADDRESS,
  Token,
} from "blazpay-sdk";
import { unwrappedToken, wrappedCurrency } from "../../utils/wrappedCurrency";
import { useTotalSupply } from "../../data/TotalSupply";
import DoubleCurrencyLogo from "../DoubleLogo";
import CurrencyLogo from "../CurrencyLogo";
import { currencyId } from "../../utils/currencyId";
import { NATIVE_SYMBOLS } from "../../constants";
import TokenModal from "../defi/TokenModal";
import { useQuery } from "@tanstack/react-query";
import { getChains } from "../../apis/chain.api";
import { getContract } from "../../utils";
import IBlazPayFactortyAbi from "../../constants/factory/abi.json";
import { useCurrency } from "../../hooks/Tokens";
import { toast } from "react-toastify";

interface ButtonsProps {
  value: string;
  isLoading?: boolean;
  stateValue?: string;
  className?: string;
}
const Button = ({ value, isLoading, stateValue, className }: ButtonsProps) => {
  return (
    <button
      className={`${className} hover:opacity-75 w-full xs:text-base text-[14px] font-bold  py-3 leading-[1.20] outline-none border-none
    bg-gradient-to-r from-[#FF3503] to-[#FFA100] rounded-full`}
    >
      {!isLoading ? stateValue : value}
    </button>
  );
};

interface ImportPoolModalProps {
  isActive: boolean;
  className?: string;
  setIsActive: (x: boolean) => void;
}
const ImportPoolModal = ({
  isActive,
  className,
  setIsActive,
}: ImportPoolModalProps) => {
  const [openModal, setOpenModal] = useState<string>("");

  const [firstCrypto, setFirstCrypto] = useState<any>(null);
  const [secondCrypto, setSecondCrypto] = useState<any>(null);
  const [tokens, setTokens] = useState<{
    token0: Token;
    token1: Token;
  }>();
  let chainQuery = useQuery({
    queryKey: ["chains"],
    queryFn: getChains,
  });
  const {
    address: account,
    provider: library,
    chainId,
    switchChain,
  } = useWallet();
  const addPossiblePair = usePossiblePair();

  const currencyA = useCurrency(firstCrypto?.address);
  const currencyB = useCurrency(secondCrypto?.address);

  const chains = chainQuery?.data?.filter((chain: any) =>
    [56, 137, 167000].includes(chain.id)
  );
  chainQuery = { ...chainQuery, data: chains };

  const handleClose = () => {
    setOpenModal("");
  };

  const resetToToken = () => {
    setSecondCrypto(null);
  };

  const getTokens = async () => {
    try {
      if (chainId && library && currencyA && currencyB) {
        const token0 = wrappedCurrency(currencyA, chainId);
        const token1 = wrappedCurrency(currencyB, chainId);
        if (token0 && token1) setTokens({ token0, token1 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const importPair = async () => {
    try {
      if (tokens?.token0 && tokens?.token1) {
        addPossiblePair(tokens.token0 as Token, tokens.token1 as Token);
        setIsActive(false);
        toast.success("Pool successfully imported");
      } else toast.error("Pool not found");
    } catch (error: any) {
      toast.error(error?.message || "Pool not found");
    }
  };

  useEffect(() => {
    getTokens();
  }, [firstCrypto, secondCrypto]);

  const handleTokenChange = (data: any) => {
    switch (openModal) {
      case "first": {
        setFirstCrypto(data);
        break;
      }
      case "second":
        setSecondCrypto(data);
        break;
    }
  };
  return (
    <>
      {isActive ? (
        <section
          // style={{ backdropFilter: "blur(10px)" }}
          className={`xs:w-[400px] w-[calc(100%-20px)] z-[30] ${className} bg-black rounded-3xl border border-primary pt-8 pb-12 px-6 flex flex-col items-center`}
        >
          <div className="flex flex-row justify-between items-center w-full">
            <div className="self-start">
              <IoIosArrowBack
                onClick={() => setIsActive(false)}
                className="xs:text-3xl text-2xl font-bold cursor-pointer hover:text-white/50"
              />
            </div>
            <h1 className="text-xl font-bold">Import Pool</h1>
            <div>
              <FaRegQuestionCircle className="xs:text-2xl text-xl cursor-pointer hover:text-white/50" />
            </div>
          </div>

          <div className="w-full mt-9" onClick={() => setOpenModal("first")}>
            <div className="w-full cursor-pointer hover:opacity-75 flex justify-center items-center gap-x-2 border border-primary rounded-3xl py-3">
              <img
                src={firstCrypto ? firstCrypto.logo : "/assets/btc.png"}
                className="h-7 w-7 rounded-full"
                alt=""
              />
              <p className="uppercase xs:text-2xl text-xl font-bold">
                {firstCrypto ? firstCrypto.symbol : "Select"}
              </p>
              <div>
                <IoIosArrowDown className="xs:text-3xl text-2xl cursor-pointer relative top-[2px]" />
              </div>
            </div>
          </div>
          <div className="w-full mt-6" onClick={() => setOpenModal("second")}>
            <div className="w-full cursor-pointer hover:opacity-75 flex justify-center items-center gap-x-2 border border-primary rounded-3xl py-3">
              <img
                src={secondCrypto ? secondCrypto.logo : "/assets/btc.png"}
                className="h-7 w-7 rounded-full"
                alt=""
              />
              <p className="uppercase xs:text-2xl text-xl font-bold">
                {secondCrypto ? secondCrypto.symbol : "Select"}
              </p>
              <div>
                <IoIosArrowDown className="xs:text-3xl text-2xl cursor-pointer relative top-[2px]" />
              </div>
            </div>
          </div>
          {!firstCrypto || !secondCrypto ? (
            <p className="text-center font-bold text-base mt-12">
              Select a token to find your liquidity.
            </p>
          ) : (
            <button
              className="hover:opacity-75 w-full xs:text-base text-[14px] font-bold xs:py-[15px] py-3 leading-[1.20] outline-none border-none
            bg-gradient-to-r from-[#FF3503] to-[#FFA100] rounded-full  mt-12"
              onClick={importPair}
            >
              Import Pool
            </button>
          )}
        </section>
      ) : null}
      <div className="relative z-40">
        <TokenModal
          open={openModal}
          chains={chainQuery.data}
          handleClose={handleClose}
          handleChange={handleTokenChange}
          swap={false}
          resetToToken={resetToToken}
        />
      </div>
    </>
  );
};

interface PositionCardProps {
  pair: Pair;
  // eslint-disable-next-line react/no-unused-prop-types
  showUnwrapped?: boolean;
}

const FullPositionCard = ({ pair }: PositionCardProps) => {
  const { address: account, chainId } = useWallet();
  const loadedChainId: ChainId = useMemo(() => chainId as ChainId, [chainId]);
  const currency0 = unwrappedToken(pair.token0);
  const currency1 = unwrappedToken(pair.token1);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [showMore, setShowMore] = useState(false);

  const userPoolBalance = useTokenBalance(
    account ?? undefined,
    pair.liquidityToken
  );
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined;

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(
            pair.token0,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
          pair.getLiquidityValue(
            pair.token1,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
        ]
      : [undefined, undefined];
  return (
    <>
      <section className="xs:mt-5 mt-4 xs:w-[416px] w-full h-[110px] relative xs:left-0 -left-4 ">
        <div
          onClick={() => setIsActive((x: boolean) => !x)}
          className="h-full w-full cursor-pointer  absolute top-0 left-0 z-10 flex flex-col justify-center "
        >
          <div className="flex  items-center gap-x-1 xs:pl-5 pl-6">
            <DoubleCurrencyLogo
              currency0={currency0}
              currency1={currency1}
              margin
              size={32}
            />
            <p className="xs:text-base text-xs">
              {!currency0 || !currency1 ? (
                <Dots>Loading</Dots>
              ) : (
                `${
                  currency0 === ETHER
                    ? NATIVE_SYMBOLS[loadedChainId]
                    : currency0.symbol
                }/${
                  currency1 === ETHER
                    ? NATIVE_SYMBOLS[loadedChainId]
                    : currency1.symbol
                }`
              )}
            </p>
          </div>
          {/* <div className="flex items-center gap-x-1 xs:pl-5 pl-6">
              <p className="xs:text-[12px] text-[10px] flex gap-x-1 items-center">
                Min 184886 / max470 988 Cake Per BNB{" "}
                <span>
                  <AiOutlineSwap className="text-2xl" />
                </span>
              </p>
              <button className="border border-[#14D204] rounded-full xs:px-4 px-2 xs:py-2 py-1 xs:text-[10px] text-[8px] ml-4">
                0.25%{" "}
              </button>
            </div> */}
          {/* dorp down button */}
          <div className="absolute xs:top-1 top-4 xs:-right-3  -right-6  cursor-pointer">
            <IoIosArrowDown
              className={`xs:text-3xl text-2xl font-bold text-white ${
                isActive && "rotate-180"
              } transition-all duration-250 ease-in-out `}
            />
          </div>
        </div>
        <YourLiquiditySvgWrapper className="absolute top-2 xs:-left-1 left-3 h-full w-full z-0 xs:scale-[1.25] scale-y-[1.40]  scale-x-[1.1]" />
      </section>
      {/* drop down */}
      <section
        className={`
        ${!isActive && "hidden"} w-full xs:px-6 xs:mt-4
      `}
      >
        <div className="flex w-full justify-between xs:text-base text-xs mt-2 items-center">
          <p>
            Pooled
            <span className="uppercase">
              {currency0 === ETHER
                ? NATIVE_SYMBOLS[loadedChainId]
                : currency0.symbol}
            </span>
            :
          </p>
          <div className="flex gap-x-1 items-center">
            <span>{token0Deposited?.toSignificant(6)}</span>
            <CurrencyLogo
              size="20px"
              style={{ marginLeft: "8px" }}
              currency={currency0}
            />
          </div>
        </div>
        <div className="flex w-full justify-between xs:text-base text-xs mt-2 items-center">
          <p>
            Pooled{" "}
            <span className="uppercase">
              {currency1 === ETHER
                ? NATIVE_SYMBOLS[loadedChainId]
                : currency1.symbol}
            </span>{" "}
            :
          </p>
          <div className="flex gap-x-1 items-center">
            <span>{token1Deposited?.toSignificant(6)}</span>
            <CurrencyLogo
              size="20px"
              style={{ marginLeft: "8px" }}
              currency={currency1}
            />
          </div>
        </div>
        <div className="flex justify-between xs:text-base text-xs items-center mt-2">
          <p>Your pool tokens</p>
          <span>
            {userPoolBalance ? userPoolBalance.toSignificant(4) : "-"}
          </span>
        </div>
        <div className="flex justify-between items-center xs:text-base text-xs mt-2">
          <p>Your pool share</p>
          <span>
            {poolTokenPercentage ? `${poolTokenPercentage.toFixed(2)}%` : "-"}
          </span>
        </div>
        <div className="flex justify-between items-center mt-4">
          {/* <Link to={`/defi/liquidity/addliquidity/${pair.token0.address}/${pair.token1.address}`}> */}
          <Link
            to={`/defi/liquidity/addliquidity/${currencyId(
              currency0
            )}/${currencyId(currency1)}`}
          >
            <button className="hover:opacity-75 outline-none border-none bg-gradient-to-r from-primary to-secondary xs:px-16 px-10 py-2 rounded-full xs:text-base text-[14px] font-bold">
              Add
            </button>
          </Link>
          <Link
            to={`/defi/liquidity/remove/${currencyId(currency0)}/${currencyId(
              currency1
            )}`}
          >
            <button className="hover:opacity-75 outline-none border-none bg-gradient-to-r from-primary to-secondary xs:px-16 px-10 py-2 rounded-full xs:text-base text-[14px] font-bold">
              Remove
            </button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default function YourLiquidity() {
  const [isSettingsActive, setIsSettingsActive] = useState<boolean>(false);
  const [isImportPoolActive, setIsImportPoolActive] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const { address: account } = useWallet();
  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs();
  const tokenPairsWithLiquidityTokens = useMemo(
    () =>
      trackedTokenPairs.map((tokens) => ({
        liquidityToken: toV2LiquidityToken(tokens),
        tokens,
      })),
    [trackedTokenPairs]
  );
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  );
  const [v2PairsBalances, fetchingV2PairBalances] =
    useTokenBalancesWithLoadingIndicator(account ?? undefined, liquidityTokens);

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan("0")
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  );

  const v2Pairs = usePairs(
    liquidityTokensWithBalances.map(({ tokens }) => tokens)
  );
  const v2IsLoading =
    fetchingV2PairBalances ||
    v2Pairs?.length < liquidityTokensWithBalances.length ||
    v2Pairs?.some((V2Pair) => !V2Pair);

  const allV2PairsWithLiquidity = v2Pairs
    .map(([, pair]) => pair)
    .filter((v2Pair): v2Pair is Pair => Boolean(v2Pair));

  return (
    <div className="relative defi-gradient-container min-h-screen md:pt-40 xs:pt-44 pt-52 w-full overflow-hidden swap-container text-white flex justify-center  xs:px-0 px-8">
      <div
        style={{ backdropFilter: "blur(28px)" }}
        className="xs:w-[480px] h-fit relative bg-[rgba(0,0,0,0.3)]  w-full flex flex-col items-center px-6 pt-6 pb-12 border border-primary rounded-3xl text-white"
      >
        <div className="flex justify-between items-start w-full">
          <div className="flex flex-col items-start py-4">
            <h1 className="font-bold text-2xl">Your Liquidity</h1>
            <p className="text-[12px]  font-medium">
              List of All your Liquidity
            </p>
          </div>
          <div className="flex gap-x-4 items-center py-4 relative">
            <IoMdSettings
              type="button"
              onClick={() => setIsSettingsActive((x: boolean) => !x)}
              className="xs:text-3xl text-2xl hover:text-[#444]"
            />
            <Settings
              closeModal={setIsSettingsActive}
              isActive={isSettingsActive}
              className="absolute xs:top-0 -top-4 xs:right-0 -right-6"
            />
          </div>
        </div>
        <div
          onClick={() => setIsActive((x: boolean) => !x)}
          className="flex cursor-pointer gap-x-2 items-center w-full justify-start mb-10"
        >
          <div>
            {isActive ? (
              <>
                {" "}
                <MdOutlineCheckBox className="text-[#FF7C03] text-2xl cursor-pointer" />
              </>
            ) : (
              <>
                <MdOutlineCheckBoxOutlineBlank className="text-[#ff7c03] text-2xl" />{" "}
              </>
            )}
          </div>
          <p className="text-[12xp]">hide closed Postions</p>
        </div>

        {!account ? (
          <LightCard padding="40px">
            <p className="xs:text-base text-xs">
              Connect to a wallet to view your liquidity.
            </p>
          </LightCard>
        ) : v2IsLoading ? (
          <LightCard padding="40px">
            <p className="xs:text-base text-xs">
              <Dots>Loading</Dots>
            </p>
          </LightCard>
        ) : allV2PairsWithLiquidity?.length > 0 ? (
          <>
            {allV2PairsWithLiquidity.map((v2Pair) => (
              <FullPositionCard
                key={v2Pair.liquidityToken.address}
                pair={v2Pair}
              />
            ))}
          </>
        ) : (
          <LightCard padding="40px">
            <p className="xs:text-base text-xs">No liquidity found.</p>
          </LightCard>
        )}

        <div className="mt-3">
          <p className="xs:text-base text-xs">
            Unable to see joined pool?{" "}
            <button
              onClick={() => setIsImportPoolActive(true)}
              className="text-[#ff3503]"
            >
              Import it.
            </button>
          </p>
        </div>
        <Link className="w-full" to="/defi/liquidity/addliquidity">
          <Button
            value="Add Liquidity"
            className="xs:mt-6 mt-4"
            isLoading={true}
            stateValue="Adding..."
          />
        </Link>
        <ImportPoolModal
          className="absolute top-10 left-1/2 -translate-x-1/2"
          setIsActive={setIsImportPoolActive}
          isActive={isImportPoolActive}
        />
      </div>
    </div>
  );
}

import { ChainId } from 'blazpay-sdk';
import MULTICALL_ABI from './abi.json';

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.BSCMAINNET]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb', // TODO
  [ChainId.POLYGON]: '0xa1B2b503959aedD81512C37e9dce48164ec6a94d', // TODO
  [ChainId.TAIKO]: '0xb3aFD07aEE45aE8ef9cefAcD6C14c67bF3994fD9', //
  //@ts-ignore
  [ChainId.FIRE]: '',
};

export { MULTICALL_ABI, MULTICALL_NETWORKS };

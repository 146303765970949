import axiosInstance from '../utils/axios';

export const getAllCurrencies = async () => {
  try {
    const res = await axiosInstance.get("/defi/transak");
    return res?.data?.data;
  } catch (error) {
    console.log(error)
  }
}

import React from 'react'
import UserIcon from '../../../assets/UserIcon.svg'

interface UserMessageProps {
    text: string;
  }

const UserMessage = ({text} : UserMessageProps) => {
  return (
    <div className='flex justify-end'>
        <div className='flex items-center gap-4'>
            <div className='my-2 p-3 bg-[#2F2F2F] text-[#FFFFFF] text-sm rounded-r-2xl rounded-b-2xl max-w-xs'>
                {text}
            </div>
            <img className='h-[55px]' src={UserIcon} alt='user-img' />
        </div>
    </div>
  )
}

export default UserMessage;
import './style.css';

const ComingSoon = () => {
  return (
    <div className="h-screen  relative w-full bg-black flex justify-center items-center overflow-hidden">
      <div className="container mx-auto">
        <h1
          className="ctext 2xl:text-8xl xl:text-7xl  lg:text-6xl sm:text-5xl text-2xl -mt-5 text-white uppercase text-center z-50"
          style={{ letterSpacing: '15px' }}
        >
          coming soon
        </h1>
      </div>
      <div className="earth"></div>
    </div>
  );
};

export default ComingSoon;

import React from 'react'
import SwapAILogo from '../../../assets/SwapAI Icon.svg';
import './Scrollbar.css';

const SwapAiHero = () => {
  return (
    <div className='flex flex-col items-center overflow-x-scroll no-scrollbar'>

        <div className='flex flex-col justify-center mt-24 ml-10'>
            <h1 className='text-2xl font-medium mb-[-10px]'>Introducing </h1>
            <div className='flex items-center h-[100px]'>
                <h1 className='text-6xl font-bold'>Swap AI</h1>
                <img className='' src={SwapAILogo} alt='swap-ai' />
            </div>
            <p className='text-xl font-medium text-[#FF5500]'>Powered by Chain abstraction</p>
        </div>

        <div className='w-7/12 h-44 2xl:mt-24 xl:mt-16 mt-12 grid grid-cols-2'>

            <div className='w-[274px] h-[75px] border-[0.81px] border-[#FF5500] rounded-xl cursor-pointer '>
                <h1 className='text-xs text-[#B5B5B5] ml-3 mt-2'>What is Swap (AI)?</h1>
                <p className='text-[8px] text-[#ADADAD] ml-3 mt-1 w-11/12'>Don’t want to select from multiple cryptos? just write down a simple prompt and our AI will take care of the rest</p>
            </div>

            <div className='w-[274px] h-[75px] border-[0.81px] border-[#FF5500] rounded-xl cursor-pointer'>
                <h1 className='text-xs text-[#B5B5B5] ml-3 mt-1 '>Example Prompt</h1>
                <ul className='ml-[10px] w-11/12 list-disc '>
                    <li className='ml-2 text-[8px] text-[#ADADAD]'>swap on polygon: 50 MATIC for USDT</li>
                    <li className=' ml-2 text-[8px] text-[#ADADAD]'>Bridge Ethereum: 100Eth to BSC : BNB</li>
                    <li className=' ml-2 text-[8px] text-[#ADADAD]'>Use ENTER to select , and Up and Down Arrow keys to navigate suggestions.</li>
                </ul>
            </div>

            <div className='w-[274px] h-[75px] border-[0.81px] border-[#FF5500] rounded-xl cursor-pointer'>
                <h1 className='text-xs text-[#B5B5B5] ml-3 mt-2'>What does Swap AI do?</h1>
                <p className='text-[8px] text-[#ADADAD] ml-3 mt-1 w-11/12'>Don’t want to select from multiple cryptos? just write down a simple prompt and our AI will take care of the rest</p>
            </div>

            <div className='w-[274px] h-[75px] border-[0.81px] border-[#FF5500] rounded-xl cursor-pointer'>
                <h1 className='text-xs text-[#B5B5B5] ml-3 mt-2'>What is Swap AI all about?</h1>
                <p className='text-[8px] text-[#ADADAD] ml-3 mt-1 w-11/12'>Don’t want to select from multiple cryptos? just write down a simple prompt and our AI will take care of the rest</p>
            </div>

        </div>
    </div>
  )
}

export default SwapAiHero
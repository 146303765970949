import axiosInstance from "../utils/axios";
import axios from "axios";

export const getChains = async () => {
  const res = await axiosInstance.get("/admin/network");

  const chains = res.data.data.data;

  console.log(chains);
  return chains;
};

export const getTokens = async (chainId: any) => {
  if (!chainId) throw new Error(`Invalid chainId`);
  const res = await axiosInstance.get(`/admin/token/${chainId}`);
  const tokens = res.data.data.data;
  return tokens;
};

export const getTokenBalance = async (walletAddress: any, chainId: any) => {
  const res = await axiosInstance.get(`/wallet`, {
    params: {
      address: walletAddress,
      chainId,
    },
  });
  return res.data;
};

const getEthPriceUSD = async () => {
  const res = await axios({
    url: "https://li.quest/v1/tokens",
    params: { chains: 1 }, // Assuming chainId 1 corresponds to ETH
  });

  const ethToken = res.data.tokens[1].find(
    (token: { symbol: any }) => token.symbol === "ETH"
  );
  return ethToken ? ethToken.priceUSD : null;
};

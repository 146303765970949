import React from 'react'

const BalanceWrapper = ({fromToken,fromBalance}:{fromToken:any,fromBalance:any}) => {
    return (
        <h2 className="text-[13px] px-4 mt-2 flex items-center gap-x-2 font-bold text-[rgba(255,255,255,0.25)]">
          Balance :
          {!fromToken ? (
            <>
              <p>Please select tokens...</p>
            </>
          ) : (
            <>
              {
                <span className="font-bold text-white">
                  {!fromToken ? "0" : <>{Number(fromBalance).toFixed(6)}</>}
                </span>
              }
            </>
          )}
        </h2>
      );
}

export default BalanceWrapper

// Types, interfaces and enums for buy and sell operations

export enum TradeType {
  ONRAMP = "ONRAMP",
  OFFRAMP = "OFFRAMP",
}

export type OnOrOffRamping = "ONRAMP" | "OFFRAMP";

export interface BasicQuote {
  amount: number;
  cryptoCurrency: string;
  tradeType: OnOrOffRamping;
  onNewQuote: (data: any) => void;
}

export interface RampingQuotes extends BasicQuote {
  fiat: string;
  fiatType: number;
  networkOnramp: number;
  cryptoChangelly: string;
  networkAlchemy: string;
  walletAddress: `0x${string}` | undefined;
  onFirstQuote: () => void;
}

export interface OnrampQuote extends BasicQuote {
  networkOnramp: number;
  fiatType: number;
}

export interface AlchemyQuote extends BasicQuote {
  network: string;
  fiat: string;
}

export interface ChangellyQuote extends BasicQuote {
  fiat: string;
  walletAddress: `0x${string}` | undefined;
  cryptoChangelly: string;
}

export type QuoteProps = {
  source: string;
  fee: number;
  rate: number;
  amount: number;
  route?: string;
};

export type Order = {
  orderType: string;
  fiat: string;
  crypto: string;
  amount: number | undefined;
  source: string;
  providerCode: string;
  walletAddress: `0x${string}` | undefined;
};

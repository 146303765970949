import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import Routes from "./routes";
import { WalletProvider } from "./contexts/WalletContext";
import {
  useWallet,
  WalletProvider as WalletProviderTron,
} from '@tronweb3/tronwallet-adapter-react-hooks';
import {
  TronLinkAdapter,
  WalletConnectAdapter,
} from '@tronweb3/tronwallet-adapters';
import { useMemo } from 'react';
import { WalletConnectChainID } from '@tronweb3/walletconnect-tron';
import { ChainNetwork } from '@tronweb3/tronwallet-abstract-adapter';
import { TronWalletProvider } from './contexts/TronWalletContext';
import { SolanaWalletProvider } from './contexts/SolanaWalletContext';
import { ChainProvider } from './contexts/ChainContext';

import ApplicationUpdater from './state/application/updater';
import MulticallUpdater from './state/multicall/updater';
import TransactionUpdater from './state/transactions/updater';
import ListsUpdater from './state/lists/updater';
import { Provider, ProviderProps } from 'react-redux';
import { store, persistor } from './state';
import { PersistGate } from 'redux-persist/integration/react';

const config = {
  network: ChainNetwork.Mainnet,
  options: {
    relayUrl: 'wss://relay.walletconnect.com',
    projectId: 'cf0545f2b1063ac84b6804ee8995cc74',
    metadata: {
      name: 'Blazpay',
      description: 'Blazpay',
      url: 'https://blazpay.com',
      icons: ['https://blazpay.com/blazpoint.png'],
    },
  },
  web3ModalConfig: {
    explorerRecommendedWalletIds: [
      '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369',
      '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    ],
  },
};

function App() {
  const queryClient = new QueryClient();
  const adapters = useMemo(
    () => [new TronLinkAdapter(), new WalletConnectAdapter(config)],
    []
  );
  const providerProps: ProviderProps = {
    store: store,
  };


  return (
    <>
      <QueryClientProvider client={queryClient}>
        <WalletProvider>
          <Provider {...providerProps}>
            <PersistGate loading={null} persistor={persistor}>
              <>
                <ListsUpdater />
                <ApplicationUpdater />
                <TransactionUpdater />
                <MulticallUpdater />
              </>
              <WalletProviderTron
                adapters={adapters}
                disableAutoConnectOnLoad={true}
              >
                <TronWalletProvider>
                  <SolanaWalletProvider>
                    <>
                      <ChainProvider>
                        <Routes />
                      </ChainProvider>
                    </>
                  </SolanaWalletProvider>
                </TronWalletProvider>
              </WalletProviderTron>
            </PersistGate>
          </Provider>
        </WalletProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;

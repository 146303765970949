import { useEffect, useMemo, useRef } from "react";
import "./style.css";
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useQuery } from "@tanstack/react-query";
import CustomModal from "../../component/CustomModal";
import { IoClose, IoReload } from "react-icons/io5";
import clsx from "clsx";
import { Transak, TransakConfig } from "@transak/transak-sdk";
import axiosInstance from "../../utils/axios";
import { Link } from "react-router-dom";
import { LuHistory } from "react-icons/lu";

import { getAllCurrencies } from "../../apis/transak.api";
import { useWallet } from "../../hooks/useWallet";
import { toast } from "react-toastify";
import { BsThreeDotsVertical } from "react-icons/bs";
import Loader from "../../component/loader";
import { OnrampWebSDK } from "@onramp.money/onramp-web-sdk";
import { TradeManager } from "../../utils/trade";
import { getChains } from "../../apis/chain.api";
import { QuoteProps, TradeType } from "../../utils/interfaces.trade";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { handleTransactionError } from "../../utils/chains";
import BuySellModal from "../../component/modals/BuySellModal";
import { createOrder } from "../../utils/functions";
import QuotesLoader from "../../component/QuotesLoader";

const UpperComponent = ({
  type,
  fiatCurrency,
  cryptoCurrency,
  amount,
  setAmount,
  setShowCryptoModal,
  setShowFiatModal,
}: any) => {
  const valueType = type === "sell" ? "crypto" : "fiat";
  const currency = valueType === "fiat" ? fiatCurrency : cryptoCurrency;

  const handleChange = (event: any) => {
    const newValue = event.target.value;
    if (newValue === "" || /^[0-9]*\.?[0-9]+$/.test(newValue)) {
      setAmount(event);
    }
  };
  return (
    <>
      <div className="flex items-center justify-between w-full sm:px-8 px-6  sm:py-7 py-5 rounded-3xl border mt-8">
        <div className="flex flex-col items-start">
          <p className="sm:text-2xl text-xl">
            {valueType === "fiat" ? "Pay" : "Token"}
          </p>
          <div
            onClick={() =>
              valueType === "fiat"
                ? setShowFiatModal(true)
                : setShowCryptoModal(true)
            }
            className="w-full"
          >
            {currency ? (
              <div className="flex items-center">
                {valueType === "fiat" ? (
                  <>
                    <div
                      className="w-8 h-8 flex justify-center items-center  overflow-x-hidden overflow-y-hidden"
                      dangerouslySetInnerHTML={{ __html: fiatCurrency.icon }}
                    />
                    <p className="text-xl mt-[2px] ml-2">
                      {fiatCurrency.symbol}
                    </p>
                  </>
                ) : (
                  <>
                    <img
                      src={cryptoCurrency.image?.small}
                      alt=""
                      className="h-7 w-7"
                    />
                    <p className="text-xl mt-[2px] ml-2">
                      {cryptoCurrency.symbol}
                    </p>
                  </>
                )}

                <IoIosArrowDown className="sm:text-2xl text-xl ml-1 cursor-pointer mt-[2px]" />
              </div>
            ) : (
              <div
                className="flex gap-x-2 items-center cursor-pointer"
                onClick={() => {
                  setShowFiatModal(true);
                }}
              >
                <span className="sm:text-base text-xs">
                  Select {valueType === "fiat" ? "Currency" : "Token"}
                </span>
                <IoIosArrowDown className="text-xl relative top-1" />
              </div>
            )}
          </div>
        </div>
        <div className="w-1/2">
          <input
            key={"i1"}
            type="text"
            value={amount}
            placeholder="Enter amount"
            onChange={handleChange}
            className="bg-transparent border-none outline-none text-end sm:text-2xl text-base w-full "
          />
        </div>
      </div>
    </>
  );
};

const LowerComponent = ({
  type,
  fiatCurrency,
  cryptoCurrency,
  amount,
  setShowCryptoModal,
  setShowFiatModal,
}: any) => {
  const valueType = type === "buy" ? "crypto" : "fiat";
  const currency = valueType === "fiat" ? fiatCurrency : cryptoCurrency;

  return (
    <>
      <div className="flex items-center justify-between w-full sm:px-8 px-6 sm:py-7 py-5 rounded-3xl border mt-2">
        <div className="flex flex-col items-start w-full">
          <p className="sm:text-2xl text-xl">Estimated Receive</p>
          <div
            onClick={() =>
              valueType === "crypto"
                ? setShowCryptoModal(true)
                : setShowFiatModal(true)
            }
          >
            {currency ? (
              <div className="flex items-center">
                {valueType === "crypto" ? (
                  <>
                    <img
                      src={cryptoCurrency.image?.small}
                      alt=""
                      className="sm:h-7 h-6 sm:w-7 h-6 border-2 rounded-full"
                    />
                    <p className="text-xl mt-[2px] ml-2">
                      {cryptoCurrency.symbol}
                    </p>
                  </>
                ) : (
                  <>
                    <div
                      className="w-[40px] h-[40px] flex items-center justify-center rounded-full"
                      dangerouslySetInnerHTML={{ __html: fiatCurrency.icon }}
                    />
                    <p className="text-xl mt-[2px] ml-2">
                      {fiatCurrency.symbol}
                    </p>
                  </>
                )}
                <IoIosArrowDown className="sm:text-2xl text-xl ml-1 cursor-pointer mt-[2px]" />
              </div>
            ) : (
              <div
                className="flex gap-x-2 cursor-pointer"
                onClick={() => {
                  setShowCryptoModal(true);
                }}
              >
                <span className="sm:text-base text-xs">
                  Select {valueType === "crypto" ? "Token" : "Currency"}
                </span>
                <IoIosArrowDown className="text-xl relative sm:top-[6px]" />
              </div>
            )}
          </div>
        </div>
        <div className="w-1/2">
          <input
            disabled={true}
            type="text"
            className="bg-transparent border-none outline-none text-end text-2xl w-full "
            value={amount}
          />
        </div>
      </div>
    </>
  );
};

const sort = (a: any, b: any) => {
  return +b.amount - +a.amount;
};

const Buy = () => {
  const [type, setType] = useState<string>("buy");
  const [searchText, setSearchText] = useState("");
  const [showFiatModal, setShowFiatModal] = useState(false);
  const [showCryptoModal, setShowCryptoModal] = useState(false);
  const [fiatCurrency, setFiatCurrency] = useState<any>(null);
  const [cryptoCurrency, setCryptoCurrency] = useState<any>(null);
  const [receiveAmount, setReceiveAmount] = useState<number>();
  const [amount, setAmount] = useState<number>();
  const [networkId, setNetworkId] = useState("");
  const { address } = useWallet();
  const [fiatCurrencyQuery, setFiatCurrencyQuery] = useState<any>([]);
  const [cryptoCurrencyQuery, setCryptoCurrencyQuery] = useState<any>([]);
  const [isLoadingCurrencies, setIsLoadingCurrencies] = useState<boolean>(true);
  const [quotes, setQuotes] = useState<any>([]);
  const [paymentProvider, setPaymentProvider] = useState<string>("AlchemyPay");
  const [selectedQuote, setSelectedQuote] = useState<any>();
  const [isQuoteLoading, setIsQuoteLoading] = useState(false);
  const [order, setOrder] = useState<any>();
  const [isCalculateActive, setIsCalculateActive] = useState<boolean>(false);
  const { open: openWallet } = useWeb3Modal();
  const [isBuySellModalActive, setIsBuySellModalActive] =
    useState<boolean>(false);
  const managerRef = useRef(new TradeManager());
  const manager = managerRef.current;

  const chainQuery = useQuery({
    queryKey: ["chains"],
    queryFn: getChains,
  });

  useEffect(() => {
    const getCurrencies = async () => {
      try {
        const res = await getAllCurrencies();
        if (res?.currency || res?.crypto) setIsLoadingCurrencies(false);
        setFiatCurrencyQuery(res?.currency);
        setCryptoCurrencyQuery(res?.crypto);
      } catch (error) {
        console.log(error);
      }
    };

    getCurrencies();
  }, []);

  const handleTypeChange = (type: string) => {
    setType(type);
    setQuotes([]);
    setSelectedQuote(null);
    setAmount(0.0);
    setFiatCurrency(null);
    setCryptoCurrency(null);
    setReceiveAmount(0);
    setIsCalculateActive(false);
  };
  const handleFiatChange = (data: any) => {
    setQuotes([]);
    setSelectedQuote(null);
    setFiatCurrency(data);
    setShowFiatModal(false);
    setIsCalculateActive(false);
  };
  const handleCryptoChange = (data: any) => {
    setQuotes([]);
    setSelectedQuote(null);
    setCryptoCurrency(data);
    setShowCryptoModal(false);
    setIsCalculateActive(false);
  };
  const handleAmountChange = (e: any) => {
    setAmount(e.target.value);
    setQuotes([]);
    setSelectedQuote(null);
    setIsCalculateActive(false);
  };
  const filteredFiatCurrencies = useMemo(() => {
    return (
      fiatCurrencyQuery?.filter((item: any) =>
        searchText
          ? item.name.toLowerCase().includes(searchText) ||
            item.symbol.toLowerCase().includes(searchText.toLowerCase())
          : true
      ) || []
    );
  }, [fiatCurrencyQuery, searchText]);

  const filteredCryptoCurrencies = useMemo(() => {
    const processedNames = new Set();

    let filteredArray: any = [];

    cryptoCurrencyQuery?.forEach((item: any) => {
      const matchesSearchText =
        searchText.length !== 0
          ? item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.symbol?.toLowerCase().includes(searchText.toLowerCase())
          : true;

      const matchesNetworkId = networkId
        ? item.network?.chainId === networkId
        : false;

      const isUniqueName = !processedNames.has(item.name.toLowerCase());

      if (matchesSearchText && matchesNetworkId && isUniqueName) {
        processedNames.add(item.name.toLowerCase());
        filteredArray.push(item);
      }
    });
    return filteredArray;
  }, [cryptoCurrencyQuery, searchText, networkId]);
  const networks = useMemo(() => {
    const data: any = [];

    for (const item of cryptoCurrencyQuery || []) {
      const chainId = item.network?.chainId;
      if (!chainId || data.some((network: any) => network.id === chainId))
        continue;
      if (item.image_bk) {
        data.push({
          id: item.network.chainId,
          name: item.network.name,
          image: item.image.large,
        });
      }
    }
    // console.log(data)
    setNetworkId(data[0]?.id);
    return data;
  }, [cryptoCurrencyQuery]);

  const handleSubmit = () => {
    if (!cryptoCurrency) {
      toast.error("Select Crypto Currency");
      return;
    }
    if (!fiatCurrency) {
      toast.error("Select  Fiat currency");
      return;
    }
    if (!amount) {
      toast.error("Enter amount");
      return;
    }

    const transakConfig: TransakConfig = {
      apiKey: "63525498-dde5-49ca-a4f6-6936969af233",
      environment: Transak.ENVIRONMENTS.PRODUCTION,
      walletAddress: address!,
      [type === "buy" ? "fiatAmount" : "cryptoAmount"]: +amount,
      fiatCurrency: fiatCurrency.symbol,
      countryCode: fiatCurrency.country,
      network: cryptoCurrency.network.name,
      cryptoCurrencyCode: cryptoCurrency.symbol,
      productsAvailed: type.toUpperCase(),
      hideMenu: true,
      hideExchangeScreen: true,
    };

    const transak = new Transak(transakConfig);

    transak.init();
    Transak.on(Transak.EVENTS.TRANSAK_ORDER_CREATED, async (orderData: any) => {
      console.log("ORDER_DETAILS: ", orderData);
    });

    Transak.on(
      Transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL,
      async (orderData: any) => {
        console.log(orderData);

        try {
          let data: any = {};
          if (type === "buy") {
            data = {
              moduleName: "buy",
              walletAddress: orderData.status.walletAddress,
              txHash: orderData.status.id,
              value: orderData.status.cryptoAmount.toString(),
              route: "transak",
              from: "",
              to: orderData.status.walletAddress,
              txFrom: {
                chainId: 0,
                tokenName: orderData.status.fiatCurrency,
                value: orderData.status.fiatAmountInUsd.toString(),
              },
              txTo: {
                chainId: findNetworkId(orderData.status.network),
                tokenName: orderData.status.cryptoCurrency,
                value: orderData.status.cryptoAmount.toString(),
              },
            };
          } else {
            // Change Here
            data = {
              moduleName: "buy",
              walletAddress: orderData.status.walletAddress,
              txHash: orderData.status.id,
              value: orderData.status.cryptoAmount.toString(),
              route: "transak",
              from: "",
              to: orderData.status.walletAddress,
              txFrom: {
                chainId: 0,
                tokenName: orderData.status.fiatCurrency,
                value: orderData.status.fiatAmountInUsd.toString(),
              },
              txTo: {
                chainId: findNetworkId(orderData.status.network),
                tokenName: orderData.status.cryptoCurrency,
                value: orderData.status.cryptoAmount.toString(),
              },
            };
          }
          const res = await axiosInstance.post(`/defi/transaction`, data);

          console.log(res);
          toast.success("Purchase successful.");
        } catch (error) {
          console.log(error);
          toast.error("Something went wrong.");
        }

        transak.close();
      }
    );
  };

  const findNetworkId = (name: string) => {
    let id = 0;
    networks.map((e: any) => {
      if (e.name === name) {
        id = e.id;
      }
    });
    return Number(id);
  };
  useEffect(() => {
    setSearchText("");
  }, [showFiatModal, showCryptoModal]);

  const handleQuoteSelect = (quote: QuoteProps) => {
    setPaymentProvider(quote.source);
    setSelectedQuote(quote);
    setReceiveAmount(quote?.amount);
  };

  if (fiatCurrencyQuery?.isLoading || cryptoCurrencyQuery?.isLoading)
    return <div>Loading...</div>;

  const calculateQuote = async () => {
    console.log("Triggered quote calculation");
    try {
      setQuotes([]);
      setIsCalculateActive(false);
      setSelectedQuote(null);
      setIsQuoteLoading(true);

      if (!amount) return;

      await manager.init(chainQuery.data);
      await manager
        .getRampingQuotes({
          amount: amount,
          fiat: fiatCurrency.symbol,
          tradeType: type === "buy" ? TradeType.ONRAMP : TradeType.OFFRAMP,
          fiatType: fiatCurrency?.onRampType,
          networkOnramp: cryptoCurrency?.onRampCryptoId?.code,
          cryptoChangelly: cryptoCurrency?.changellyCryptoId?.symbol,
          networkAlchemy: cryptoCurrency?.alchemyCryptoId?.network,
          walletAddress: address,
          cryptoCurrency: cryptoCurrency.symbol,
          onNewQuote: (quote: any) => {
            setQuotes((prev: any) => {
              if (quote) {
                const newQuotes = [...prev, quote].sort(sort);
                setSelectedQuote(newQuotes[0]);
                setReceiveAmount(newQuotes[0]?.amount);
                setPaymentProvider(newQuotes[0]?.source);
                return newQuotes;
              } else return [];
            });
          },
          onFirstQuote: () => {
            setIsQuoteLoading(false);
          },
        })
        .finally(() => {
          if (quotes.length === 0) {
            console.log("No quotes!");
            setIsQuoteLoading(false);
          }
          setIsCalculateActive(true);
        });
      manager.setWalletAddress(address!);
    } catch (error: any) {
      console.log(error, "error");
      // console.log(error?.message, "Line 498");
    }
  };

  const buyTokens = async () => {
    try {
      if (!amount && !address)
        throw new Error("Provide Amount or connect wallet");
      let data = {
        orderType: type,
        fiat: fiatCurrency._id,
        crypto: cryptoCurrency._id,
        amount: amount,
        source: selectedQuote.route,
        network: cryptoCurrency.alchemyCryptoId.network,
        providerCode: selectedQuote.source,
        walletAddress: address,
      };
      if (paymentProvider === "AlchemyPay") {
        const order = await createOrder(data);
        setOrder(order);
        setIsBuySellModalActive(true);
      } else if (paymentProvider === "OnRamp") {
        const order = await createOrder(data);
        setOrder(order);
        data.network = cryptoCurrency?.onRampCryptoId?.network;
        const obj: any = {
          appId: 1,
          walletAddress: address,
          network: cryptoCurrency?.onRampCryptoId?.network,
          coinCode: cryptoCurrency?.changellyCryptoId?.symbol?.toLowerCase(),
          flowType: type === "buy" ? 1 : 2,
          fiatType: fiatCurrency?.onRampType,
          merchantRecognitionId: order?._id,
        };
        type === "buy"
          ? (obj.fiatAmount = Number(amount))
          : (obj.quantity = Number(amount));
        const onramp = new OnrampWebSDK(obj);
        onramp.show();
      } else if (
        paymentProvider !== "AlchemyPay" &&
        paymentProvider !== "Onramp"
      ) {
        const order = await createOrder(data);
        setOrder(order);
        window.location.href = order?.changelly?.redirectUrl;
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data || error?.response?.errors || error?.message
      );
    }
  };

  const getButtonState = () => {
    if (!address) return "connect_wallet";
    if (!fiatCurrency) return "select_currency";
    if (!cryptoCurrency) return "select_token";
    if (!amount) return "enter_amount";
    if (isQuoteLoading) return "Calculating...";
    if (!selectedQuote) return "calculate";
    return type === "buy" ? "Buy" : "Sell";
  };

  const handleButtonClick = async () => {
    const buttonState = getButtonState();

    switch (buttonState) {
      case "connect_wallet":
        return openWallet({ view: "Connect" });

      case "calculate":
        calculateQuote();
        break;
      case "Buy":
      case "Sell":
        try {
          await buyTokens();
        } catch (error) {
          handleTransactionError(error);
        }
        break;
    }
  };

  return (
    <section className="min-h-screen defi-gradient-container md:pb-0 pb-12 w-full relative md:pt-40 pt-52 flex justify-center text-white lg:px-0 md:px-6 px-2">
      <div
        className={`container h-full  flex ${
          quotes.length > 0
            ? "xl:flex-row flex-col xl:items-start items-center gap-x-12"
            : "flex-col items-center"
        } gap-y-4 justify-center pt-8 px-4 mx-auto`}
      >
        <div>
          <div
            className={`
        border border-primary sm:w-[520px] w-full h-fit sm:px-8 px-4 py-12 rounded-3xl`}
          >
            <div className=" relative flex flex-col w-full rounded-3xl">
              {/* Buy-sell btn */}

              <div className="inline sm:text-2xl text-xl sm:pl-7 pl-3 pr-3 flex justify-between">
                <div>
                  <button
                    className={clsx(
                      type === "buy" &&
                        "border-b border-primary text-[#ff3503] font-bold"
                    )}
                    onClick={() => handleTypeChange("buy")}
                  >
                    Buy
                  </button>
                  <button
                    className={clsx(
                      "ml-3",
                      type === "sell" &&
                        "border-b border-primary text-[#ff3503] font-bold"
                    )}
                    onClick={() => handleTypeChange("sell")}
                  >
                    Sell
                  </button>
                </div>
                <div className="flex items-center gap-x-2">
                  <Link to="/defi/buy-history">
                    <LuHistory className="cursor-pointer text-2xl cursor-pointer hover:text-[rgba(255,255,255,0.5)] transition-all duration-250 ease-in-out" />
                  </Link>
                  {selectedQuote && (
                    <IoReload
                      onClick={calculateQuote}
                      className="cursor-pointer text-2xl hover:text-[rgba(255,255,255,0.24)]"
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col items-center relative">
                <UpperComponent
                  type={type}
                  fiatCurrency={fiatCurrency}
                  cryptoCurrency={cryptoCurrency}
                  amount={amount === 0 ? "" : amount}
                  setAmount={handleAmountChange}
                  setShowCryptoModal={setShowCryptoModal}
                  setShowFiatModal={setShowFiatModal}
                />
                <div
                  className={`
             absolute   ${
               type && fiatCurrency === "buy"
                 ? "sm:top-36 top-32"
                 : "sm:top-[130px] top-28"
             } left-1/2 -translate-x-1/2 bg-black z-50
             h-10 w-10 rounded-lg border flex justify-center items-center`}
                >
                  <IoIosArrowDown className="text-3xl text-white" />
                </div>

                <LowerComponent
                  type={type}
                  fiatCurrency={fiatCurrency}
                  cryptoCurrency={cryptoCurrency}
                  setShowCryptoModal={setShowCryptoModal}
                  setShowFiatModal={setShowFiatModal}
                  amount={receiveAmount === 0 ? "" : receiveAmount}
                />
              </div>

              {/* Select Chain */}

              {type === "buy" && (
                <>
                  <div className="flex flex-col mt-2">
                    <p className="text-white">Receive address :</p>
                    <p className="text-white text-xs text-[rgba(255,255,255,0.75)]">
                      {address}
                    </p>
                  </div>
                </>
              )}

              <button
                onClick={handleButtonClick}
                className=" w-full py-3 mb-5 capitalize mt-8 leading-[1.0] rounded-full bg-gradient-to-r from-[#FE4B03] to-[#FD9100] text-xl"
              >
                {getButtonState().replace(/_/g, " ")}
              </button>
            </div>
            <div>
              <CustomModal
                open={showFiatModal}
                onClose={() => setShowFiatModal(false)}
              >
                <div className="flex flex-col items-center w-full">
                  <div className="flex flex-row justify-between w-full items-center mt-6">
                    <div></div>
                    <h1 className="text-2xl">Currency List</h1>
                    <div>
                      <IoClose
                        onClick={() => setShowFiatModal(false)}
                        className="text-3xl font-bold relative -left-5 cursor-pointer"
                      />
                    </div>
                  </div>
                  <input
                    value={searchText}
                    placeholder="Search currency"
                    className="px-4 py-2 bg-transparent outline-none border border-primary rounded-full sm:w-4/5 w-full my-5"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
                <p className="text-2xl text-wihte px-8 pb-6">All Currencies</p>
                <div className="flex">
                  {isLoadingCurrencies ? (
                    <>
                      <div className="flex items-center justify-center xs:w-[480px] w-full h-[360px]">
                        <Loader />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="grid grid-cols-2  chain-list w-full gap-y-3 sm:gap-x-6 gap-x-3 px-4
              scrollbar-thin scrollbar-thumb-primary scrollbar-thumb-rounded-full
              gap-0 justify-center flex-wrap max-h-96 overflow-auto"
                      >
                        {filteredFiatCurrencies?.map((item: any, i: number) => (
                          <div
                            key={i}
                            onClick={() => handleFiatChange(item)}
                            className={clsx(
                              "flex flex-row p-2 rounded-xl sm:w-[200px] w-full cursor-pointer border-t bg-[rgba(255,255,255,0.04)] items-center"
                            )}
                          >
                            <div className="sm:w-12 w-8 sm:h-12 h-8 flex pt-2 justify-center">
                              <div
                                className="sm:h-8 h-5 sm:w-8 w-5  overflow-hidden"
                                dangerouslySetInnerHTML={{ __html: item.icon }}
                              />
                            </div>
                            <div className="flex flex-col leading-[1.0]">
                              <p className="sm:text-2xl text-base">
                                {item.symbol}
                              </p>

                              <p className="text-sm text-[#999999] -mt-1">
                                {item.name}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </CustomModal>

              <CustomModal
                open={showCryptoModal}
                onClose={() => setShowCryptoModal(false)}
              >
                <div className="relative text-white flex">
                  <IoClose
                    className="h-4 w-4 cursor-pointer absolute right-5"
                    onClick={() => setShowCryptoModal(false)}
                  />
                  <div>
                    <div className="">
                      <h4 className="text-center font-semibold text-2xl">
                        Token List
                      </h4>

                      <input
                        placeholder="Search name of token"
                        className="px-8 max-sm:px-4 max-sm:py-1 py-2 text-base border bg-transparent outline-none border-primary placeholder:text-white rounded-full w-full my-5"
                        onChange={(e) => setSearchText(e.target.value)}
                      ></input>
                    </div>

                    <div className="flex sm:flex-row flex-col gap-3">
                      <div
                        className="grid sm:grid-cols-2 grid-cols-4  max-sm:grid-cols-4 max-sm:w-full
          scrollbar-thin scrollbar-thumb-scroll scrollbar-thumb-rounded-full
          chain-list w-[250px] p-2  gap-4 justify-center flex-wrap max-h-96 max-sm:max-h-20 overflow-auto"
                      >
                        {networks.map((chain: any) => (
                          <div
                            key={chain.id}
                            onClick={() => setNetworkId(chain.id)}
                            className={clsx(
                              "flex flex-col justify-center items-center p-2 rounded-xl cursor-pointer",
                              networkId === chain.id
                                ? "bg-[rgba(57,18,0,.72)]"
                                : "bg-[#130600]"
                            )}
                          >
                            <img
                              src={`/assets/chain/` + chain.id + `.png`}
                              className="h-7 w-7 rounded-full"
                              alt="bnb"
                            />
                            <p className="text-xs text-center">{chain.name}</p>
                          </div>
                        ))}
                      </div>

                      <div>
                        <p className="hidden max-sm:block">Tokens</p>
                        <div className="min-w-[320px] max-sm:w-full px-3 py-4 overflow-y-auto  overflow-x-hidden rounded-2xl bg-[#130600] flex-1 max-h-96 max-sm:max-h-60  scrollbar-thin scrollbar-thumb-scroll scrollbar-thumb-rounded-full">
                          {filteredCryptoCurrencies.map((token: any) => (
                            <>
                              {token.network.chainId === networkId && (
                                <div
                                  key={token.address}
                                  onClick={() => handleCryptoChange(token)}
                                  className="border-b border-b-borderGray px-3 py-2  lg:min-w-[340px]  flex justify-between cursor-pointer"
                                >
                                  <div className=" flex gap-3 items-center">
                                    <img
                                      src={token.image.small}
                                      className="w-8 h-8 rounded-full"
                                      alt="nb"
                                    />
                                    <div>
                                      <p className="text-sm">{token.symbol}</p>
                                      <p className="text-xs max-w-[100px]">
                                        {token.name}
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    <BsThreeDotsVertical />
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CustomModal>
            </div>
          </div>
        </div>
        {/* Provider list */}
        <div
          className={`pr-2 py-4 border border-primary w-96 rounded-xl ${
            quotes.length === 0 && "hidden"
          }`}
        >
          <h5 className="text-lg font-semibold pl-5 py-4">Provider List</h5>
          <div
            className={`flex flex-col gap-y-2 rounded-xl px-4 max-h-[500px] overflow-y-auto overflow-x-hidden
           scrollbar-thin scrollbar-thumb-primary scrollbar-thumb-rounded-full
          `}
          >
            {quotes.map((quote: QuoteProps, index: number) => {
              return (
                <div
                  onClick={() => handleQuoteSelect(quote)}
                  className={`w-full flex flex-col border ${
                    quote.source === paymentProvider && "border-primary"
                  } rounded-xl py-2 cursor-pointer `}
                >
                  <div
                    className={` px-5 flex justify-between items-center border-b ${
                      quote.source === paymentProvider && "border-primary"
                    } pb-2`}
                  >
                    <div className="flex  items-center">
                      <h1 className="text-xl font-bold capitalize">
                        {quote?.source}
                      </h1>
                      <span className="opacity-80">
                        {quote?.source !== quote?.route &&
                          quote?.route &&
                          `(${quote.route})`}
                      </span>
                    </div>
                    {/* Estimated receive */}
                    <p>
                      {quote?.amount}
                      {type === "buy" && <> {cryptoCurrency?.symbol}</>}
                      {type === "sell" && <>{fiatCurrency?.symbol} </>}
                    </p>
                  </div>
                  <div className="flex justify-between px-5 mt-2">
                    <p className="text-[14px]">
                      {quote?.fee} {fiatCurrency && fiatCurrency.symbol}
                    </p>
                    {index === 0 ? (
                      <span className="text-white px-4 py-1  text-xs rounded-lg bg-success">
                        Best
                      </span>
                    ) : (
                      <span className="text-xs">{index}</span>
                    )}
                  </div>
                </div>
              );
            })}
            <QuotesLoader
              isLoading={!isCalculateActive}
              className=""
              loadingText="Fetching More Quotes"
            />
          </div>
        </div>
        <BuySellModal
          handleClose={setIsBuySellModalActive}
          url={order?.alchemy}
          className="fixed left-1/2 -translate-x-1/2 top-32 z-[1000]"
          isActive={isBuySellModalActive}
        />
      </div>
    </section>
  );
};

export default Buy;

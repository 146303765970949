import React, { useState } from "react";
import LimitOpenOrders from "./LimitOpenOrders";
import LimitExpiredOrders from "./LimitExpiredOrders";
import LimitOrderHistory from "./LimitOrderHistory";

const LimitOrders = () => {
  const [currentNav, setCurrentNav] = useState<string>("open orders");
  const NAVS = [
    { value: "open orders", element: <LimitOpenOrders /> },
    { value: "expired orders", element: <LimitExpiredOrders /> },
    { value: "order history", element: <LimitOrderHistory /> },
  ];
  return (
    <div
      style={{ backdropFilter: "blur(28px)" }}
      className="w-full 2xl:h-[380px] h-[300px] border rounded-3xl border-primary bg-[rgba(0,0,0,0.3)]"
    >
      <ul className="w-full flex items-center justify-between pt-7 pb-3 border-b border-white/55 px-10">
        {NAVS.map((nav: any, index: number) => {
          return (
            <li
              key={index}
              onClick={() => setCurrentNav(nav.value)}
              className={`text-base pb-2 hover:opacity-90 active:text-[#ff3503] capitalize cursor-pointer leading-[1.0] font-bold ${
                nav.value === currentNav && "border-b border-primary"
              } `}
            >
              {nav.value}
            </li>
          );
        })}
      </ul>
      <>{NAVS.map((nav: any) => nav.value === currentNav && nav.element)}</>
    </div>
  );
};

export default LimitOrders;

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { chains } from './chains';

const projectId = 'cf0545f2b1063ac84b6804ee8995cc74';

const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://1rpc.io/eth',
};

const metadata = {
  name: 'Blazpay',
  description: 'Blazpay',
  url: 'https://blazpay.com',
  icons: ['https://blazpay.com/blazpoint.png'],
};

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  rpcUrl: '...', // Provide your RPC URL here
  defaultChainId: 1,
});

const web3Modal = createWeb3Modal({
  ethersConfig,
  //@ts-ignore
  chains: chains,
  projectId,
  enableAnalytics: true,
  allowUnsupportedChain: true,
  customWallets:[{
  id: 'eb58a4bf-61de-47c8-a42a-00922312cd62',
  name: 'Blazpay Wallet',
  homepage: 'www.blazpay.com', // Optional
  image_url: 'https://i.ibb.co/4fXy9yP/BZLogo.jpg', // Optional
  //@ts-ignore
  injected: [
    {
        "namespace": "eip155",
        "injected_id": "isBlzWallet"
    }
],
}]
});

export default web3Modal;

import React, { useState } from "react";
import LimitProviderListCard from "./LimitProviderListCard";
import { IoIosArrowDown } from "react-icons/io";
import LineLoader from "./LineLoader";
import AllProviders from "../common/AllProviders";
interface ProviderListProps {
  isActive: boolean;
  isQuoteLoading: boolean;
  quotes: any;
  className?: string;
  handleClick: (x: boolean) => void;
  selectQuote: (x: any) => void;
  logoURI: string;
  tokenSymbol: string;
}
const LimitProviderList = ({
  isActive,
  isQuoteLoading,
  quotes,
  className,
  handleClick,
  selectQuote,
  logoURI,
  tokenSymbol,
}: ProviderListProps) => {
  const [isShowMoreActive, setIsShowMoreActive] = useState<boolean>(false);
  const handleShowMore = () => setIsShowMoreActive((x) => !x);
  return (
    <>
      {isActive ? (
        <div
          style={{ backdropFilter: "blur(30px)" }}
          className={`h-[300px] ${
            isShowMoreActive &&
            "w-full 2xl:h-[690px] h-[610px] z-50 absolute left-0 top-0"
          } border border-primary px-6 py-5 rounded-3xl w-full ${className} bg-[rgba(0,0,0,0.50)]`}
        >
          <h1>Provider List</h1>
          {isQuoteLoading && (
            <p className="text-[11px] mt-3">
              More Providers can get close, But They are Delaying the
              answer......
            </p>
          )}
          <LineLoader className="mt-6" />
          <LimitProviderListCard
            isActive={!isShowMoreActive}
            className="mt-6"
            handleSelect={() => {}}
            route={quotes[0]?.route}
            source={quotes[0]?.source}
            logoURI={logoURI}
            amount={quotes[0]?.amount}
            tokenSymbol={tokenSymbol}
            usdPrice={quotes[0]?.usdPrice}
            index={0}
          />
          <AllProviders
            type="limit"
            isActive={isShowMoreActive}
            quotes={quotes}
            logoURI={logoURI}
            usdPrice={0}
            tokenSymbol={tokenSymbol}
            handleSelect={() => {}}
            selectedQuote={() => {}}
          />
          <div
            onClick={handleShowMore}
            className="w-full cursor-pointer text-[14px] mt-4 flex items-center gap-x-2 justify-end"
          >
            <span className="select-none">
              {isShowMoreActive ? "Less" : "More"}
            </span>
            <IoIosArrowDown
              className={`text-2xl ${
                isShowMoreActive && "rotate-180"
              } transition-all duration-200 ease-in-out`}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default LimitProviderList;

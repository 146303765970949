import { Currency, ETHER, Token } from "blazpay-sdk";
import React, { useMemo } from "react";
import styled from "styled-components";
import { WrappedTokenInfo } from "../../state/lists/hooks";
import Logo from "../PLogo";
import CoinLogo from "../blazpay/CoinLogo";
import { useTokenLogoURI } from "../../hooks/Tokens";
import { zeroAddress } from "viem";

// const getTokenLogoURL = (address: string) =>
//   `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${address}/logo.png`

const StyledBnbLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`;

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;

export default function CurrencyLogo({
  currency,
  size = "24px",
  style,
}: {
  currency?: Currency;
  size?: string;
  style?: React.CSSProperties;
}) {
  // const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const tokenLogo = useTokenLogoURI((currency as WrappedTokenInfo)?.address);
  const coinLogo = useTokenLogoURI(zeroAddress);
  const srcs: string[] = useMemo(() => {
    // if (currency === ETHER) return []

    // if (currency instanceof Token) {
    //   if (currency instanceof WrappedTokenInfo) {
    //     return [currency.logoURI as string]
    //   }

    //   return []
    // }
    // return []
    if (currency === ETHER) return [coinLogo as string];
    return [tokenLogo as string];
  }, [currency, tokenLogo, coinLogo]);

  return (currency as any)?.symbol ? (
    <CoinLogo
      size={size}
      srcs={srcs}
      alt={`${currency?.symbol ?? "token"} logo`}
      style={style}
    />
  ) : (
    <StyledLogo
      size={size}
      srcs={srcs}
      alt={`${currency?.symbol ?? "token"} logo`}
      style={style}
    />
  );
}

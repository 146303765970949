import React from "react";
import { IoIosArrowDown } from "react-icons/io";
const RecipientAddressWrapper = ({
  setIsActiveCheckbox,
  setRecipientAdd,
  setIsActiveRecipientAdd,
  recipientAdd,
  isActiveRecipientAdd,
  isActiveCheckbox,
}: {
  isActiveCheckbox: boolean;
  isActiveRecipientAdd: boolean;
  recipientAdd: string;
  setIsActiveRecipientAdd: any;
  setRecipientAdd: (x: string) => void;
  setIsActiveCheckbox: any;
}) => {
  return (
    <div className="w-full flex flex-col items-center mt-4 leading-[1.15]">
      <div className="flex justify-between px-2 w-full items-center">
        <h1
          className="cursor-pointer"
          onClick={() => setIsActiveRecipientAdd((x: boolean) => !x)}
        >
          Add Recipient Address
        </h1>
        <span className="cursor-pointer">
          {recipientAdd === "" ? (
            <>
              <IoIosArrowDown
                className={`2xl:text-2xl ${
                  isActiveRecipientAdd && "rotate-180"
                } transition-all duration-250 ease-linear`}
                onClick={() => setIsActiveRecipientAdd((x: boolean) => !x)}
              />
            </>
          ) : (
            <>
              <button
                onClick={() => setRecipientAdd("")}
                className="text-white text-[14px]"
              >
                Remove
              </button>
            </>
          )}
        </span>
      </div>
      <div
        className={`flex flex-col items-center w-full mt-3 ${
          !isActiveRecipientAdd && "hidden"
        }`}
      >
        <input
          type="text"
          value={recipientAdd}
          onChange={(e: any) => setRecipientAdd(e.target.value)}
          placeholder="Enter destination address"
          className="w-full bg-transparent rounded-full outline-none px-4 2xl:py-3 py-2 border border-white 2xl:text-[14px] text-xs placeholder:text-white/60"
        />
        <div className={`flex items-start gap-x-2 2xl:mt-4 mt-2 swap`}>
          <input
            type="checkbox"
            onClick={() => setIsActiveCheckbox((x: boolean) => !x)}
            className={`${isActiveCheckbox && "ckbox"} relative top-[2px]`}
          />
          <p className="text-xs text-white/60">
            Please ensure that the address is correct and not an exchagne
            wallet. any tokens sent ot the wrong address will be imposiible to
            retrieve.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RecipientAddressWrapper;

import React from "react";
import { IoClose } from "react-icons/io5";

interface AlchemyModalProps {
  isActive: boolean;
  className?: string;
  url: string;
  handleClose: (x: boolean) => void;
}
const BuySellModal = ({
  isActive,
  className,
  url,
  handleClose,
}: AlchemyModalProps) => {
  return (
    <>
      {isActive ? (
        <div
          style={{ backdropFilter: "blur(2px)" }}
          className={`${className} w-full min-h-screen bg-black/50 flex justify-center`}
        >
          <div className="h-full z-50 xs:w-fit w-full xs:px-0 px-4 relative">
            <div
              onClick={() => handleClose(false)}
              className="absolute -right-2 -top-3 cursor-pointer text-white text-2xl flex items-center justify-center h-10 w-10 rounded-full bg-[#0059da] z-[1000]"
            >
              <IoClose />
            </div>
            <iframe
              src={url}
              height="625"
              className="block xs:max-h-[625px] max-h-[560px] max-w-[500px] xs:w-[440px] w-full rounded-3xl"
              title="AlchemyPay On/Off Ramp Widget"
              allowTransparency={true}
            ></iframe>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default BuySellModal;

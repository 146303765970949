import React from "react";
interface FirstProviderProps {
  className?: string;
  handleSelect: (x: any) => void;
  route: string;
  source: string;
  logoURI: string;
  amount: number;
  tokenSymbol: string;
  usdPrice: number;
  index: number;
  isActive: boolean;
}
const LimitProviderListCard = ({
  className,
  handleSelect,
  route,
  source,
  logoURI,
  amount,
  tokenSymbol,
  usdPrice,
  index,
  isActive,
}: FirstProviderProps) => {
  return (
    <>
      {isActive ? (
        <div
          onClick={() => handleSelect(null)}
          className={`w-full border rounded-[30px] ${className}  py-4`}
        >
          <div className={`w-full justify-between flex border-b px-6 pb-3`}>
            <h1>
              {route}({source})
            </h1>
            <div className="flex items-center gap-x-1">
              <span>
                {amount} {tokenSymbol}
              </span>
              <img src={logoURI} className="h-5 w-5 rounded-full" alt="" />
            </div>
          </div>
          <div className="w-full flex px-5 py-3 justify-between items-center">
            <span>{usdPrice}~$</span>
            {index === 0 ? (
              <span className="bg-success px-2 rounded-md text-[14px]">
                Best
              </span>
            ) : (
              <span>{index + 1}</span>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default LimitProviderListCard;

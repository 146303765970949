import React, { useState, useEffect, useMemo } from "react";
import { IoClose } from "react-icons/io5";
import { RemoveModalWrapperSvg } from "./SvgContainer";

import { Currency, ChainId, ETHER } from "blazpay-sdk";
import { Field } from "../../state/burn/actions";
import CurrencyLogo from "../CurrencyLogo";
import ConfirmationLoader from "./ConfirmationLoader";
import TransactionModal from "./TransactionModal";
import { getBscScanLink } from "../../utils";
import { useWallet } from "../../hooks/useWallet";
import { FaArrowUp } from "react-icons/fa6";

import { NATIVE_SYMBOLS } from "../../constants";

const Modal = ({
  onDismiss,
  hash,
  attemptingTxn,
  pendingText,
  className,
  currencyA,
  currencyB,
  pair,
  parsedAmounts,
  disabled,
  onRemove,
  priceA,
  priceB
}: {
  onDismiss: () => void
  hash: string | undefined
  attemptingTxn: boolean
  pendingText: string
  className?: string
  currencyA?: Currency
  currencyB?: Currency
  pair: any
  parsedAmounts: any
  disabled: boolean
  onRemove: () => void
  priceA: string
  priceB: string
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(true)

  const handleConfirm = () => {
    onRemove();
    setIsLoading(true);
  }
  
  useEffect(() => {
    if(attemptingTxn && hash) {
      setIsSubmitted(true);
    }
  }, [hash])

  const { chainId } = useWallet();
  const loadedChainId: ChainId = useMemo(() => chainId as ChainId, [chainId])

  return (
    <div
      style={{ backdropFilter: "blur(10px)" }}
      className={`${className} xs:w-[480px] w-full h-[580] rounded-3xl flex items-center bg-[rgba(0,0,0,0.12)] justify-center z-[1000] `}
    >
      <div className="flex flex-col xs:w-[480px] w-full h-[580px] py-7 px-6 rounded-3xl border border-primary z-20 ">
        <div className="flex justify-between items-center z-20">
          <h1 className=" xs:text-3xl text-2xl">You will receive</h1>
          <IoClose
            onClick={onDismiss}
            className="text-[#ff3503] text-3xl cursor-pointer"
          />
        </div>
        <div className="xs:h-48 h-40 relative w-full">
          <div className="flex flex-col gap-3 relative z-10">
            <div className="flex items-center gap-x-5 h-full w-full ml-5 xs:mt-16 mt-11">
              <h1 className="font-bold text-lg">{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</h1>
              <div className="flex flex-row gap-x-2">
                <CurrencyLogo currency={currencyA} size="24px" /> 
                {currencyA === ETHER ? NATIVE_SYMBOLS[loadedChainId] : currencyA?.symbol}
              </div>
            </div>
            <div className="flex items-center gap-x-5 h-full w-full ml-5 ">
              <h1 className="font-bold text-lg">{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</h1>
              <div className="flex flex-row gap-x-2">
                <CurrencyLogo currency={currencyB} size="24px" /> 
                {currencyB === ETHER ? NATIVE_SYMBOLS[loadedChainId] : currencyB?.symbol}
              </div>
            </div>
          </div>
          <RemoveModalWrapperSvg className="absolute top-0 left-0 h-full w-full z-0 xs:scale-y-100 scale-y-[1.60]" />
        </div>
        <div className="flex flex-col  w-full">
          <h1 className="font-bold xs:text-xl text-base">
            {currencyA === ETHER ? NATIVE_SYMBOLS[loadedChainId] : currencyA?.symbol}/{currencyB === ETHER ? NATIVE_SYMBOLS[loadedChainId] : currencyB?.symbol} Tokens
          </h1>
          <p className="text-[12px] mt-3">
            Output is estimated. if the price changes by more than 0.8% Your
            Transaction will revert.
          </p>
        </div>
        <div className="flex xs:text-base text-xs flex-col gap-y-[14px] mt-8">
          <div className="flex items-center  w-full justify-between">
            <p>{`LP ${currencyA === ETHER ? NATIVE_SYMBOLS[loadedChainId] : currencyA?.symbol}/${currencyB === ETHER ? NATIVE_SYMBOLS[loadedChainId] : currencyB?.symbol}`} Burned</p>
            <span>{parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}</span>
          </div>
          <div className="flex items-start  w-full justify-between">
            <p>Price</p>
            <div className="flex gap-y-4 flex-col items-start">
              <span>{ priceA }</span>
              <span>{ priceB }</span>
            </div>
          </div>
          <button
            disabled={disabled}
            onClick={handleConfirm}
            className="outline-none border-none bg-gradient-to-r from-primary to-secondary rounded-full text-base font-bold text-whilte py-3"
          >
            Confirm
          </button>
        </div>
      </div>

      {attemptingTxn ? (
        <ConfirmationLoader
        isLoading={isLoading}
        handleCancel={setIsLoading}
        title="Waiting for confirmation"
        message={pendingText}
        description="Confirm this transaction in your wallet"
      />
      ) : hash ? (
        // <TransactionSubmittedContent chainId={chainId} hash={hash} onDismiss={onDismiss} />
        <TransactionModal
          isActive={isSubmitted}
          title="Transaction Submitted"
          message="View on Explorer"
          btnValue="Close"
          handleIsActive={setIsSubmitted}
          link={getBscScanLink(loadedChainId, hash ?? '', 'transaction')}
          icon={
            <FaArrowUp
              className="text-5xl text-[#ff3503]"
              style={{ filter: "box-shadow(0 0 20px rgba(255,35,3,0.2))" }}
            />
          }
          handleBtn={() => {}}
        />
      ) : (
        null
      )}
    </div>
  );
};

interface ConfirmationModalProps {
  isActive: boolean
  onDismiss: () => void
  hash: string | undefined
  attemptingTxn: boolean
  pendingText: string
  className?: string
  currencyA?: Currency
  currencyB?: Currency
  pair: any
  parsedAmounts: any
  disabled: boolean
  onRemove: () => void
  priceA: string
  priceB: string
}

const RemoveLiquidityModal = ({
  isActive,
  onDismiss,
  hash,
  attemptingTxn,
  pendingText,
  className,
  currencyA,
  currencyB,
  pair,
  parsedAmounts,
  disabled,
  onRemove,
  priceA,
  priceB
}: ConfirmationModalProps) => {
  return (
    <>
      {isActive ? (
        <Modal onDismiss={onDismiss} hash={hash} attemptingTxn={attemptingTxn} pendingText={pendingText} className={className} currencyA={currencyA} currencyB={currencyB} pair={pair} parsedAmounts={parsedAmounts} disabled={disabled} onRemove={onRemove} priceA={priceA} priceB={priceB}  />
      ) : null}
    </>
  );
};

export default RemoveLiquidityModal;

import { MouseEvent, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";

interface ITokenInputProps {
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  showChain: boolean;
  className?: string;
  inputClassName?: string;

  onChange?: (e: any) => void;
  onClick: (e: MouseEvent) => void;
  data?: {
    name: string;
    logo: string;
    chainName: string;
    chainLogo: string;
  };
}

export default function TokenInput({
  placeholder,
  value,
  disabled = false,
  showChain,
  className,
  inputClassName,

  onChange,
  onClick,
  data,
}: ITokenInputProps) {
  const handleChange = (event: any) => {
    const newValue = event.target.value;
    if (newValue === "" || /^[0-9]*\.?[0-9]*$/.test(newValue)) {
      if (onChange) {
        onChange(event);
      }
    }
  };

  return (
    <div
      className={`${className} w-full border border-white rounded-3xl flex flex-row justify-between items-center pt-4 2xl:pb-11 pb-7  2xl:px-7 px-5`}
    >
      <div className="w-full">
        {data ? (
          <>
            <div className="flex w-full items-center gap-x-2">
              <div className="flex flex-row">
                {showChain && (
                  <>
                    <img
                      src={data.chainLogo}
                      alt="token"
                      className="2xl:h-7 h-5 2xl:w-7 border-2 w-5 rounded-full  z-0"
                    />
                  </>
                )}
                <div className="rounded-full 2xl:w-7 w-5 flex items-center justify-center z-50">
                  <img
                    src={data.logo}
                    alt="token"
                    className={`2xl:h-7 h-5 2xl:w-7 w-5 ${
                      showChain && "-ml-2"
                    } rounded-full z-50`}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                {showChain && (
                  <p className="text-xs text-[rgba(255,255,255,0.25)]">
                    {data.chainName}
                  </p>
                )}
                <h1 className="2xl:text-base text-[14px] -mt-1">{data.name}</h1>
              </div>
              <IoIosArrowDown
                onClick={onClick}
                className={`text-2xl cursor-pointer hover:opacity-50 active:scale-90 transition all duration-150 ease relative ${
                  showChain ? "top-1" : "top-0"
                }`}
              />
            </div>
          </>
        ) : (
          <>
            <div onClick={onClick} className="flex items-center gap-x-2">
              <span className="text-xl">Select</span>
              <IoIosArrowDown className="text-3xl cursor-pointer hover:opacity-50 active:scale-90 transition all duration-150 ease relative top-1" />
            </div>
          </>
        )}
      </div>

      <div>
        <input
          type="number"
          value={value}
          placeholder={"0.0"}
          disabled={disabled}
          onChange={handleChange}
          className="w-full bg-transparent text-end 2xl:text-2xl text-base border-none outline-none"
        />
      </div>
    </div>
  );
}

const ROOT_PATH = "/";

export const DashboardPath = {
  defi: {
    root: "/defi",
    swap: "/defi/swap",
    swapAi: "/defi/swpai",
    bridge: "/defi/bridge",
    swapHistory: "/defi/swap-history",
    bridgeHistory: "/defi/bridge-history",
    liquidity: "/defi/liquidity",
    liquidityHistory: "/defi/liquidity-history",
    perpetual: "/defi/perpetual",
    limit: "/defi/limit",
    buySell: "/defi/buy-sell",
    borrow: "/defi/borrow",
  },

  // defi: ROOT_PATH + '/defi',
  nft: "https://rewards.blazpay.com/",
  games: "https://rewards.blazpay.com/gaming-marketplace",
  leaderboard: {
    root: "/leaderboard",
    details: (id: string) => `/leaderboard/${id}`,
  },
  pools: ROOT_PATH + "/pools",
};

import { ethers } from 'ethers';
import { infuraWallet } from '../utils/minterest';
import usdcAbi from '../utils/abi/usdcAbi.json';

const mUsdcAddress = '0xDD024638EfD132B342dEDee3B4F928893A88b7Ff';

export default function LendBorrow() {
  const makeLend = async (value: number) => {
    console.log(
      `Calling Lend with value ${value} in contract at address: ${mUsdcAddress}`
    );
    const mUsdc = new ethers.Contract(mUsdcAddress, usdcAbi, infuraWallet);
    const createReceipt = await mUsdc.lend(value);
    await createReceipt.wait();
    console.log(`Tx successful with hash: ${createReceipt.hash}`);
  };

  const makeBorrow = async (value: number) => {
    try {
      console.log(
        `Calling borrow with value ${value} in contract at address: ${mUsdcAddress}`
      );
      const mUsdc = new ethers.Contract(mUsdcAddress, usdcAbi, infuraWallet);

      const createReceipt = await mUsdc.lend(value);
      await createReceipt.wait();
      console.log(`Tx successful with hash: ${createReceipt.hash}`);
    } catch (error) {
      console.log('log::::: error ', error);
    }
  };

  return (
    <div className="container pt-32">
      <button className="px-3 py-1 rounded-full" onClick={() => makeLend(1)}>
        Lend
      </button>
      <button className="px-3 py-1 rounded-full" onClick={() => makeBorrow(1)}>
        {' '}
        Borrow
      </button>
    </div>
  );
}

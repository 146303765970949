import React, { useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";

const ProviderListCard = ({
  route,
  source,
  amount,
  tokenSymbol,
  usdPrice,
  logoURI,
  handleShowMore,
  isShowMoreActive,
  isActive,
  setShowMoreState,
}: {
  route: string;
  source: string;
  amount: number;
  tokenSymbol: string;
  usdPrice: any;
  logoURI: string;
  handleShowMore: any;
  isShowMoreActive: boolean;
  isActive: boolean;
  setShowMoreState: (x: boolean) => void;
}) => {
  useEffect(() => {
    setShowMoreState(isShowMoreActive);
  }, [isShowMoreActive]);
  return (
    <div className="w-full  2xl:py-1 py-0.5 flex flex-col ">
      <div className="flex w-full justify-between items-center">
        {!isShowMoreActive && (
          <>
            <div className="flex 2xl:text-base text-[14px] items-center gap-x-6">
              <h1>
                {route}({source})
              </h1>
              <div className="flex items-center gap-x-1">
                <h2>
                  {amount} {tokenSymbol}
                </h2>
                <img src={logoURI} className="h-5 w-5 rounded-full" />
              </div>
            </div>

            <span className="2xl:text-[14px] text-xs  px-2 w-fit rounded-md bg-success">
              Best
            </span>
          </>
        )}
      </div>
      <div className="flex justify-between w-full 2xl:gap-y-1 items-end">
        {!isShowMoreActive ? (
          <h1 className="2xl:text-base text-[14px]">{usdPrice}~$</h1>
        ) : (
          <h1 className="mt-4">Select quote</h1>
        )}
        <div
          onClick={() => {
            handleShowMore((x: boolean) => !x);
          }}
          className="flex gap-x-1 hover:opacity-75 cursor-pointer items-center"
        >
          <span className="text-xs">
            {isShowMoreActive ? "Show Less" : "Show More"}
          </span>
          <IoIosArrowDown
            className={`text-xl relative top-[2px] ${
              isShowMoreActive && "rotate-180"
            } transition-all duration-250 ease-in-out`}
          />
        </div>
      </div>
    </div>
  );
};

export default ProviderListCard;

import React, { createContext, useState, useContext } from "react";
import { useTronWallet } from "./TronWalletContext";
import { useSolanaWallet } from "./SolanaWalletContext";
import { useWallet } from "../hooks/useWallet";
import { useWeb3Modal, useDisconnect } from "@web3modal/ethers5/react";

export const ChainContext = createContext<any>(null);

export const ChainProvider = ({ children }: any) => {
  const [chainId, setChainId] = useState(null);
  const {
    wallet,
    connected,
    select,
    connect,
    disconnect: tronDisconnect,
  } = useTronWallet();
  const {
    connected: isSolanaConnected,
    connect: solanaConnect,
    disconnect: solanaDisconnect,
  } = useSolanaWallet();
  const { isConnected } = useWallet();
  const { disconnect } = useDisconnect();
  const { open: openWallet } = useWeb3Modal();
  const connectTronLink = async () => {
    try {
      console.log("Connecting to TronLink");
      if (!wallet) {
        await select("TronLink");
      }
      if (!connected) {
        await connect();
      }
      if (isConnected) {
        disconnect(); // web3modal disconnect
      }
      if (isSolanaConnected) {
        solanaDisconnect();
      }
    } catch (error) {
      console.log("Error connecting to TronLink:", error);
    }
  };

  const connectSolanaWallet = async () => {
    try {
      await solanaConnect();
      if (isConnected) {
        disconnect();
      }
      if (connected) {
        tronDisconnect();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const connectWeb3Modal = async () => {
    try {
      await openWallet({ view: "Connect" });
      if (isSolanaConnected) {
        solanaDisconnect();
      }
      if (connected) {
        tronDisconnect();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <ChainContext.Provider
      value={{
        chainId,
        setChainId,
        connectTronLink,
        connectSolanaWallet,
        connectWeb3Modal,
      }}
    >
      {children}
    </ChainContext.Provider>
  );
};

export const useChain = () => {
  const context = useContext(ChainContext);
  if (!context) {
    throw new Error("useChain must be used within a ChainProvider");
  }
  return context;
};

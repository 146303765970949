import { Link } from "react-router-dom";
import MainNav from "../../layouts/Navbars/MainNav";
import "./style.css";

export default function DefiLandingPage() {
  return (
    <div className="leading-6 flex flex-col ">
      <section className="relative flex flex-col font-sans   leading-10  ">
        <img
          src="/assets/bg-img.png"
          className="-z-40 opacity-40 absolute top-0 left-0"
        />
        <img
          src="/assets/bg-img1.png"
          className="hidden absolute h-dvh md:flex md:justify-center md:items-center -z-30   object-fill "
        />
        <img
          src="/assets/coin1.png"
          className="hidden md:block absolute z-10 object-contain top-72 right-48"
        />
        <img
          src="/assets/coin2.png"
          className=" hidden md:block absolute z-10  object-contain top-72 left-44"
        />
        <img
          src="/assets/coin3.png"
          className="hidden md:block absolute z-10 full object-contain bottom-16 left-20"
        />
        <div className="flex flex-col flex-wrap justify-center items-center h-dvh gap-1 z-30">
          <div className="text-7xl font-bold text-center leading-normal max-w-[800px] p-4 ">
            Trade Crypto &amp; NFT’s With Confidence
          </div>
          <div className=" text-xl pp-4 text-wrap px-4">
            Buy,Sell, Trade, manage and get a chance to earn
          </div>
          <div className="flex items-center justify-center mt-10 gap-12">
            <button className=" font-bold text-center">Learn more</button>
            <Link to="/defi/swap">
              <button className="border-b rounded-b-lg border-b-primary w-48 ">
                Launch
              </button>
            </Link>
          </div>
        </div>
      </section>

      <section className="font-['Kodchasan'] h-auto mt-8 mb-32 relative">
        <img
          src="/assets/bg-img2.png"
          className="absolute right-0 top-1/2 transform -translate-y-1/2 object-cover -z-10 "
          alt=""
        />
        <div className="flex flex-col flex-wrap gap-2   md:grid  md:grid-cols-3  justify-center items-center mx-24">
          <div className="flex flex-col gap-4 h-auto md:min-h-[31.25rem] ">
            <div className="flex flex-col border-gradient p-2 mx-4 h-auto md:min-h-[15.625rem] justify-around px-10 mb-1 ">
              <div className="font-bold text-3xl">Trade NFTs</div>
              <div>
                buy and sell NFTs across marketplaces to find more listings at
                better prices.
              </div>
              <div className="pb-4">Explore NFTs</div>
            </div>
            <div className="flex flex-col  border-gradient py-4 mx-4 h-auto md:min-h-[15.625rem] justify-around px-10 mt-1 ">
              <div className="font-bold text-3xl">EARN</div>
              <div>
                provide liquidity to pools on uniswap and earn fees on swaps.
              </div>
              <div>Earn Now</div>
            </div>
          </div>

          <div className="flex flex-col   border-gradient justify-around  px-10 h-auto md:min-h-[32.3rem] ">
            <div className="pt-8 font-bold text-3xl">Swap token</div>
            <div>
              Buy, sell, and explore tokens on ethereum polygon, optimism, and
              more
            </div>
            <div className="pb-4">Trade Tokens</div>
          </div>

          <div className="flex flex-col gap-4 h-auto md:min-h-[31.25rem]">
            <div className="flex flex-col flex-wrap border-gradient shadow-lg  p-2 mx-4  h-auto md:min-h-[15.625rem] justify-around  px-10 mb-1 ">
              <div className="font-bold text-3xl ">BUY CRYTPO</div>
              <div>
                Buy crypto with your credit card or bank account at the best
                rates.
              </div>
              <div className="pb-4">Buy now</div>
            </div>
            <div className="flex flex-col flex-wrap border-gradient p-2 mx-4 h-auto md:min-h-[15.625rem] justify-around  px-10 mt-1 ">
              <div className="font-bold text-3xl">Payment Transfer</div>
              <div>
                Buils apps and tools on the largest Defi protocol on ethereum
              </div>
              <div className="pb-4">Developer docs</div>
            </div>
          </div>
        </div>
      </section>

      <section className=" flex flex-col font-['Kodchasan'] justify-center items-center  ">
        <div className="container w-full mx-auto flex lg:flex-row flex-col lg:items-start items-center justify-around lg:px-10 font-bold gap-2 mb-20 text-xl py-0 px-10 uppercase lg:relative">
          <div className="flex flex-col  leading-6 gap-4 ">
            <div className="text-3xl text-prText leading-9 py-7 px-0">
              Ecosystem
            </div>
            <Link to="https://swap.blazpay.com/">Trade</Link>
            <Link to="https://taiko.blazpay.com/">Earn</Link>
            <Link to="https://blazpay.com/comingsoon">Game</Link>
            <Link to="https://blazpay.com/comingsoon">Nft</Link>
            <Link to="https://blazpay.com/tokenomics">Tokenomics</Link>
            <Link to="">Litepaper</Link>
            <Link to="">Merchandice</Link>
          </div>
          <div className="flex flex-col leading-6 gap-4 ">
            <div className="text-3xl text-prText leading-9 py-7 px-0 ">
              About
            </div>
            <Link to="https://blazpay.com/tokenomics">Terms of service</Link>
            <Link to="https://medium.com/@blazpay">Blog</Link>
            <Link to="https://medium.com/@blazpay">Brand Assets</Link>
            <Link to="https://medium.com/@blazpay">Careers</Link>
          </div>
          <div className="flex flex-col leading-6 gap-4">
            <div className="text-3xl text-prText  leading-9 py-7 px-0">
              Developers
            </div>
            <Link to="https://blazpay.com/comingsoon">Contributing</Link>
            <Link to="https://github.com/blazpay">Github</Link>
            <Link to="https://blazpay.com/comingsoon">Bug Bounty</Link>
          </div>
          <div className="flex flex-col leading-6 gap-4">
            <div className="text-3xl text-prText leading-9 py-7 px-0">
              Contact
            </div>
            <Link to="https://blazpay.com/comingsoon">Troubleshooting</Link>
            <Link to="">Documentation</Link>
          </div>
          <div className="lg:absolute lg:bottom-0 lg:right-20">
            <img src="/assets/logo.png" />
            <div className="flex justify-between items-center gap-10">
              <Link to="https://twitter.com/blazpaylabs">
                <img src="/assets/icon1.png" />
              </Link>
              <Link to="https://www.linkedin.com/company/blazpay">
                <img src="/assets/icon2.png" />
              </Link>
              <Link to="https://discord.com/invite/BlazPay">
                <img src="/assets/icon3.png" />
              </Link>
              <Link to="https://t.me/Blazpay">
                <img src="/assets/icon4.png" />
              </Link>
              <Link to="https://instagram.com/Blazpay">
                <img src="/assets/icon5.png" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

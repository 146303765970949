import { LuHistory } from "react-icons/lu";
import { IoIosSettings } from "react-icons/io";
import Settings from "../../component/liquidity/Settings";
import { IoReload } from "react-icons/io5";
import BalanceWrapper from "../../component/defi/common/BalanceWrapper";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import QuoteWrapper from "../../component/defi/common/QuoteWrapper";
import LoadingButton from "../../component/LoadingButton";
import TokenInput from "../../component/defi/TokenInput";
import { useState, useMemo, useEffect, useRef } from "react";
import { useWallet } from "../../hooks/useWallet";
import { useQuery } from "@tanstack/react-query";
import { getChains } from "../../apis/chain.api";
import { TradeManager } from "../../utils/trade";
import { sort } from "./swap";
import { capitalize } from "../../utils/case";
import SwapGraph from "../../component/defi/swap/SwapGraph";
import { getCoinGeckoId } from "../../apis/defi.api";
import LimitProviderList from "../../component/defi/limit/LimitProviderList";
import LimitOrders from "../../component/defi/limit/LimitOrders";
import TokenModal from "../../component/defi/TokenModal";
import { toast } from "react-toastify";

// test data for quotes
const data = [
  {
    source: "Nitro",
    route: "Nitro",
    networkFee: 0,
    priceImage: 0.08,
    usdAmount: 0,
    amount: 3.7473,
  },
  {
    source: "Nitro",
    route: "Nitro",
    networkFee: 0,
    priceImage: 0.08,
    usdAmount: 0,
    amount: 3.7473,
  },
  {
    source: "Nitro",
    route: "Nitro",
    networkFee: 0,
    priceImage: 0.08,
    usdAmount: 0,
    amount: 3.7473,
  },
  {
    source: "Nitro",
    route: "Nitro",
    networkFee: 0,
    priceImage: 0.08,
    usdAmount: 0,
    amount: 3.7473,
  },
  {
    source: "Nitro",
    route: "Nitro",
    networkFee: 0,
    priceImage: 0.08,
    usdAmount: 0,
    amount: 3.7473,
  },
  {
    source: "Nitro",
    route: "Nitro",
    networkFee: 0,
    priceImage: 0.08,
    usdAmount: 0,
    amount: 3.7473,
  },
  {
    source: "Nitro",
    route: "Nitro",
    networkFee: 0,
    priceImage: 0.08,
    usdAmount: 0,
    amount: 3.7473,
  },
  {
    source: "Nitro",
    route: "Nitro",
    networkFee: 0,
    priceImage: 0.08,
    usdAmount: 0,
    amount: 3.7473,
  },
];

export default function Limit() {
  const [open, setOpen] = useState<string>("");
  const [showMore, setShowMore] = useState<boolean>(false);
  const [openProviders, setOpenProviders] = useState(false);
  const [coinGeckoToken, setCoinGeckoToken] = useState<any>(null);
  const [amount, setAmount] = useState("");
  const [fromToken, setFromToken] = useState<any>(null);
  const [toToken, setToToken] = useState<any>(null);
  const [quotes, setQuotes] = useState<any>([]);
  const [selectedQuote, setSelectedQuote] = useState<any>();
  const [isQuoteLoading, setIsQuoteLoading] = useState(false);
  const [isSwapLoading, setIsSwapLoading] = useState(false);
  const [fromBalance, setFromBalance] = useState<number>(0);
  const { address, chainId, switchChain, provider } = useWallet();
  const [isSettingsActive, setIsSettingsActive] = useState<boolean>(false);
  const [btnValue, setBtnValue] = useState<number>(0);
  const [recipientAdd, setRecipientAdd] = useState<string>("");
  const [currencyIdA, setCurrencyIdA] = useState<string>("");
  const [currencyIdB, setCurrencyIdB] = useState<string>("");
  const [foundBlazpayPair, setFoundBlazpayPair] = useState<boolean>(false);
  const [isCalculateActive, setIsCalculateActive] = useState<boolean>(false);
  const managerRef = useRef(new TradeManager());
  const manager = managerRef.current;
  const chainQuery = useQuery({
    queryKey: ["chains"],
    queryFn: getChains,
  });
  const handleClose = () => {
    setOpen("");
  };

  const handleQuoteSelect = (quote: any) => {
    setSelectedQuote(quote);
  };

  const getButtonState = () => {
    if (!address) return "connect_wallet";
    if (!fromToken || !toToken) return "select_tokens";
    if (!amount) return "enter_amount";
    if (isQuoteLoading) return "calculating";
    if (!selectedQuote) return "calculate";
    if (chainId !== fromToken.chain.id) return "change_network";
    if (isSwapLoading) return "loading";
    return "Limit";
  };
  const handleButtonClick = async () => {};
  const resetToToken = () => {
    setToToken(null);
  };
  useEffect(() => {
    if (fromToken?.name === toToken?.name) {
      setToToken(null);
    }
  }, [fromToken, toToken]);
  const calculateQuote = async () => {
    setQuotes([]);
    setFoundBlazpayPair(false);
    setIsCalculateActive(false);
    setSelectedQuote(null);
    setIsQuoteLoading(true);
    await manager.init(chainQuery.data);
    await manager
      .getSwapQuotes({
        fromChainId: fromToken.chain.id.toString(),
        toChainId: toToken.chain.id.toString(),
        fromTokenName: fromToken.name,
        toTokenName: toToken.name,
        fromTokenAddress: fromToken.address,
        toTokenAddress: toToken.address,
        amount,
        onNewQuote: (quote: any) => {
          setQuotes((prev: any) => {
            if (quote) {
              const newQuotes = [...prev, quote].sort(sort);
              setSelectedQuote(newQuotes[0]);
              return newQuotes;
            } else return [];
          });
        },
        onFirstQuote: () => {
          setIsQuoteLoading(false);
        },
        decimals: fromToken?.decimals,
        toTokenDecimals: toToken?.decimals,
        toTokenSymbol: toToken?.symbol,
        fromtokenSymbol: fromToken?.symbol,
        fromAddress: address,
        toAddress: Boolean(recipientAdd) ? recipientAdd : address,
      })
      .finally(() => {
        if (quotes.length === 0) {
          setIsQuoteLoading(false);
        }
      });
    manager.setWalletAddress(address!);
    setIsCalculateActive(true);
    console.log("completed setting quotes");
    setIsCalculateActive(true);
  };
  const buttonData: number[] = [25, 50, 75, 100];
  const handlePercentageClick = (percentage: number) => {
    if (btnValue === 100) {
      const calculateAmount = (fromBalance * 99) / 100;
      setAmount(calculateAmount.toFixed(6));
    } else {
      const calculatedAmount = (fromBalance * percentage) / 100;
      setAmount(calculatedAmount.toFixed(6));
    }
  };
  const handleFromTokenClick = () => setOpen("from");
  const handleToTokenClick = () => fromToken && setOpen("to");
  const handleAmountChange = (e: any) => {
    setAmount(e.target.value);
    setQuotes([]);
    setSelectedQuote(null);
    setIsQuoteLoading(false);
  };
  const handleTokenInterchange = () => {
    if (!fromToken && !toToken) {
      toast.error("Please select tokens!");
    }
    const from = fromToken;
    const to = toToken;

    setFromToken(to);
    setToToken(from);
    setSelectedQuote(null);
    setQuotes([]);
    setIsCalculateActive(false);
  };

  const handleTokenChange = (data: any) => {
    setIsQuoteLoading(false);
    setQuotes([]);
    setSelectedQuote(null);
    switch (open) {
      case "from": {
        setFromToken(data);
        setCurrencyIdA(data.address);
        break;
      }
      case "to": {
        setToToken(data);
        setCurrencyIdB(data.address);
        break;
      }
    }
  };
  const coinGeckoTokenQuery = useQuery({
    queryKey: [chainId, fromToken],
    queryFn: () => getCoinGeckoId(chainId ? chainId : 137),
  });
  useEffect(() => {
    if (!coinGeckoTokenQuery.isLoading && coinGeckoTokenQuery?.data) {
      const tokens = coinGeckoTokenQuery?.data;
      // const defaultTokenData = tokens?.filter(
      //   (token: any) =>
      //     token.address === "0x0000000000000000000000000000000000000000"
      // );
      const defaultTokenData = tokens?.filter(
        (token: any) => token?.coingeckoId
      );
      console.log(defaultTokenData[0], "defualt token...3232..");
      setCoinGeckoToken(defaultTokenData[0]);
    }
  }, [chainId, coinGeckoTokenQuery.data, coinGeckoTokenQuery.isLoading]);
  return (
    <section className="min-h-svh defi-gradient-container   md:pt-32 pt-44 w-full swap-container text-white flex justify-center">
      <div
        className={`2xl:container w-full lg:pb-5 pb-12  h-full flex lg:flex-row flex-col lg:items-start lg:gap-y-0 gap-y-8 items-center lg:gap-x-4 lg:justify-center pt-8 xl:px-16 lg:px-6 sm:px-12 px-4 mx-auto`}
      >
        {/* Limit fields */}
        <div
          style={{ backdropFilter: "blur(28px)" }}
          className={`sm:w-[500px] w-full h-full bg-[rgba(0,0,0,0.3)] lg:w-2/5 pt-6 ${
            quotes.length > 0 ? "pb-12" : "pb-52 pb-20"
          } rounded-3xl  border border-primary flex flex-col text-white xl:mt-0`}
        >
          <div className="px-8">
            <h2 className="xl:text-2xl text-xl">Limit</h2>
          </div>

          <div className="flex flex-row justify-between items-center w-full 2xl:mt-2 mt-1 text-[18px] px-8">
            <h1 className="2xl:text-[18px] text-base">
              Place a limit order to trade at a set price
            </h1>
            <div className="flex items-center gap-x-2">
              <LuHistory className="cursor-pointer hover:text-[rgba(255,255,255,0.50)] transition-all duration-250 ease-in-out text-2xl" />

              <div className="relative">
                <IoIosSettings
                  onClick={() => setIsSettingsActive((x: boolean) => !x)}
                  className="cursor-pointer  hover:text-[rgba(255,255,255,0.50)] transition-all duration-250 ease-in-out text-2xl"
                />
                <Settings
                  isActive={isSettingsActive}
                  closeModal={setIsSettingsActive}
                  className="absolute -right-2 -top-2"
                />
              </div>
              {selectedQuote && (
                <IoReload
                  onClick={calculateQuote}
                  className="cursor-pointer text-2xl hover:text-[rgba(255,255,255,0.24)]"
                />
              )}
            </div>
          </div>
          <div className="2xl:mt-8 mt-4 sm:px-8 px-4">
            <div className="relative">
              <TokenInput
                placeholder="Enter amount"
                showChain={true}
                inputClassName="text-white"
                data={
                  fromToken
                    ? {
                        name: fromToken.symbol,
                        logo: fromToken.logo,
                        chainName: fromToken.chain.name,
                        chainLogo: fromToken.chain.logoURI,
                      }
                    : undefined
                }
                value={amount.toString()}
                onClick={() => setOpen("from")}
                onChange={handleAmountChange}
              />
              {fromToken && (
                <>
                  <div className="bottom-3 right-7 absolute flex items-center gap-x-2">
                    {buttonData.map((item) => (
                      <button
                        key={item}
                        onClick={() => {
                          handlePercentageClick(item);
                          setBtnValue(item);
                        }}
                        className="px-3 py-1 border rounded-full 2xl:text-[10px] text-[8px] leading-[1.0]"
                      >
                        {item === 100 ? "MAX" : <>{item}%</>}
                      </button>
                    ))}
                  </div>
                </>
              )}
            </div>
            <BalanceWrapper fromToken={fromToken} fromBalance={fromBalance} />
            <div
              onClick={handleTokenInterchange}
              className="mx-auto mb-2 cursor-pointer 2xl:h-10 h-8 2xl:w-10 w-8 border border-white text-white 2xl:rounded-xl rounded-lg flex justify-center items-center text-xl"
            >
              <HiOutlineSwitchVertical className="2xl:text-2xl text-base" />
            </div>
            <div>
              <TokenInput
                showChain={false}
                disabled={true}
                value={selectedQuote?.amount || ""}
                data={
                  toToken
                    ? {
                        name: toToken.symbol,
                        logo: toToken.logo,
                        chainName: toToken.chain.name,
                        chainLogo: toToken.chain.logoURI,
                      }
                    : undefined
                }
                onClick={() => fromToken && setOpen("to")}
              />
            </div>
            <QuoteWrapper
              selectedQuote={selectedQuote}
              setOpenProviders={setOpenProviders}
            />

            {/* buttons */}
            <LoadingButton
              onClick={handleButtonClick}
              className="2xl:mt-8 mt-6 bg-gradient-to-r 2xl:text-base text-[14px] from-primary to-secondary text-white font-bold text-center w-full mx-auto block py-2 rounded-full"
            >
              {capitalize(getButtonState())}
            </LoadingButton>
            {quotes?.length != 0 && !isCalculateActive && (
              <button
                className="2xl:mt-6 mt-4 bg-gradient-to-r from-primary to-secondary text-white font-bold text-center w-full mx-auto block h-10 rounded-full"
                onClick={() => {
                  setIsCalculateActive(true);
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
        <div className="flex relative flex-col lg:gap-y-3 gap-y-8 lg:w-[calc(100%-200px)] sm:w-[500px] w-full h-full">
          <div className="flex flex-row justify-between gap-x-4 px-0 h-full w-full">
            <SwapGraph
              fromToken={coinGeckoToken}
              toToken={toToken}
              graphType="limit"
              className="w-full"
            />
            <LimitProviderList
              isQuoteLoading={true}
              isActive={true}
              quotes={data}
              handleClick={() => {}}
              selectQuote={handleQuoteSelect}
              tokenSymbol={toToken?.symbol}
              logoURI={toToken?.logo}
            />
          </div>
          <LimitOrders />
        </div>
      </div>
      <TokenModal
        open={open}
        chains={chainQuery.data}
        handleClose={handleClose}
        handleChange={handleTokenChange}
        swap={true}
        resetToToken={resetToToken}
        handleFromTokenClick={handleFromTokenClick}
        handleToTokenClick={handleToTokenClick}
      />
    </section>
  );
}

import {
  Navigate,
  Outlet,
  createBrowserRouter,
  useRoutes,
} from "react-router-dom";
import { RootErrorBoundary } from "./RootErrorBoundary";
import Defi from "../pages/defi";
import Swap from "../pages/defi/swap";
import Bridge from "../pages/defi/bridge";
import Buy from "../pages/defi/buy";
import MainLayout from "../layouts/MainLayout";
import Perpetual from "../pages/defi/perpetual";
import Limit from "../pages/defi/limit";
import History from "../pages/defi/history";
import DefiLandingPage from "../pages/defiLandingPage";
import Transactiondetails from "../pages/defi/transactiondetails";
import LendBorrow from "../pages/LendBorrow";
import YourLiquidity from "../component/liquidity/YourLiquidity";
import AddLiquidity from "../component/liquidity/AddLiquidity";
import RemoveLiquidity from "../component/liquidity/RemoveLiquidity";
import ConfirmOrder from "../component/liquidity/ConfirmOrder";
import Add from "../component/liquidity/Add";
import Loader from "../component/loader";
import SwapAI from "../pages/defi/swap-ai";
import MainContainer from "../component/defi/swapAI/MainContainer";

export default function Routes() {
  return useRoutes([
    {
      path: "/*",
      element: <MainLayout />,
      errorElement: <RootErrorBoundary />,
      children: [
        { path: "", element: <DefiLandingPage /> },

        {
          path: "defi",
          element: (
            <>
              <Defi />
              <Outlet />
            </>
          ),
          children: [
            {
              index: true,
              element: <Navigate to={"/defi/swap"} replace />,
            },
            {
              path: "swap",
              element: <Swap />,
            },
            {
              path: "swpai",
              element: <MainContainer />,
              // element: <SwapAI />
              
            },
            {
              path: "bridge",
              element: <Bridge />,
            },

            {
              path: "liquidity",
              element: <YourLiquidity />,
            },
            {
              path: "liquidity/addliquidity/:currencyIdA?/:currencyIdB?",
              element: <AddLiquidity />,
            },
            {
              path: "liquidity/addliquidity/confirm",
              element: <ConfirmOrder />,
            },
            {
              path: "liquidity/remove/:currencyIdA/:currencyIdB",
              element: <RemoveLiquidity />,
            },
            {
              path: "liquidity/add",
              element: <Add />,
            },
            {
              path: "perpetual",
              element: <Perpetual />,
            },
            {
              path: "bridge",
              element: <Bridge />,
            },
            {
              path: "limit",
              element: <Limit />,
            },
            {
              path: "buy-sell",
              element: <Buy />,
            },
            {
              path: "swap-history",
              element: <History module="swap" />,
            },
            {
              path: "bridge-history",
              element: <History module="bridge" />,
            },
            {
              path: "buy-history",
              element: <History module="buy" />,
            },
            {
              path: "liquidity-history",
              element: <History module="liquidity" />,
            },
            {
              path: "history/:id",
              element: <Transactiondetails />,
            },
            {
              path: "borrow",
              element: <LendBorrow />,
            },
          ],
        },
      ],
    },
  ]);
}

import { IoClose } from "react-icons/io5";
import CustomModal from "../CustomModal";
import clsx from "clsx";
import { useEffect, useMemo, useState, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getTokens, getTokenBalance } from "../../apis/chain.api";
import { BsThreeDotsVertical } from "react-icons/bs";
import Loader from "../loader";
import { useWallet } from "../../hooks/useWallet";
import LoadingButton from "../LoadingButton";
import { useTronWallet } from "../../contexts/TronWalletContext";
import { useDisconnect, useWeb3Modal } from "@web3modal/ethers5/react";
import { useSolanaWallet } from "../../contexts/SolanaWalletContext";
import { ChainContext } from "../../contexts/ChainContext";
interface ITokenModalProps {
  chains: any[];
  allowedChainIds?: number[];
  open: string;
  handleClose: () => void;
  handleChange: (data: any) => any;
  swap: boolean;
  resetToToken: () => void;
  bridge?: boolean;
  handleFromTokenClick?: any;
  handleToTokenClick?: any;
}

export default function TokenModal({
  open,
  chains,
  allowedChainIds,
  handleClose,
  handleChange,
  swap,
  resetToToken,
  bridge = false,
  handleToTokenClick,
  handleFromTokenClick,
}: ITokenModalProps) {
  // const { chainId, switchChain } = useWallet();
  const { chainId, switchChain, isConnected, address } = useWallet();
  const [selectedChain, setSelectedChain] = useState(chainId);
  const [selectedNetwork, setSelectedNetwork] = useState();
  const [searchText, setSearchText] = useState("");
  const {
    connected,
    select,
    connect,
    wallet,
    address: tronAddress,
  } = useTronWallet();
  const {
    connected: isSolanaConnected,
    connect: solanaConnect,
    address: solanaAddress,
  } = useSolanaWallet();
  const { disconnect } = useDisconnect();
  const { setChainId } = useContext(ChainContext);

  const tokenQuery = useQuery({
    queryKey: ["tokens", selectedChain],
    queryFn: () => getTokens(selectedChain),
    enabled: !!selectedChain,
  });

  const tokenBalanceQuery: any = useQuery({
    queryKey: [
      "tokenbalance",
      address,
      tronAddress,
      solanaAddress,
      selectedChain,
    ],
    queryFn: () => {
      if (selectedChain === 728126428) {
        return getTokenBalance(tronAddress, selectedChain);
      } else if (selectedChain === 102) {
        return getTokenBalance(solanaAddress, selectedChain);
      } else {
        return getTokenBalance(address, selectedChain);
      }
    },
    enabled:
      !!selectedChain &&
      ((selectedChain === 728126428 && !!tronAddress) ||
        (selectedChain === 102 && !!solanaAddress) ||
        (selectedChain !== 728126428 && selectedChain !== 102 && !!address)),
  });

  const filteredChains = useMemo(() => {
    return allowedChainIds?.length
      ? chains.filter((chain) => allowedChainIds.includes(chain.id))
      : chains;
  }, [chains, allowedChainIds]);

  const filteredTokens = useMemo(() => {
    if (!tokenBalanceQuery?.data || tokenBalanceQuery?.isLoading) {
      return (
        tokenQuery?.data
          ?.filter((item: any) =>
            searchText
              ? item.name.toLowerCase().includes(searchText.toLowerCase())
              : true
          )
          .slice(0, 200) || []
      );
    }

    const balanceMap = new Map(
      tokenBalanceQuery?.data?.data?.tokens.map((token: any) => [
        token?.symbol.toLowerCase(),
        token,
      ])
    );

    return tokenQuery?.data
      ?.map((token: any) => {
        const balanceInfo: any = balanceMap.get(token.symbol.toLowerCase());
        return {
          ...token,
          balance: balanceInfo?.balance_formatted || "0",
          usdBalance: balanceInfo?.usd_value || "0",
        };
      })
      .filter((item: any) =>
        searchText
          ? item.name.toLowerCase().includes(searchText.toLowerCase())
          : true
      )
      .sort((a: any, b: any) => parseFloat(b?.balance) - parseFloat(a?.balance))
      .slice(0, 200);
  }, [searchText, tokenQuery.data, tokenBalanceQuery.data, selectedChain]);

  const onTokenChange = (token: any) => {
    console.log(token, chains, selectedChain, "all params");
    const chain = chains.find((chain) => chain.id === selectedChain);
    handleChange({
      name: token.name,
      symbol: token.symbol,
      address: token.address,
      logo: token.logoURI,
      chain,
      usdPrice: token.priceUSD,
      decimals: token?.decimals,
      changellySymbol: token?.changellySymbol,
      balance: token?.balance,
      usdBalance: token?.usdBalance,
    });
    handleClose();
  };
  useEffect(() => {
    setSearchText("");
  }, [handleFromTokenClick, handleToTokenClick]);
  return (
    <CustomModal open={!!open} onClose={handleClose}>
      <div className="relative text-white px-6 py-2 max-sm:px-2">
        <IoClose
          className="text-2xl cursor-pointer absolute right-5"
          onClick={handleClose}
        />
        <div className="">
          <h4 className="text-center font-semibold text-2xl">Token List</h4>

          <input
            value={searchText}
            placeholder="Search name of token"
            className="px-8 max-sm:px-4 max-sm:py-1 py-2 text-base border bg-transparent outline-none border-primary placeholder:text-white rounded-full w-full my-5"
            onChange={(e) => setSearchText(e.target.value)}
          ></input>
        </div>
        <div className="flex gap-3 max-sm:flex-col lg:h-full">
          <div
            className={`grid grid-cols-2 max-sm:grid-cols-4 max-sm:w-full
          scrollbar-thin scrollbar-thumb-scroll scrollbar-thumb-rounded-full
          chain-list w-[250px] p-2  gap-4 justify-center flex-wrap max-h-96 max-sm:max-h-20 overflow-auto ${
            (open === "to" || open === "second") && swap ? "opacity-50" : ""
          }`}
          >
            {filteredChains?.map((chain) => {
              return (
                <div
                  key={chain.id}
                  onClick={() => {
                    if (open === "to" && swap) {
                      return;
                    }
                    setSelectedChain(chain.id);
                    setChainId(chain.id);
                    setSelectedNetwork(chain.metamask);
                  }}
                  className={clsx(
                    "flex flex-col justify-center items-center p-2 rounded-xl cursor-pointer",
                    selectedChain === chain.id
                      ? "bg-[rgba(57,18,0,.72)]"
                      : "bg-[#130600]"
                  )}
                >
                  <img
                    src={chain.logoURI}
                    className="h-7 w-7 rounded-full"
                    alt={chain.name}
                  />
                  <p className="text-xs text-center">{chain.name}</p>
                </div>
              );
            })}
          </div>

          <p className="hidden max-sm:block">Tokens</p>
          <div
            className={`min-w-[320px] max-sm:w-full px-3 py-4 overflow-y-auto  overflow-x-hidden rounded-2xl bg-[#130600] flex-1 max-h-96 max-sm:max-h-60  scrollbar-thin scrollbar-thumb-scroll scrollbar-thumb-rounded-full ${
              !bridge &&
              selectedChain &&
              chainId !== selectedChain &&
              "flex items-center justify-center"
            }`}
          >
            {/* {((address && tokenBalanceQuery.isLoading) ||
              tokenQuery.isLoading) && (
              
            )} */}
            {!bridge && selectedChain && chainId !== selectedChain ? (
              <LoadingButton
                onClick={() => switchChain(selectedNetwork)}
                className="bg-gradient-to-r from-primary to-secondary text-white font-bold text-center w-full mx-auto h-12 rounded-full"
              >
                Switch Chain
              </LoadingButton>
            ) : (
              filteredTokens?.map((token: any) =>
                (address && tokenBalanceQuery.isLoading) ||
                tokenQuery?.isLoading ? (
                  <div className="flex items-center justify-center w-full min-h-80">
                    <Loader />
                  </div>
                ) : (
                  <div
                    key={token.address}
                    onClick={() => {
                      onTokenChange(token);
                    }}
                    className="border-b border-b-borderGray px-3 py-2  lg:min-w-[340px]  flex justify-between cursor-pointer"
                  >
                    <div className=" flex gap-3 items-center">
                      <img
                        src={token.logoURI}
                        className="w-8 h-8 rounded-full"
                        alt="nb"
                      />
                      <div>
                        <p className="text-sm">{token.symbol}</p>
                        <p className="text-xs max-w-[100px]">{token.name}</p>
                      </div>
                    </div>
                    <div className="flex gap-3 items-center">
                      {token?.balance !== "0" &&
                        !isNaN(parseFloat(token.balance)) && (
                          <div>
                            <p className="text-sm">
                              {parseFloat(token.balance).toFixed(5)}
                            </p>
                            {!isNaN(parseFloat(token.usdBalance)) && (
                              <p className="text-xs max-w-[100px]">
                                ${parseFloat(token.usdBalance).toFixed(2)}
                              </p>
                            )}
                          </div>
                        )}
                      <BsThreeDotsVertical />
                    </div>
                  </div>
                )
              )
            )}
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

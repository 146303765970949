import { useEffect, useRef, useState } from "react";
import { IoIosArrowDropleft } from "react-icons/io";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BsCopy } from "react-icons/bs";
import { BsExclamationCircle } from "react-icons/bs";
import { fetchHistory } from "../../apis/defi.api";
import { useQuery } from "@tanstack/react-query";
import { useWallet } from "../../hooks/useWallet";
import { IoIosArrowBack } from "react-icons/io";
const Transactiondetails = ({}) => {
  const { address } = useWallet();
  const navigate = useNavigate();

  let { id } = useParams();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["history", address, id, 1],
    queryFn: fetchHistory,
    enabled: !!address,
  });

  return (
    <section className="min-h-svh relative w-full lg:pt-0 pt-52 text-white flex flex-col overflow-x-hidden  justify-center">
      <div className="flex lg:justify-center lg:px-0 sm:px-20 px-8 justify-start lg:block">
        <span className="flex lg:left-36 lg:relative text-3xl lg:pt-1 items-center gap-3">
          <button onClick={() => navigate(-1)}>
            <IoIosArrowBack className="cursor-pointer hover:text-[rgba(255,255,255,0.5)] transition-all duration-250 ease-in-out" />
          </button>
          <span> Order Details</span>
        </span>
      </div>
      <div className="w-full flex justify-center md:gap-x-0 md:px-0 lg:mt-12 mt-4 px-6 gap-x-4 items-center pt-16">
        <>
          <div className="flex flex-col gap-1 justify-center items-center">
            <p className="sm:text-xl text-base">
              {data?.data[0].txFrom?.tokenName}
            </p>
            <p className="md:text-6xl sm:text-3xl text-xl">
              {data?.data[0].txFrom?.value}
            </p>
          </div>
          <FaArrowRightLong className="relative top-4 lg:w-[300px] lg:text-8xl sm:text-5xl text-3xl" />
          <div className="flex flex-col gap-1 justify-center items-center">
            <p className="sm:text-xl text-base">
              {data?.data[0].txTo?.tokenName}
            </p>
            <p className="md:text-6xl sm:text-3xl text-xl">
              {data?.data[0].txTo?.value}
            </p>
          </div>
        </>
      </div>
      <div className=" relative w-full  lg:gap-x-10 lg:gap-y-0 gap-y-8 md:px-28 px-5  text-white flex justify-center lg:flex-row items-center lg:items-stretch flex-col pt-16">
        <div className="sm:w-[500px] w-full py-6 rounded-xl border border-white flex flex-col text-white xl:mt-0">
          <div className="flex flex-row w-full items-center justify-between border-b border-borderGray gap-3 mb-5">
            <div className="ml-5 text-xl">Status</div>
            <div className="mr-5">Success</div>
          </div>
          <div className="flex flex-row w-full items-center justify-between border-b border-borderGray gap-3 mb-5">
            <div className="ml-5 text-xl">Order ID</div>
            <div className="mr-5 flex">
              {data?.data[0]._id.slice(0, 3)}...
              {data?.data[0]._id.slice(-3)}{" "}
              <BsCopy
                className="ml-2 cursor-pointer"
                color="#F85300"
                onClick={() => {
                  navigator.clipboard.writeText(data?.data[0]._id);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row w-full items-center gap-3 justify-between ">
            <div className="ml-5 text-xl">Order Time</div>
            <div className="mr-5 sm:whitespace-nowrap">
              {data?.data[0].createdAt.slice(0, 10)}
              {data?.data[0].createdAt.slice(11, 19)}
            </div>
          </div>
        </div>
        <div className="sm:w-[500px] w-full py-6 rounded-xl border border-white flex flex-col text-white xl:mt-0">
          <div className="flex flex-row w-full items-center justify-between border-b border-borderGray gap-3 mb-5">
            <div className="ml-5 text-xl">Receive Address</div>
            <div className="mr-5 flex">
              {data?.data[0].walletAddress.slice(0, 3)}...
              {data?.data[0].walletAddress.slice(-3)}{" "}
              <BsCopy
                className="ml-2 cursor-pointer"
                color="#F85300"
                onClick={() => {
                  navigator.clipboard.writeText(data?.data[0].walletAddress);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row w-full items-center justify-between border-b border-borderGray gap-3 mb-5">
            <div className="ml-5 text-xl">StatTXID of Transfer-out</div>
            <div className="mr-5 flex">
              {data?.data[0].txHash.slice(0, 3)}...
              {data?.data[0].txHash.slice(-3)}{" "}
              <BsCopy
                className="ml-2 cursor-pointer"
                color="#F85300"
                onClick={() => {
                  navigator.clipboard.writeText(data?.data[0].txHash);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row w-full items-center gap-3 justify-between ">
            <div className="ml-5 text-xl sm:whitespace-nowrap">
              StatTXID of Transfer-in
            </div>
            <div className="mr-5 flex">
              {data?.data[0].txHash.slice(0, 3)}...
              {data?.data[0].txHash.slice(-3)}{" "}
              <BsCopy
                className="ml-2 cursor-pointer"
                color="#F85300"
                onClick={() => {
                  navigator.clipboard.writeText(data?.data[0].txHash);
                }}
              />
            </div>
          </div>
        </div>
        <div className="sm:w-[500px] w-full py-6 rounded-xl border border-white flex flex-col text-white xl:mt-0">
          <div className="flex flex-row w-full items-center justify-between border-b border-borderGray gap-3 mb-5">
            <div className="ml-5 text-xl">Provider</div>
            <div className="mr-5">{data?.data[0].route}</div>
          </div>
          <div className="flex flex-row w-full items-center justify-between border-b border-borderGray gap-3 mb-5">
            <div className="ml-5 text-xl">Rate</div>
            <div className="mr-5 sm:whitespace-nowrap">
              1 {data?.data[0].txFrom?.tokenName} ={" "}
              {(
                Number(data?.data[0].txTo?.value) /
                Number(data?.data[0].txFrom?.value)
              ).toFixed(2)}
              {data?.data[0].txTo?.tokenName}
            </div>
          </div>
          <div className="flex flex-row w-full items-center gap-3 justify-between ">
            <div className="ml-5 text-xl flex gap-2 items-center">
              Fee <BsExclamationCircle />
            </div>
            <div className="mr-5 flex flex-col gap-2">
              <div className="">0.2%</div>
              <div>0.15 MATIC</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Transactiondetails;

import axiosInstance from "./axios";
import { Order } from "./interfaces.trade";

export const updateQueryString = (params: any) => {
  window.history.replaceState({}, '', `${window.location.pathname}${params}`);
};

export const calculateFee = (feeObject: any, searchParam = "Fee"): number => {
  const feeKeys = Object.keys(feeObject)
  const totalFee = feeKeys.reduce((acc = 0, key) => {
    if (!key.startsWith("raw") && key.endsWith(searchParam)) {
      const fee = feeObject[key];
      return acc + (Number(fee) || 0);
    }
    return acc;
  }, 0);
  return totalFee || 0
}

export function typecast<T extends object, U>(obj: T, keys: (keyof T)[]): U {
  const result = {} as U;
  keys.forEach(key => {
    if (key in obj) {
      (result as any)[key] = obj[key];
    }
  });
  return result;
}

export async function createOrder(orderParams: Order) {
  const { data: { data: order } } = await axiosInstance.post("/defi/order/create", orderParams)
  return order
}
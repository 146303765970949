import React, { useState, useMemo, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import {
  Currency,
  CurrencyAmount,
  Fraction,
  Percent,
  ChainId,
  ETHER,
} from "blazpay-sdk";
import { Field } from "../../state/mint/actions";
import { AnyMxRecord } from "dns";
import ConfirmationLoader from "./ConfirmationLoader";
import TransactionModal from "./TransactionModal";
import { FaArrowUp } from "react-icons/fa6";
import { getBscScanLink } from "../../utils";
import { useWallet } from "../../hooks/useWallet";
import { NATIVE_SYMBOLS } from "../../constants";

const SVGWrapper = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      width="421"
      height="93"
      viewBox="0 0 421 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110.49 0H4.93292L0 4.93123V33.4455L6.94033 40.3835V10.4716H94.8287L104.591 0.710719L110.49 0Z"
        fill="#FF5500"
      />
      <path
        d="M259.27 82.0518L249 92.3184H352.829L342.506 82L259.27 82.0518Z"
        fill="#FF5500"
      />
      <path
        d="M103.69 1L94.0815 10.6076H6V89.9733H242.806L254.817 77.9667H346.686L357.954 89.2307H419.083V1H103.69Z"
        stroke="#FF5500"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

interface ICurrencyInfo {
  name: string;
  logo: string;
  chainName: string;
  chainLogo: string;
}

const Modal = ({
  setIsActive,
  firstData,
  secondData,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd,
  noLiquidity,
  liquidityMinted,
  attemptingTxn,
  hash,
}: {
  setIsActive: (x: boolean) => void;
  firstData?: ICurrencyInfo;
  secondData?: ICurrencyInfo;
  price?: Fraction;
  currencies: { [field in Field]?: Currency };
  parsedAmounts: { [field in Field]?: CurrencyAmount };
  poolTokenPercentage?: Percent;
  onAdd: () => void;
  noLiquidity?: boolean;
  liquidityMinted: any;
  attemptingTxn: boolean;
  hash: string | undefined;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(true);

  const handleConfirm = () => {
    onAdd();
    setIsLoading(true);
  };

  useEffect(() => {
    if (attemptingTxn && hash) {
      setIsSubmitted(true);
    }
  }, [hash]);

  const { chainId } = useWallet();
  const loadedChainId: ChainId = useMemo(() => chainId as ChainId, [chainId]);

  return (
    <div
      style={{ backdropFilter: "blur(3px)" }}
      className="w-full min-h-screen flex items-center xs:px-0 px-4 bg-black/30 justify-center fixed top-0 left-0 flex justify-center items-center z-[1000]"
    >
      <div className="flex flex-col w-[480px] h-[580px] py-7 px-6 rounded-3xl bg-black/80 border border-primary">
        <div className="flex justify-between items-center">
          <h1>You will receive</h1>
          <IoClose
            onClick={() => setIsActive(false)}
            className="text-[#ff3503] text-xl cursor-pointer"
          />
        </div>
        <div className="h-24 relative w-full mt-6">
          <div className="flex items-center gap-x-4 h-full w-full absolute top-0 left-0 px-6 z-50">
            <h1 className="font-bold text-3xl">{liquidityMinted}</h1>
            {/* <img src="/assets/btc.png" className="h-8 w-8 rounded-full" alt="" /> */}
            {!firstData?.logo ? (
              <div className="rounded-full h-6 w-6 border bg-[rgba(255,255,255,0.24)] flex justify-center items-center">
                <p>?</p>
              </div>
            ) : (
              <img
                src={firstData?.logo}
                className={`sm:h-7 h-5 sm:w-7 w-5 rounded-full -mt-2 z-0`}
                alt=""
              />
            )}

            {!secondData?.logo ? (
              <div className="rounded-full h-6 w-6 border bg-[rgba(255,255,255,0.24)] flex justify-center items-center">
                <p>?</p>
              </div>
            ) : (
              <img
                src={secondData?.logo}
                className={`sm:h-7 h-5 sm:w-7 w-5 rounded-full -mt-2 z-0`}
                alt=""
              />
            )}
          </div>
          <SVGWrapper className="absolute top-0 left-0 h-full w-full z-0" />
        </div>
        <div className="flex flex-col mt-8 w-full">
          <h1 className="font-bold text-xl">
            {firstData?.name}/{secondData?.name} Pool Tokens
          </h1>
          <p className="text-[12px] mt-3">
            Output is estimated. if the price changes by more than 0.8% Your
            Transaction will revert.
          </p>
        </div>
        <div className="flex flex-col gap-y-[14px] mt-8">
          <div className="flex items-center  w-full justify-between">
            <p>
              {currencies[Field.CURRENCY_A] === ETHER
                ? NATIVE_SYMBOLS[loadedChainId]
                : currencies[Field.CURRENCY_A]?.symbol}{" "}
              Deposited
            </p>
            <span>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</span>
          </div>
          <div className="flex items-center  w-full justify-between">
            <p>
              {currencies[Field.CURRENCY_B] === ETHER
                ? NATIVE_SYMBOLS[loadedChainId]
                : currencies[Field.CURRENCY_B]?.symbol}{" "}
              Deposited
            </p>
            <span>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</span>
          </div>
          <div className="flex items-start  w-full justify-between">
            <p>Rates</p>
            <div className="flex gap-y-4 flex-col items-start">
              <span>
                {`1 ${
                  currencies[Field.CURRENCY_A] === ETHER
                    ? NATIVE_SYMBOLS[loadedChainId]
                    : currencies[Field.CURRENCY_A]?.symbol
                } = ${price?.toSignificant(4)} ${
                  currencies[Field.CURRENCY_B] === ETHER
                    ? NATIVE_SYMBOLS[loadedChainId]
                    : currencies[Field.CURRENCY_B]?.symbol
                }`}
              </span>
              <span>
                {`1 ${
                  currencies[Field.CURRENCY_B] === ETHER
                    ? NATIVE_SYMBOLS[loadedChainId]
                    : currencies[Field.CURRENCY_B]?.symbol
                } = ${price?.invert().toSignificant(4)} ${
                  currencies[Field.CURRENCY_A] === ETHER
                    ? NATIVE_SYMBOLS[loadedChainId]
                    : currencies[Field.CURRENCY_A]?.symbol
                }`}
              </span>
            </div>
          </div>
          <div className="flex items-center  w-full justify-between">
            <p>Share of Pool</p>
            <span>
              {noLiquidity ? "100" : poolTokenPercentage?.toSignificant(4)}%
            </span>
          </div>
          <button
            onClick={handleConfirm}
            className="outline-none border-none bg-gradient-to-r from-primary to-secondary rounded-full text-base font-bold text-whilte py-3"
          >
            Confirm Supply
          </button>
        </div>
      </div>
      {/* confirmation loader */}
      {attemptingTxn ? (
        <ConfirmationLoader
          className="fixed min-h-screen w-full bg-black/80 z-[1000]"
          isLoading={isLoading}
          handleCancel={setIsLoading}
          title="Waiting for confirmation"
          message={`Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(
            6
          )} ${
            currencies[Field.CURRENCY_A] === ETHER
              ? NATIVE_SYMBOLS[loadedChainId]
              : currencies[Field.CURRENCY_A]?.symbol
          } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${
            currencies[Field.CURRENCY_B] === ETHER
              ? NATIVE_SYMBOLS[loadedChainId]
              : currencies[Field.CURRENCY_B]?.symbol
          } `}
          description="Confirm this transaction in your wallet"
        />
      ) : hash ? (
        // <TransactionSubmittedContent chainId={chainId} hash={hash} onDismiss={onDismiss} />
        <TransactionModal
          isActive={isSubmitted}
          title="Transaction Submitted"
          message="View on Explorer"
          btnValue="Close"
          handleIsActive={setIsSubmitted}
          link={getBscScanLink(loadedChainId, hash ?? "", "transaction")}
          icon={
            <FaArrowUp
              className="text-5xl text-[#ff3503]"
              style={{ filter: "box-shadow(0 0 20px rgba(255,35,3,0.2))" }}
            />
          }
          handleBtn={() => {}}
        />
      ) : null}
    </div>
  );
};

const ConfirmOrderModal = ({
  isActive,
  setIsActive,
  firstData,
  secondData,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd,
  noLiquidity,
  liquidityMinted,
  attemptingTxn,
  hash,
}: {
  isActive: boolean;
  setIsActive: (x: boolean) => void;
  firstData?: ICurrencyInfo;
  secondData?: ICurrencyInfo;
  price?: Fraction;
  currencies: { [field in Field]?: Currency };
  parsedAmounts: { [field in Field]?: CurrencyAmount };
  poolTokenPercentage?: Percent;
  onAdd: () => void;
  noLiquidity?: boolean;
  liquidityMinted: any;
  attemptingTxn: boolean;
  hash: string | undefined;
}) => {
  return (
    <>
      {isActive ? (
        <Modal
          setIsActive={setIsActive}
          firstData={firstData}
          secondData={secondData}
          price={price}
          currencies={currencies}
          parsedAmounts={parsedAmounts}
          poolTokenPercentage={poolTokenPercentage}
          onAdd={onAdd}
          noLiquidity={noLiquidity}
          liquidityMinted={liquidityMinted}
          attemptingTxn={attemptingTxn}
          hash={hash}
        />
      ) : null}
    </>
  );
};

export default ConfirmOrderModal;

import React from "react";
import "./loader.css";
interface QuotesLoaderProps {
  isLoading: boolean;
  className?: string;
  loadingText: string;
}
const QuotesLoader = ({
  isLoading,
  className,
  loadingText,
}: QuotesLoaderProps) => {
  return (
    <>
      {isLoading && (
        <div
          className={`${className} px-6 py-4 text-base font-bold flex items-end gap-x-2 justify-start`}
        >
          <p>{loadingText}</p>
          <div className="flex gap-x-1 relative -top-1">
            <span className="h-2 w-2 rounded-full bg-white loader-1 loading-animation"></span>
            <span className="h-2 w-2 rounded-full bg-white loader-2 loading-animation"></span>
            <span className="h-2 w-2 rounded-full bg-white loader-3 loading-animation"></span>
          </div>
        </div>
      )}
    </>
  );
};

export default QuotesLoader;

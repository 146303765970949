import React from 'react'
import BlazBot from '../../../assets/BlazBot.svg'

interface BotMessageProps {
    text: string;
  }

const BotMessage = ({text} : BotMessageProps) => {
  return (
    <div className='flex justify-start'>
        <div className='flex items-center gap-4'>
            <img className='h-[50px]' src={BlazBot} alt='bot' />
            <div className='my-2 p-3 bg-[#2F2F2F] text-[#FFFFFF] text-sm rounded-r-2xl rounded-b-2xl max-w-xs'>
                {text}
            </div>
        </div>
    </div>
  )
}

export default BotMessage
import { SDK, CHAIN_TYPE, BlockchainsInfo, BlockchainName } from "rubic-sdk";
import { BigNumber, Contract, ethers } from "ethers";
import axios from "axios";
import tokenAbi from "./abi/token.json";
import "@butternetwork/bridge/contracts/interface/IButterBridgeV3.sol";
import axiosInstance from "./axios";
import { calculateFee, typecast, updateQueryString } from "./functions";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  AlchemyQuote,
  ChangellyQuote,
  OnrampQuote,
  RampingQuotes,
  TradeType,
} from "./interfaces.trade";
import { getChainNameById, getContractAddressByChainId } from "./mappings";
const addressZero = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
export class TradeManager {
  rubicSdk: SDK | null;
  senderAddress?: string | null;
  senderTronNitro?: string | null;
  receiverAddress?: string | null;
  evmCompitable?: string | null;
  providerAddress: string;
  nitroPartnerId: number;
  nitroBaseUrl: string;
  oneInchBaseUrl: string;
  symbiosisBaseUrl: string;
  openOceanBaseUrl: string;
  oneInchFee: number; // 1 - 3
  tronweb: any;
  tronFeeLimit: number;
  unizenBaseUrl: string;
  icecreamswapBaseUrl: string;
  butterNetworkBaseUrl: string;
  changeNowBaseUrl: string;
  kyberSwapBaseUrl: string;
  lifiBaseUrl: string;
  sushiSwapBaseUrl: string;
  constructor() {
    this.rubicSdk = null;
    this.senderAddress = null;
    this.providerAddress = "0x231427e3290bc09D93377bAE1105a15129F7d612";
    this.nitroPartnerId = 60;
    this.nitroBaseUrl = "https://api-beta.pathfinder.routerprotocol.com/api";
    this.oneInchBaseUrl = "/defi/1inch";
    this.symbiosisBaseUrl = "https://api.symbiosis.finance/crosschain";
    this.openOceanBaseUrl = "/defi/openocean";
    this.unizenBaseUrl = `https://api.zcx.com/trade/v1`;
    this.icecreamswapBaseUrl = "https://aggregator.icecreamswap.com";
    this.changeNowBaseUrl = "https://api.changenow.io/v2/exchange/range";
    this.butterNetworkBaseUrl = "https://bs-router-v3.chainservice.io";
    this.kyberSwapBaseUrl = "https://aggregator-api.kyberswap.com";
    this.lifiBaseUrl = "https://li.quest/v1";
    this.sushiSwapBaseUrl =
      "https://virtserver.swaggerhub.com/matthewlilley/Swap/5.0.0";
    this.oneInchFee = 1;
    this.tronweb = window.tronWeb;
    this.tronFeeLimit = 1000000000;
  }

  async init(chains: any[]) {
    const rpcProviders = chains.reduce((data, chain) => {
      data[BlockchainsInfo.getBlockchainNameById(chain.id)!] = {
        rpcList: chain.metamask.rpcUrls,
      };

      return data;
    }, {});
    // rpcProviders.TRON = {
    //   rpcList: [
    //     {
    //       fullHost: "https://api.trongrid.io",
    //       headers: {
    //         "TRON-PRO-API-KEY": "9c05c65f-f656-425c-8c6d-78d1971f2d94",
    //       },
    //     },
    //   ],
    // };
    // console.log(rpcProviders, "rpcProviders");

    this.rubicSdk = await SDK.createSDK({
      rpcProviders,
      providerAddress: {
        [CHAIN_TYPE.EVM]: {
          crossChain: this.providerAddress,
          onChain: this.providerAddress,
        },
      },
    });
  }

  setWalletAddress(address: string) {
    this.senderAddress = address;
  }

  setReceiverAddress(address: string) {
    this.receiverAddress = address;
  }

  setEvmCompitable(address: string) {
    this.evmCompitable = address;
  }

  setSenderTronNitro(address: string) {
    console.log("setSenderTronNit", address);
    this.senderTronNitro = address;
  }

  async get1InchQuote(params: any) {
    const headers = {
      Authorization: "Bearer ",
    };
    if (params.fromChainId !== params.toChainId) {
      return;
    }

    const query = {
      src:
        params.fromTokenAddress === "0x0000000000000000000000000000000000000000"
          ? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
          : params.fromTokenAddress,
      dst:
        params.toTokenAddress === "0x0000000000000000000000000000000000000000"
          ? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
          : params.toTokenAddress,
      amount: ethers.utils
        .parseUnits(params.amount, params.decimals)
        .toString(),
      fee: this.oneInchFee,
    };

    const { data } = await axiosInstance.post("/defi/1inch", {
      path: `/swap/v6.0/${params.fromChainId}/quote`,
      query: {
        ...query,
        includeTokensInfo: true,
        includeProtocols: true,
        includeGas: true,
      },
    });
    const swapAmount = ethers.utils.formatUnits(
      data.dstAmount,
      data.dstToken?.decimals
    );

    const swap = async ({
      provider,
      receiver,
      slippageTolerance = 0.5,
    }: any) => {
      const signer = await provider.getSigner();
      const walletAddress = await signer.getAddress();
      const spender = await this.get1InchSepender(Number(params.fromChainId));
      await this.setAllowance(
        params.fromTokenAddress,
        spender,
        provider,
        params.fromChainId,
        BigNumber.from(
          ethers.utils.parseUnits(params.amount, params.decimals).toString()
        ),
        "1inch"
      );

      const res = await axiosInstance.post("/defi/1inch", {
        query: {
          ...query,
          includeTokensInfo: true,
          includeProtocols: true,
          includeGas: true,
          from: walletAddress,
          slippage: slippageTolerance,
          receiver: receiver || walletAddress,
        },
        path: `/swap/v6.0/${params.fromChainId}/swap`,
      });
      const txdata = res.data.tx;

      const tx = await signer.sendTransaction({
        gasLimit: 500000,
        data: txdata.data,
        from: txdata.from,
        to: txdata.to,
        gasPrice: txdata.gasPrice,
        value: txdata.value,
      });

      updateQueryString(`?hash=${tx.hash}`);

      const txPayload = {
        moduleName: "swap",
        walletAddress: walletAddress,
        route: "1inch",
        txHash: tx?.hash,
        from: tx?.from,
        to: tx?.to,
        value: params.amount,
        txFrom: {
          chainId: params.fromChainId,
          tokenName: params?.fromTokenName,
          value: params.amount,
        },
        txTo: {
          chainId: params.fromChainId,
          tokenName: params?.toTokenName,
          value: ethers.utils.formatUnits(
            res.data?.dstAmount,
            res.data?.dstToken?.decimals
          ),
        },
        status: "pending",
      };

      await this.saveInitiateTxn(txPayload);
      await tx.wait();
      return tx;
    };

    const payload = {
      source: "1inch",
      route: "1inch",
      amount: Number(swapAmount).toFixed(4),
      usdAmount: 0,
      networkFee: 0,
      platformFee: 0,
      priceImpact: 0,
      slippage: data.slippageTolerance,
      swap,
    };

    return payload;
  }

  async getSymbiosisQuote(params: any) {
    const amount = ethers.utils
      .parseUnits(params.amount, params.fromTokenDecimals)
      .toString();

    const payload = {
      tokenAmountIn: {
        address: params?.fromTokenAddress,
        symbol: params?.fromTokenSymbol,
        amount,
        chainId: Number(params?.fromChainId),
        decimals: params?.fromTokenDecimals,
      },
      tokenOut: {
        chainId: Number(params?.toChainId),
        address: params?.toTokenAddress,
        symbol: params?.toTokenSymbol,
        decimals: params?.toTokenDecimals,
      },
      from: params?.fromAddress,
      to: params?.toAddress,
      slippage: 50,
    };

    const { data } = await axios.post(
      this.symbiosisBaseUrl + "/v1/swap",
      payload
    );

    const swapAmount = ethers.utils
      .formatUnits(
        data?.tokenAmountOut?.amount,
        data?.tokenAmountOut?.fromTokenDecimals
      )
      .toString();
    const hexData = data?.tx?.data;
    const contractAddress = data?.tx?.to;

    let tx: any;
    if (data.type === "tron") {
      const txn = await this.tronweb.transactionBuilder.triggerSmartContract(
        contractAddress,
        "",
        {
          callValue: 0,
          feeLimit: this.tronFeeLimit,
        },
        [
          {
            type: "bytes",
            value: hexData,
          },
        ],
        this.tronweb.defaultAddress.base58
      );
      tx = txn?.transaction;
    } else {
      tx = data?.tx;
    }

    const swap = async ({ provider }: any) => {
      await this.setAllowance(
        params.fromTokenAddress,
        data?.approveTo,
        provider,
        params.fromChainId,
        BigNumber.from(
          ethers.utils
            .parseUnits(params.amount, params.fromTokenDecimals)
            .toString()
        ),
        "Symbiosis"
      );

      const { walletAddress, hash } = await this.triggerContract(
        params.fromChainId,
        provider,
        tx
      );

      updateQueryString(`?hash=${hash}`);

      const txPayload = {
        moduleName: params.moduleName,
        walletAddress: walletAddress,
        route: "Symbiosis",
        txHash: hash,
        from: walletAddress,
        to: this.receiverAddress,
        value: params?.amount,
        txFrom: {
          chainId: params.fromChainId,
          tokenName: params?.fromTokenName,
          value: params?.amount,
        },
        txTo: {
          chainId: params.fromChainId,
          tokenName: params?.toTokenName,
          value: ethers.utils.formatUnits(
            data?.tokenAmountOut?.amount,
            data?.tokenAmountOut?.fromTokenDecimals
          ),
        },
        status: "pending",
      };

      await this.saveInitiateTxn(txPayload);

      return { hash };
    };

    const quoteData = {
      source: "Symbiosis",
      route: "Symbiosis",
      amount: Number(swapAmount).toFixed(4),
      usdAmount: 0,
      networkFee: 0,
      platformFee: 0,
      priceImpact: 0,
      slippage: 50 / 100,
      swap,
    };

    return quoteData;
  }

  async getOpenOceanQuote(params: any) {
    const query = {
      chain: params.fromChainId,
      inTokenAddress: params.fromTokenAddress,
      outTokenAddress: params.toTokenAddress,
      amount: Number(params.amount),
      slippage: 0.5,
      gasPrice: params?.gasPrice * 2 || 20,
      account: params.fromAddress,
    };

    const res = await axiosInstance.post(
      this.openOceanBaseUrl,
      {},
      {
        params: query,
      }
    );

    const data = res?.data?.data?.data;
    const swapAmount = ethers.utils
      .formatUnits(data?.outAmount, data?.outToken?.decimals)
      .toString();

    const swap = async ({ provider }: any) => {
      const signer = await provider.getSigner();
      const walletAddress = await signer.getAddress();

      await this.setAllowance(
        params.fromTokenAddress,
        data?.to,
        provider,
        params.fromChainId,
        BigNumber.from(
          ethers.utils.parseUnits(params.amount, params.decimals).toString()
        ),
        "openocean"
      );

      const tx = await signer.sendTransaction({
        data: data?.data,
        from: data?.from,
        to: data?.to,
        gasLimit: data?.estimatedGas,
        gasPrice: data?.gasPrice * 3,
        value: data?.value ? data?.value : data?.inAmount,
      });

      updateQueryString(`?hash=${tx.hash}`);

      const txPayload = {
        moduleName: "swap",
        walletAddress: walletAddress,
        route: "openocean",
        txHash: tx?.hash,
        from: tx?.from,
        to: tx?.to,
        value: params.amount,
        txFrom: {
          chainId: params.fromChainId,
          tokenName: params?.fromTokenName,
          value: params.amount,
        },
        txTo: {
          chainId: params.fromChainId,
          tokenName: params?.toTokenName,
          value: ethers.utils.formatUnits(
            data?.outAmount,
            data?.outToken?.decimals
          ),
        },
        status: "pending",
      };
      await this.saveInitiateTxn(txPayload);
      await tx.wait();
      return tx;
    };

    return {
      source: "Openocean",
      route: "Openocean",
      amount: Number(swapAmount).toFixed(4),
      usdAmount: data?.outToken?.usd,
      networkFee: 0,
      platformFee: 0,
      priceImpact: data?.price_impact,
      slippage: 1,
      swap,
    };
  }

  async getChangeNowQuote(params: any) {
    const query = {
      fromCurrency: params.fromTokenSymbol,
      toCurrency: params.toTokenSymbol,
      fromNetwork: params.fromChainId,
      toNetwork: params.toChainId,
      flow: "standard",
    };
  }

  async getUnizenQuote(params: any) {
    const query = {
      fromTokenAddress: params.fromTokenAddress,
      toTokenAddress: params.toTokenAddress,
      amount: ethers.utils
        .parseUnits(params.amount, params.decimals)
        .toString(),
      sender: params.fromAddress,
      slippage: 0.005,
    };
    const res = await axios.get(
      this.unizenBaseUrl + `/${params.fromChainId}/quote/single`,
      {
        headers: { "x-api-key": process.env.REACT_APP_API_UNIZEN_IO_LINK },
        params: query,
      }
    );

    const data = await res?.data;
    const quotes = data.map((quote: any) => {
      const swapAmount = ethers.utils
        .formatUnits(quote?.toTokenAmount, quote?.tokenTo?.decimals)
        .toString();

      const swap = async ({ provider }: { provider: any }) => {
        const signer = provider.getSigner();
        const spender = await this.getUnizenSpender(params.fromChainId);
        await this.setAllowance(
          params.fromTokenAddress,
          spender,
          provider,
          params.fromChainId,
          BigNumber.from(
            ethers.utils.parseUnits(params.amount, params.decimals).toString()
          ),
          "Unizen"
        );

        const swapResponse = await axios.post(
          this.unizenBaseUrl + `/${params.fromChainId}/swap/single`,
          {
            transactionData: quote?.transactionData,
            nativeValue: quote?.nativeValue,
            account: params?.fromAddress,
            tradeType: quote?.tradeType,
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_UNIZEN_IO_LINK}`,
              "Content-Type": "application/json",
            },
          }
        );
        const txData = swapResponse?.data;
        const contractAddress = getContractAddressByChainId(params.fromChainId);
        const tx = await signer.sendTransaction({
          from: params.fromAddress,
          to: contractAddress,
          gasLimit: txData?.estimatedGas,
          data: txData?.data,
          gasPrice: txData?.gasPrice,
          value: txData?.nativeValue,
        });
        console.log("res unizen", tx);
        // updateQueryString(`?hash=${tx.hash}`);
        // const txPayload = {
        //   moduleName: "swap",
        //   walletAddress: params.fromAddress,
        //   route: "unizen",
        //   txHash: tx?.hash,
        //   from: tx?.from,
        //   to: tx?.to,
        //   value: params.amount,
        //   txFrom: {
        //     chainId: params.fromChainId,
        //     tokenName: params?.fromTokenName,
        //     value: params.amount,
        //   },
        //   txTo: {
        //     chainId: params.fromChainId,
        //     tokenName: params?.toTokenName,
        //     value: ethers.utils.formatUnits(
        //       data?.outAmount,
        //       data?.outToken?.decimals
        //     ),
        //   },
        //   status: "pending",
        // };
        // await this.saveInitiateTxn(txPayload);
        await tx.wait();
        return tx;
      };
      return {
        source: "Unizen",
        route: quote?.protocol[0]?.name,
        amount: Number(swapAmount).toFixed(4),
        usdAmount: quote?.tokenTo?.priceInUsd,
        networkFee: 0,
        platformFee: 0,
        priceImpact: quote?.priceImpact,
        slippage: 1,
        swap,
      };
    });

    return quotes;
  }

  async getUnizenBridgeQuote(params: any) {
    const query = {
      fromTokenAddress: params.fromTokenAddress,
      toTokenAddress: params.toTokenAddress,
      amount: ethers.utils
        .parseUnits(params.amount, params.decimals)
        .toString(),
      destinationChainId: params.toChainId,
      sender: params.fromAddress,
    };
    if (params.fromChainId === params.toChainId) {
      return await this.getUnizenQuote(params);
    }
    const res = await axios.get(
      `${this.unizenBaseUrl}/${params.fromChainId}/quote/cross`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_UNIZEN_IO_LINK}`,
        },
        params: query,
      }
    );
    const data = await res?.data;
    const quotes = data.map((quote: any) => {
      const swapAmount = ethers.utils
        .formatUnits(quote?.dstTrade?.toTokenAmount, params.toTokenDecimals)
        .toString();
      const swap = async ({ provider }: { provider: any }) => {
        const signer = provider.getSigner();
        const spender = await this.getUnizenSpender(params.fromChainId);
        await this.setAllowance(
          params.fromTokenAddress,
          spender,
          provider,
          params.fromChainId,
          BigNumber.from(
            ethers.utils.parseUnits(params.amount, params.decimals).toString()
          ),
          "Unizen"
        );
        const swapResponse = await axios.post(
          this.unizenBaseUrl + `/${params.fromChainId}/swap/cross`,
          {
            transactionData: quote?.transactionData,
            nativeValue: quote?.nativeValue,
            account: params?.fromAddress,
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_UNIZEN_IO_LINK}`,
              "Content-Type": "application/json",
            },
          }
        );
        const txData = swapResponse?.data;
        const contractAddress = getContractAddressByChainId(params.fromChainId);
        const tx = await signer.sendTransaction({
          from: params.fromAddress,
          to: contractAddress,
          gasLimit: txData?.estimateGas,
          data: txData?.data,
          gasPrice: txData?.gasPrice,
          value: txData?.nativeValue,
        });
        await tx.wait();
        return tx;
      };

      return {
        source: "unizen",
        route: quote?.providerInfo?.name ?? "Unizen",
        amount: Number(swapAmount).toFixed(4),
        usdAmount: 0,
        networkFee: quote?.fee,
        platformFee: 0,
        priceImpact: quote?.priceImpact.toFixed(5),
        slippage: quote?.slippage.toFixed(5),
        swap,
      };
    });
    return quotes;
  }

  async getIcreamSwapQuote(params: any) {
    const query = {
      src: params.fromTokenAddress,
      dst: params.toTokenAddress,
      amount: ethers.utils
        .parseUnits(params.amount, params.decimals)
        .toString(),
      from: params.fromAddress,
    };
    const res = await axios.get(
      this.icecreamswapBaseUrl + `/${params.fromChainId}`,
      {
        params: query,
      }
    );
    console.log(params.decimals, "decimals");
    console.log("icrecream", res?.data);
    const data = res?.data;
    const swapAmount = (data?.toAmount / 10 ** params.toTokenDecimals).toFixed(
      4
    );
    const txData = data?.tx;
    const swap = async ({ provider }: { provider: any }) => {
      const signer = provider.getSigner();
      const gasPrice = await provider.getGasPrice();
      await this.setAllowance(
        params.fromTokenAddress,
        txData?.to,
        provider,
        params.fromChainId,
        BigNumber.from(
          ethers.utils.parseUnits(params.amount, params.decimals).toString()
        ),
        "icecreamswap"
      );
      const tx = await signer.sendTransaction({
        from: txData?.from,
        to: txData?.to,
        data: txData?.data,
        maxFeePerGas: gasPrice.mul(40),
        value:
          params.fromTokenAddress ===
          "0x0000000000000000000000000000000000000000"
            ? txData?.value
            : 0,
      });
      await tx.wait();
      return tx;
    };
    return {
      source: "IcecreamSwap",
      route: "IcecreamSwap",
      amount: swapAmount,
      usdAmount: 0,
      networkFee: 0,
      platformFee: 0,
      priceImpact: 0,
      slippage: 1,
      swap,
    };
  }

  async getButterNetworkQuotes(params: any) {
    const query = {
      fromChainId: params.fromChainId,
      toChainId: params.toChainId,
      tokenInAddress: params.fromTokenAddress,
      tokenOutAddress: params.toTokenAddress,
      amount: params.amount.toString(),
      type: "exactIn",
      entrance: "Blazpay",
      slippage: 2000,
      from: params.fromAddress,
      receiver: params.fromChainId,
    };
    const res = await axios.get(this.butterNetworkBaseUrl + `/routeAndSwap`, {
      params: query,
    });
    const data = res?.data.data;
    const quotes = data.map((quote: any) => {
      const swap = async ({ provider }: { provider: any }) => {
        console.log(
          "res butter",
          ethers.utils
            .parseUnits(quote?.route.dstChain?.totalAmountOut, 18)
            .toString()
        );
        const bridgeAbi = [
          "function getNativeFee(address _token, uint256 _gasLimit, uint256 _toChain) external view returns (uint256)",
          "function swapOutToken(address _sender, address _token, bytes memory _to, uint256 _amount, uint256 _toChain, bytes calldata _swapData) external payable returns (bytes32)",
        ];
        const bridge = new ethers.Contract(
          quote?.route?.contract,
          bridgeAbi,
          provider.getSigner()
        );
        const gasLimit = await bridge.estimateGas.swapOutToken(
          params.fromAddress,
          params.fromTokenAddress,
          params.toTokenAddress,
          ethers.utils.parseUnits(params.amount, params.decimals),
          params.toChainId,
          "0x",
          { value: quote?.route?.swapFee?.nativeFee }
        );
        let BridgeParam = {
          gasLimit: gasLimit,
          refundAddress: params.fromAddress,
          swapData: "0x",
        };
        let swapData = ethers.utils.defaultAbiCoder.encode(
          ["tuple(uint256,bytes,bytes)"],
          [
            [
              BridgeParam.gasLimit,
              BridgeParam.refundAddress,
              BridgeParam.swapData,
            ],
          ]
        );
        let tx = await bridge.swapOutToken(
          params.fromAddress,
          params.fromTokenAddress,
          params.toTokenAddress,
          ethers.utils
            .parseUnits(quote?.route.dstChain?.totalAmountOut, 18)
            .toString(),
          swapData,
          { value: quote?.route?.swapFee?.nativeFee }
        );
        await tx.wait();
      };

      return {
        source: "ButterNetwork",
        route: quote?.route?.srcChain?.route[0]?.dexName || "Butter",
        amount: Number(
          parseFloat(quote?.route.dstChain?.totalAmountOut)
        ).toFixed(4),
        usdAmount: 0,
        networkFee: 0,
        platformFee: quote?.route?.bridgeFee?.amount || "0",
        priceImpact: quote?.route.srcChain?.route[0]?.priceImpact || "0",
        slippage: 1,
        swap,
      };
    });
    return quotes;
  }

  async getKyberSwapQuotes(params: any) {
    const fromTokenAdd =
      params.fromTokenAddress === "0x0000000000000000000000000000000000000000"
        ? "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
        : params.fromTokenAddress;
    const toTokenAdd =
      params.toTokenAddress === "0x0000000000000000000000000000000000000000"
        ? "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
        : params.toTokenAddress;
    const query = {
      tokenIn: fromTokenAdd,
      tokenOut: toTokenAdd,
      amountIn: ethers.utils
        .parseUnits(params.amount, params.decimals)
        .toString(),
      source: "blazpay",
    };
    const chain = getChainNameById(params.fromChainId);
    const res = await axios.get(
      `${this.kyberSwapBaseUrl}/${chain}/api/v1/routes`,
      {
        headers: { "X-Client-Id": "blazpay" },
        params: query,
      }
    );
    const data = await res?.data?.data;
    const swapAmount = ethers.utils
      .formatUnits(data?.routeSummary?.amountOut, params.toTokenDecimals)
      .toString();
    const swap = async ({ provider }: { provider: any }) => {
      const payload = {
        routeSummary: data?.routeSummary,
        tokenIn: data?.routeSummary?.tokenIn,
        amountIn: data?.routeSummary?.amountIn,
        amountInUsd: data?.routeSummary?.amountInUsd,
        tokenOut: data?.routeSummary?.tokenOut,
        amountOut: data?.routeSummary?.amountOut,
        amountOutUsd: data?.routeSummary?.amountOutUsd,
        gas: data?.routeSummary?.gas,
        gasPrice: data?.routeSummary?.gapPrice,
        gasUsd: data?.routeSummary?.gasUsd,
        extraFee: data?.routeSummary?.extraFee,
        route: data?.routeSummary?.route,
        sender: params.fromAddress,
        recipient: params.fromAddress,
        slippageTolerance: 10,
        source: "blazpay",
      };
      const res = await axios.post(
        `${this.kyberSwapBaseUrl}/${chain}/api/v1/route/build`,
        payload,
        {
          headers: { "X-Client-Id": "blazpay" },
        }
      );
      const txData = await res?.data?.data;
      const signer = provider.getSigner();

      await this.setAllowance(
        params.fromTokenAddress,
        txData?.routerAddress,
        provider,
        params.fromChainId,
        BigNumber.from(
          ethers.utils.parseUnits(params.amount, params.decimals).toString()
        ),
        "KyberSwap"
      );

      const gasPrice = await provider.getGasPrice();
      const tx = await signer.sendTransaction({
        data: txData?.data,
        from: params.fromAddress,
        to: txData?.routerAddress,
        maxFeePerGas: gasPrice.mul(20),
        value:
          params.fromTokenAddress ===
          "0x0000000000000000000000000000000000000000"
            ? txData?.amountIn
            : 0,
      });

      await tx.wait();
      return tx;
    };
    return {
      source: "KyberSwap",
      route: "KyberSwap",
      amount: Number(swapAmount).toFixed(4),
      usdAmount: data?.routeSummary?.amountOutUsd,
      networkFee: 0,
      platformFee: 0,
      priceImpact: 0,
      slippage: 1,
      swap,
    };
  }

  async getLifiQuote(params: any) {
    const query = {
      fromChain: params.fromChainId,
      toChain: params.toChainId,
      fromToken: params.fromTokenAddress,
      toToken: params.toTokenAddress,
      fromAmount: ethers.utils
        .parseUnits(params.amount, params.decimals)
        .toString(),
      fromAddress: params.fromAddress,
    };
    const res = await axios.get(`${this.lifiBaseUrl}/quote`, {
      params: query,
    });
    const data = await res?.data;
    const swap = async ({ provider }: { provider: any }) => {
      const signer = provider.getSigner();
      await this.setAllowance(
        params.fromTokenAddress,
        data?.transactionRequest?.to,
        provider,
        params.fromChainId,
        BigNumber.from(
          ethers.utils.parseUnits(params.amount, params.decimals).toString()
        ),
        "Lifi"
      );
      const tx = await signer.sendTransaction({
        from: data?.transactionRequest?.from,
        to: data?.transactionRequest?.to,
        value: data?.transactionRequest?.value,
        data: data?.transactionRequest?.data,
        gasPrice: data?.transactionRequest?.gasPrice,
        gasLimit: data?.transactionRequest?.gasLimit,
      });
      // updateQueryString(`?hash=${tx.hash}`);
      // const txPayload = {
      //   moduleName: "swap",
      //   walletAddress: params.fromAddress,
      //   route: "lifi",
      //   txHash: tx?.hash,
      //   from: tx?.from,
      //   to: tx?.to,
      //   value: params.amount,
      //   txFrom: {
      //     chainId: params.fromChainId,
      //     tokenName: params?.fromTokenName,
      //     value: params.amount,
      //   },
      //   txTo: {
      //     chainId: params.fromChainId,
      //     tokenName: params?.toTokenName,
      //     value: ethers.utils.formatUnits(
      //       data?.outAmount,
      //       data?.outToken?.decimals
      //     ),
      //   },
      //   status: "pending",
      // };
      // await this.saveInitiateTxn(txPayload);
      await tx.wait();
      return tx;
    };
    const swapAmount = ethers.utils.formatUnits(
      data?.estimate?.toAmount,
      params.toTokenDecimals
    );
    return {
      source: "Lifi",
      route: data?.tool || "Lifi",
      amount: Number(swapAmount).toFixed(4),
      usdAmount: 0,
      networkFee: 0,
      platformFee: 0,
      priceImpact: 0,
      slippage: data?.action?.slippage,
      swap,
    };
  }
  async getSushiSwapQuote(params: any) {
    const query = {
      chainId: params.fromChainId,
      tokenIn: params.fromTokenAddress,
      tokenOut: params.toTokenAddress,
      amount: ethers.utils
        .parseUnits(params.amount, params.decimals)
        .toString(),
      maxSlippage: 1,
      gasPrice: ethers.utils.parseUnits("50000", "wei"),
    };
    const res = await axios.get(`${this.sushiSwapBaseUrl}/swap/v5`, {
      params: query,
    });
    const swap = async ({ provider }: { provider: any }) => {};
    return {
      source: "SushiSwap",
      route: "SushiSwap",
      amount: 0,
      usdAmount: 0,
      networkFee: 0,
      platformFee: 0,
      priceImpact: 0,
      slippage: 0,
      swap,
    };
  }
  async getFromBalance(params: any) {
    const headers = {
      Authorization: "Bearer QfBrWX7hElOn44EJlyCQSKVqj5MErm5H",
    };
    const { data } = await axiosInstance.post("/defi/1inch", {
      path: `/balance/v1.2/${params.fromToken.chain.id}/balances/${params.address}`,
      query: { referrer: "" },
      headers,
    });
    return data;
  }

  async getNitroQuote(params: any) {
    const body = {
      fromTokenAddress:
        params.fromTokenAddress === ethers.constants.AddressZero
          ? addressZero
          : params.fromTokenAddress,
      toTokenAddress:
        params.toTokenAddress === ethers.constants.AddressZero
          ? addressZero
          : params.toTokenAddress,
      amount: ethers.utils
        .parseUnits(params.amount, params.decimals)
        .toString(),
      fromTokenChainId: params.fromChainId,
      toTokenChainId: params.toChainId == "102" ? 900 : params.toChainId,
      partnerId: this.nitroPartnerId,
    };

    try {
      const { data } = await axios.get(this.nitroBaseUrl + "/v2/quote", {
        params: body,
        timeout: 20000,
      });
      const swap = async ({
        provider,
        receiver,
        slippageTolerance = 0.5,
      }: any) => {
        const response = await axios.post(
          this.nitroBaseUrl + "/v2/transaction",
          {
            ...data,
            slippageTolerance,
            senderAddress: this.senderTronNitro || this.senderAddress,
            receiverAddress: receiver || this.senderAddress,
          },
          {
            timeout: 20000,
          }
        );
        await this.setAllowance(
          params.fromTokenAddress,
          data.allowanceTo,
          provider,
          params.fromChainId,
          BigNumber.from(data.source.tokenAmount),
          "nitro"
        );

        const { walletAddress, hash } = await this.triggerContract(
          params.fromChainId,
          provider,
          response.data.txn
        );
        updateQueryString(`?hash=${hash}`);

        const txPayload = {
          moduleName: params.moduleName,
          walletAddress: walletAddress,
          route: "nitro",
          txHash: hash,
          from: walletAddress,
          to: this.receiverAddress,
          value: params?.amount,
          txFrom: {
            chainId: params.fromChainId,
            tokenName: params?.fromTokenName,
            value: params?.amount,
          },
          txTo: {
            chainId: params.fromChainId,
            tokenName: params?.toTokenName,
            value: ethers.utils.formatUnits(
              response.data?.destination?.tokenAmount,
              response.data?.destination?.asset?.decimals
            ),
          },
          status: "pending",
        };

        await this.saveInitiateTxn(txPayload);

        return { hash };
      };

      const platformFee = data.bridgeFee.amount
        ? Number(ethers.utils.formatUnits(data.bridgeFee.amount)).toFixed(4)
        : 0;

      const payload = {
        source: "nitro",
        route: "nitro",
        amount: Number(
          ethers.utils.formatUnits(
            data.destination.tokenAmount,
            data.destination.asset.decimals
          )
        ).toFixed(4),
        usdAmount: 0,
        networkFee: 0,
        platformFee,
        priceImpact: data.source.priceImpact,
        slippage: data.slippageTolerance,
        swap,
      };
      return payload;
    } catch (error: any) {
      console.log("NITRO ERROR: ", error);
      if (error.response.data.errorCode === "PATH-NF") {
        toast.error("No path found for this trade.");
      }
    }
  }

  async getRubicQuote({
    isCalculateActive,
    selectedQuote,
    fromTokenAddress,
    fromChainId,
    toTokenAddress,
    toChainId,
    fromTokenName,
    toTokenName,
    amount,
    onNewQuote,
  }: any) {
    console.log(
      BlockchainsInfo.getBlockchainNameById(
        toChainId == "102"
          ? toChainId
          : toChainId == "728126428"
          ? 195
          : toChainId
      ) as BlockchainName,
      toChainId == "102" ? 102 : toChainId == "728126428" ? 195 : toChainId,
      "chains"
    );
    if (fromChainId === "102") {
      console.log("here");
      return;
    }
    if (selectedQuote != null || isCalculateActive) return;
    const hasRoute = (item: any) =>
      ["lifi", "changenow", "rango", "layerzero"].includes(
        item.wrappedTrade?.tradeType.toLowerCase()
      );
    try {
      return new Promise((resolve) => {
        if (fromChainId === toChainId) return;
        this.rubicSdk?.crossChainManager
          .calculateTradesReactively(
            {
              address: fromTokenAddress,
              blockchain: BlockchainsInfo.getBlockchainNameById(
                fromChainId === "102" ? "7565164" : fromChainId
              ) as BlockchainName,
            },
            amount,
            {
              address: toTokenAddress,
              blockchain: BlockchainsInfo.getBlockchainNameById(
                toChainId == "102"
                  ? toChainId
                  : toChainId == "728126428"
                  ? 195
                  : toChainId
              ) as BlockchainName,
            }
          )
          .subscribe((data) => {
            if (selectedQuote != null || isCalculateActive) return;
            const trade = data.wrappedTrade?.trade;

            if (trade) {
              const tradeInfo = trade.getTradeInfo();

              if (hasRoute(data)) return;
              const swap = async ({
                walletAddress,
                receiver,
                slippageTolerance,
              }: {
                walletAddress: `0x${string}`;

                receiver: string;
                slippageTolerance: number;
              }) => {
                const id = uuidv4();
                updateQueryString(`?hash=${id}`);
                const txPayload = {
                  moduleName: "bridge",
                  walletAddress: walletAddress,
                  route: "rubic",
                  txHash: id,
                  from: walletAddress,
                  to: "",
                  value: trade.from.tokenAmount.toFormat(4),
                  txFrom: {
                    chainId: fromChainId,
                    tokenName: fromTokenName,
                    value: trade.from.tokenAmount.toFormat(4),
                  },
                  txTo: {
                    chainId: toChainId,
                    tokenName: toTokenName,
                    value: trade.to.tokenAmount.toFormat(4),
                  },
                  status: "pending",
                };
                await this.saveInitiateTxn(txPayload);
                const hash = await trade?.swap.bind(trade)({
                  receiverAddress: receiver,
                });
                console.log(hash, "in trade file");
                return {
                  hash,
                  id,
                };
              };
              onNewQuote({
                source: "rubic",
                route: data.wrappedTrade?.tradeType,
                amount: trade.to.tokenAmount.toFormat(4),
                usdAmount: trade.getUsdPrice().toFormat(2),
                networkFee: trade.networkFee.toFormat(4),
                platformFee: trade.platformFee.toFormat(4),
                priceImpact: tradeInfo.priceImpact,
                slippage: tradeInfo.slippage,
                swap,
              });
            }

            if (data.total === data.calculated) {
              resolve(true);
            }
          });
        // }
      });
    } catch (error) {
      console.log("RUBIC ERROR: ", error);
    }
  }

  async getSquidRouterQuote({
    isCalculateActive,
    selectedQuote,
    fromChainId,
    toChainId,
    fromTokenAddress,
    toTokenAddress,
    amount,
    decimals,
    onNewQuote,
  }: any) {
    if (selectedQuote != null || isCalculateActive) return;
    const body = {
      fromAddress: this.senderAddress,
      fromChain: fromChainId,
      fromToken:
        fromTokenAddress === ethers.constants.AddressZero
          ? addressZero
          : fromTokenAddress,
      fromAmount: ethers.utils.parseUnits(amount, decimals).toString(),
      toChain: toChainId,
      toToken:
        toTokenAddress === ethers.constants.AddressZero
          ? addressZero
          : toTokenAddress,
      toAddress: this.receiverAddress || this.senderAddress,
      slippage: 1,
      slippageConfig: {
        autoMode: 1,
      },
    };

    try {
      const { data } = await axiosInstance.post("/defi/squidrouter", body, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const requestId = data.requestId;
      const route = data.data.route;
      route.estimate.actions
        .filter((action: any) => action.type === "bridge")
        .forEach((action: any) => {
          const swap = async ({
            provider,
            receiver,
            slippageTolerance = 1,
          }: any) => {
            const transactionRequest = route.transactionRequest;

            await this.setAllowance(
              fromTokenAddress,
              transactionRequest.target,
              provider,
              fromChainId,
              ethers.utils.parseUnits(amount, decimals),
              "squidRouter"
            );

            const signer = provider.getSigner();
            const gasPrice = await provider.getGasPrice();

            const tx = await signer.sendTransaction({
              to: transactionRequest.target,
              data: transactionRequest.data,
              value: transactionRequest.value,
              gasPrice: gasPrice,
              gasLimit: transactionRequest.gasLimit,
            });

            updateQueryString(`?hash=${tx.hash}`);

            const txPayload = {
              moduleName: "bridge",
              walletAddress: this.senderAddress,
              route: "squidrouter",
              txHash: tx?.hash,
              txId: requestId,
              from: route.params.fromAddress,
              to: route.params.toAddress,
              value: amount,
              txFrom: {
                chainId: fromChainId,
                tokenName: action.fromToken.symbol,
                value: amount,
              },
              txTo: {
                chainId: toChainId,
                tokenName: action.toToken.symbol,
                value: ethers.utils.formatUnits(
                  action.toAmount,
                  action.toToken.decimals
                ),
              },
              status: "pending",
            };
            await this.saveInitiateTxn(txPayload);
            await tx.wait();

            return tx;
          };

          const payload: any = {
            source: "squidrouter",
            route: action.provider,
            amount: Number(
              ethers.utils.formatUnits(
                route.estimate.toAmount,
                route.estimate.toToken.decimals
              )
            ).toFixed(4),
            usdAmount: 0,
            priceImpact: parseFloat(action.priceImpact),
            slippage: action.slippage || 0,
            networkFee: Number(
              ethers.utils.formatUnits(route.estimate.gasCosts[0].amount)
            ).toFixed(4),
            platformFee: Number(
              ethers.utils.formatUnits(route.estimate.feeCosts[0].amount)
            ).toFixed(4),
            swap,
          };

          onNewQuote(payload);
        });
    } catch (error: any) {
      throw error;
    }
  }

  async getChangellyQuote(params: any) {
    if (params.selectedQuote != null || params.isCalculateActive) return;
    if (!params.fromChangellySymbol || !params.toChangellySymbol) {
      return;
    }
    try {
      const data = await axiosInstance.post("/defi/changelly", {
        from: params.fromChangellySymbol,
        to: params.toChangellySymbol,
        amountFrom: params.amount,
      });
      const result = data.data.data.result;
      if (data.data.data.error) {
        throw new Error(data.data.data.error.message);
      }

      const swap = async ({
        provider,
        receiver,
        slippageTolerance = 0.5,
      }: any) => {
        const signer = provider.getSigner();
        const walletAddress = await signer.getAddress();

        const data = await axiosInstance.post("/defi/changelly/transaction", {
          from: params.fromChangellySymbol,
          to: params.toChangellySymbol,
          amountFrom: params.amount,
          address: receiver || this.senderAddress,
          rateId: result[0].id,
          refundAddress: this?.senderTronNitro
            ? this.tronweb?.address?.fromHex(
                "41" + this.senderTronNitro?.substring(2)
              )
            : this.senderAddress,
        });
        const res = data.data.result;
        const tokenAmount = res.amountExpectedFrom;

        await this.setAllowance(
          params.fromTokenAddress,
          res.payinAddress,
          provider,
          params.fromChainId,
          ethers.utils.parseUnits(tokenAmount, params.decimals),
          "changelly"
        );

        const triggerTokenContract = async () => {
          if (Number(params.fromChainId) === 728126428) {
            const encodedTokenAddress = this.tronweb.address.fromHex(
              "41" + params.fromTokenAddress.substring(2)
            );
            const encodedApprovalAddress = this.tronweb.address.fromHex(
              "41" + res.payinAddress.substring(2)
            );
            const contract = await this.tronweb.contract(
              tokenAbi,
              encodedTokenAddress
            );
            const response = await contract
              .transfer(
                encodedApprovalAddress,
                ethers.utils.parseUnits(tokenAmount, params.decimals)
              )
              .send();
            return { hash: response.txID };
          }
          const tx =
            params.fromTokenAddress === ethers.constants.AddressZero
              ? await signer.sendTransaction({
                  to: res.payinAddress,
                  value: ethers.utils.parseUnits(tokenAmount, params.decimals),
                })
              : await new ethers.Contract(
                  params.fromTokenAddress,
                  tokenAbi,
                  signer
                ).transfer(
                  res.payinAddress,
                  ethers.utils.parseUnits(tokenAmount, params.decimals)
                );
          return tx;
        };
        const { hash } = await triggerTokenContract();

        updateQueryString(`?hash=${hash}`);

        const txPayload = {
          moduleName: "bridge",
          walletAddress: walletAddress,
          route: "changelly",
          txHash: hash,
          txId: res.id,
          txTrackUrl: res.trackUrl,
          from: walletAddress,
          to: res.payinAddress,
          value: res.amountExpectedFrom,
          txFrom: {
            chainId: params.fromChainId,
            tokenName: params.fromTokenName,
            value: res.amountExpectedFrom,
          },
          txTo: {
            chainId: params.toChainId,
            tokenName: params.toTokenName,
            value: res.amountExpectedTo,
          },
          status: "pending",
        };
        console.log(txPayload);
        await this.saveInitiateTxn(txPayload);
        return { hash };
      };

      const payload = {
        source: "changelly",
        route: "changelly",
        amount: Number(result[0].amountTo).toFixed(4),
        usdAmount: 0,
        networkFee: Number(result[0].networkFee).toFixed(4),
        platformFee: 0,
        priceImpact: 0,
        slippage: 0.5,
        swap,
      };

      return payload;
    } catch (error: any) {
      console.log("Changelly ERROR: ", error);
      console.log(error.response);
      throw error;
    }
  }

  async getSwapQuotes({
    fromChainId,
    toChainId,
    fromTokenName,
    toTokenName,
    fromTokenAddress,
    toTokenAddress,
    amount,
    onNewQuote,
    onFirstQuote,
    decimals,
    toTokenDecimals,
    fromTokenSymbol,
    toTokenSymbol,
    fromAddress,
    toAddress,
    gasPrice,
  }: any) {
    let isFirstQuoteEmitted = false;

    const handleQuote = (quote: any) => {
      onNewQuote(quote);

      if (!isFirstQuoteEmitted) {
        onFirstQuote();
        isFirstQuoteEmitted = true;
      }
    };

    if (fromChainId === "167000" || toChainId === "167000") {
      await Promise.all([
        await this.getNitroQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          decimals,
          moduleName: "swap",
        })
          .then(handleQuote)
          .catch(console.error),
      ]);
    } else if (fromChainId === "102" || toChainId === "102") {
      fromChainId = fromChainId === "102" ? "solana" : fromChainId;
      toChainId = toChainId === "102" ? "solana" : toChainId;
      await Promise.all([
        this.getNitroQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          decimals,
          moduleName: "swap",
        })
          .then(handleQuote)
          .catch(console.error),
      ]);
    } else {
      await Promise.all([
        await this.get1InchQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          decimals,
        })
          .then(handleQuote)
          .catch(console.error),
        await this.getLifiQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          onNewQuote,
          onFirstQuote,
          decimals,
          toTokenDecimals,
          fromTokenSymbol,
          toTokenSymbol,
          fromAddress,
          toAddress,
          gasPrice,
        })
          .then((quote) => handleQuote(quote))
          .catch(console.error),
        await this.getSushiSwapQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          onNewQuote,
          onFirstQuote,
          decimals,
          toTokenDecimals,
          fromTokenSymbol,
          toTokenSymbol,
          fromAddress,
          toAddress,
          gasPrice,
        })
          .then((quote) => handleQuote(quote))
          .catch(console.error),
        await this.getKyberSwapQuotes({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          toTokenDecimals,
          amount,
          decimals,
          fromAddress,
          gasPrice,
        })
          .then((quote) => handleQuote(quote))
          .catch(console.error),
        await this.getOpenOceanQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          decimals,
          fromAddress,
          gasPrice,
        })
          .then(handleQuote)
          .catch(console.error),
        await this.getUnizenQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          decimals,
          fromAddress,
          gasPrice,
        })
          .then((quotes) => {
            quotes.forEach((quote: any) => handleQuote(quote));
          })
          .catch(console.error),
        ,
        // await this.getButterNetworkQuotes({
        //   fromChainId,
        //   toChainId,
        //   fromTokenName,
        //   toTokenName,
        //   fromTokenAddress,
        //   toTokenAddress,
        //   amount,
        //   decimals,
        //   fromAddress,
        //   gasPrice,
        //   moduleName: "swap",
        // })
        //   .then(handleQuote)
        //   .catch(console.error),
        await this.getIcreamSwapQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenDecimals,
          toTokenAddress,

          amount,
          decimals,
          fromAddress,
        })
          .then((quote) => handleQuote(quote))
          .catch(console.error),

        await this.getNitroQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          decimals,
          moduleName: "swap",
        })
          .then(handleQuote)
          .catch(console.error),
        this.getSymbiosisQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          fromTokenDecimals: decimals,
          toTokenDecimals,
          fromTokenSymbol,
          toTokenSymbol,
          fromAddress,
          toAddress,
        })
          .then(handleQuote)
          .catch(console.error),
      ]);
    }

    console.log("all completed");
  }

  async getBridgeQuotes({
    isCalculateActive,
    selectedQuote,
    fromChainId,
    toChainId,
    fromTokenName,
    toTokenName,
    fromChangellySymbol,
    toChangellySymbol,
    fromTokenAddress,
    toTokenAddress,
    amount,
    onNewQuote,
    onFirstQuote,
    decimals,
    toTokenDecimals,
    fromTokenSymbol,
    toTokenSymbol,
    fromAddress,
    toAddress,
  }: any) {
    let isFirstQuoteEmitted = false;

    const handleQuote = (quote: any) => {
      onNewQuote(quote);
      if (!isFirstQuoteEmitted) {
        onFirstQuote();
        isFirstQuoteEmitted = true;
      }
    };

    if (
      fromChainId === "167000" ||
      toChainId === "167000" ||
      fromChainId === "728126428" ||
      toChainId === "728126428"
    ) {
      await Promise.all([
        this.getSymbiosisQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          fromTokenDecimals: decimals,
          toTokenDecimals,
          fromTokenSymbol,
          toTokenSymbol,
          fromAddress,
          toAddress,
        })
          .then(handleQuote)
          .catch(console.error),

        this.getNitroQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          decimals,
          moduleName: "bridge",
        })
          .then(handleQuote)
          .catch(console.error),
        await this.getChangellyQuote({
          isCalculateActive,
          selectedQuote,
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromChangellySymbol,
          toChangellySymbol,
          fromTokenAddress,
          toTokenAddress,
          amount,
          decimals,
        })
          .then(handleQuote)
          .catch(console.error),
      ]);
    } else if (fromChainId === "102" || toChainId === "102") {
      await this.getChangellyQuote({
        isCalculateActive,
        selectedQuote,
        fromChainId,
        toChainId,
        fromTokenName,
        toTokenName,
        fromChangellySymbol,
        toChangellySymbol,
        fromTokenAddress,
        toTokenAddress,
        amount,
        decimals,
      })
        .then(handleQuote)
        .catch(console.error);
    } else {
      await Promise.all([
        await this.getLifiQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          onNewQuote,
          onFirstQuote,
          decimals,
          toTokenDecimals,
          fromTokenSymbol,
          toTokenSymbol,
          fromAddress,
          toAddress,
        })
          .then((quote) => handleQuote(quote))
          .catch(console.error),
        await this.getButterNetworkQuotes({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          decimals,
          fromAddress,
          moduleName: "bridge",
        })
          .then((quotes) => {
            quotes.forEach((quote: any) => handleQuote(quote));
          })
          .catch(console.error),

        await this.getNitroQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          decimals,
          moduleName: "bridge",
        })
          .then((quote) => console.log("unizen bridge request ----"))
          .catch((err) => console.log(err)),
        this.getSymbiosisQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          fromTokenDecimals: decimals,
          toTokenDecimals,
          fromTokenSymbol,
          toTokenSymbol,
          fromAddress,
          toAddress,
        })
          .then((quote) =>
            console.log("log::: symbioss ", quote, handleQuote(quote))
          )
          .catch(console.error),

        await this.getSquidRouterQuote({
          isCalculateActive,
          selectedQuote,
          fromChainId: fromChainId,
          fromTokenAddress: fromTokenAddress,
          amount: amount,
          decimals,
          toChainId: toChainId,
          toTokenAddress: toTokenAddress,
          onNewQuote: handleQuote,
        }).catch((error) => {
          console.log(error);
        }),

        await this.getChangellyQuote({
          isCalculateActive,
          selectedQuote,
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromChangellySymbol,
          toChangellySymbol,
          fromTokenAddress,
          toTokenAddress,
          amount,
          decimals,
        })
          .then(handleQuote)
          .catch(console.error),

        await this.getRubicQuote({
          isCalculateActive,
          selectedQuote,
          fromChainId,
          toChainId,
          fromTokenAddress,
          toTokenAddress,
          fromTokenName,
          toTokenName,
          amount,
          onNewQuote: handleQuote,
        }).catch((error) => {
          console.log(error);
        }),
        await this.getUnizenBridgeQuote({
          fromChainId,
          toChainId,
          fromTokenName,
          toTokenName,
          fromTokenAddress,
          toTokenAddress,
          amount,
          decimals,
          fromAddress,
          toTokenDecimals,
        })
          .then((quotes) => {
            quotes.forEach((quote: any) => handleQuote(quote));
          })
          .catch((err) => console.log(err)),
      ]);
    }
    console.log("all completed");
  }

  async setAllowance(
    tokenAddress: string,
    approvalAddress: string,
    provider: any,
    chainId: number,
    amount = ethers.constants.MaxUint256,
    router: string
  ) {
    if (
      tokenAddress === ethers.constants.AddressZero ||
      tokenAddress === addressZero
    ) {
      return;
    }
    if (Number(chainId) === 728126428) {
      try {
        const encodedTokenAddress = this.tronweb.address.fromHex(
          "41" + tokenAddress.substring(2)
        );
        const encodedApprovalAddress =
          router === "nitro"
            ? this.tronweb.address.fromHex("41" + approvalAddress.substring(2))
            : approvalAddress;
        const contract = await this.tronweb.contract(
          tokenAbi,
          encodedTokenAddress
        );
        const allowance = await contract
          .allowance(this.senderTronNitro, encodedApprovalAddress)
          .call();
        if (allowance.lt(amount)) {
          await contract.approve(encodedApprovalAddress, amount).send();
        }
        return;
      } catch (error: any) {
        console.log(error, "error");
        throw error;
      }
    }

    const erc20 = new Contract(tokenAddress, tokenAbi, provider.getSigner());
    const allowance = await erc20.allowance(
      this.senderAddress,
      approvalAddress
    );

    if (allowance.lt(amount)) {
      const approveTx = await erc20.approve(approvalAddress, amount, {
        gasPrice: await provider.getGasPrice(),
      });
      try {
        await approveTx.wait();
        console.log(`Transaction mined successfully: ${approveTx.hash}`);
      } catch (error) {
        console.log(`Transaction failed with error: ${error}`);
      }
    }
  }

  async triggerContract(chainId: number, provider: any, txn: any) {
    try {
      if (Number(chainId) === 728126428) {
        const signedTx = await this.tronweb.trx.sign(txn);
        const { txID } = await this.tronweb.trx.sendRawTransaction(signedTx);

        return {
          walletAddress: this.tronweb.defaultAddress.base58,
          hash: txID,
        };
      }
      const signer = await provider.getSigner();
      const walletAddress = await signer.getAddress();
      const tx = await signer.sendTransaction(txn);
      await tx.wait();
      return {
        walletAddress,
        hash: tx.hash,
      };
    } catch (error) {
      throw error;
    }
  }

  async get1InchSepender(chainId: number) {
    try {
      const { data } = await axiosInstance.post("/defi/1inch/getspender", {
        chain: chainId,
      });
      return data.spender;
    } catch (error) {
      console.log(error, "error");
      throw error;
    }
  }

  async getUnizenSpender(chainId: number) {
    const res = await axios.get(
      this.unizenBaseUrl + `/${chainId}/approval/spender?contractVersion=v1`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_UNIZEN_IO_LINK,
        },
      }
    );

    return res?.data?.address;
  }

  async getQuotesOnramp(onrampQuoteParams: OnrampQuote) {
    try {
      let body = {
        coinCode: onrampQuoteParams.cryptoCurrency?.toLowerCase(),
        network: onrampQuoteParams.networkOnramp,
        amount: onrampQuoteParams.amount,
        fiatType: onrampQuoteParams.fiatType,
        type: onrampQuoteParams.tradeType === TradeType.ONRAMP ? 1 : 2,
      };
      console.log(
        body,
        "BODY",
        !body?.fiatType,
        body?.fiatType,
        body?.network !== null && body?.network !== undefined,
        body?.network
      );
      if (!body?.fiatType) return;
      if (!(body?.network >= 0)) return;
      const {
        data: { data: quote },
      } = await axiosInstance.post("/defi/onramp/quotes", body);
      onrampQuoteParams.onNewQuote({
        source: "OnRamp",
        route: "OnRamp",
        rate: quote.rate,
        amount: quote?.quantity || quote?.fiatAmount,
        fee: calculateFee(quote),
      });
    } catch (error: any) {
      throw error;
    }
  }

  async getQuotesAlchemyPay(alchemyQuoteParams: AlchemyQuote) {
    if (!alchemyQuoteParams?.network) return;
    const data = {
      crypto: alchemyQuoteParams.cryptoCurrency,
      network: alchemyQuoteParams.network,
      fiat: alchemyQuoteParams.fiat,
      amount: alchemyQuoteParams.amount,
      side: alchemyQuoteParams.tradeType === TradeType.ONRAMP ? "BUY" : "SELL",
    };
    try {
      const {
        data: { data: quote },
      } = await axiosInstance.post("/defi/alchemy/quotes", data);
      alchemyQuoteParams.onNewQuote({
        source: "AlchemyPay",
        route: "AlchemyPay",
        rate: data.side === quote.cryptoPrice,
        amount:
          alchemyQuoteParams.tradeType === TradeType.ONRAMP
            ? quote.cryptoQuantity
            : quote.fiatQuantity,
        fee: calculateFee(quote),
      });
    } catch (error) {}
  }

  async getQuotesChangelly(changellyQuoteParams: ChangellyQuote) {
    if (!changellyQuoteParams?.cryptoChangelly) return;
    try {
      const { data: quotes } = await axiosInstance.post(
        "/defi/changelly/quotes",
        changellyQuoteParams
      );
      for (let quote of quotes)
        changellyQuoteParams.onNewQuote({
          source: quote.providerCode,
          route: "Changelly",
          rate: quote.rate,
          amount: quote.amountExpectedTo,
          fee: quote.fee,
        });
    } catch (error) {
      console.log(error);
    }
  }

  async getRampingQuotes(rampingQuotes: RampingQuotes) {
    let isFirstQuoteEmitted = false;
    const handleQuote = (quote: any) => {
      rampingQuotes.onNewQuote(quote);
      if (!isFirstQuoteEmitted) {
        rampingQuotes.onFirstQuote();
        isFirstQuoteEmitted = true;
      }
    };
    const changellyParams = typecast<RampingQuotes, ChangellyQuote>(
      rampingQuotes,
      [
        "amount",
        "fiat",
        "cryptoCurrency",
        "onNewQuote",
        "tradeType",
        "walletAddress",
        "cryptoChangelly",
      ]
    );
    return await Promise.all([
      this.getQuotesAlchemyPay({
        ...rampingQuotes,
        network: rampingQuotes.networkAlchemy,
        onNewQuote: handleQuote,
      }),
      this.getQuotesOnramp({ ...rampingQuotes, onNewQuote: handleQuote }), // TODO: uncomment this when it's fully integrated
      this.getQuotesChangelly(changellyParams),
    ]);
  }

  async getGasPrice(rpcUrl: string) {
    try {
      const data = await axios.post(rpcUrl, {
        jsonrpc: "2.0",
        method: "eth_gasPrice",
        params: [],
        id: 1,
      });

      const gasPriceWei = data.data.result;
      const gasPriceInWei = parseInt(gasPriceWei, 16);
      const gasPriceInGwei = Math.floor(gasPriceInWei / 1e9);

      return gasPriceInGwei;
    } catch (error) {
      console.log("log", error);
    }
  }

  async saveInitiateTxn(payload: any) {
    const res = await axiosInstance.post("/defi/transaction", payload);

    return res.data;
  }
}

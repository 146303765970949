import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";

const SolanaWalletContext = createContext<any>(null);

export const SolanaWalletProvider = ({ children }: any) => {
  const [wallet, setWallet] = useState<any>(null);
  const [connected, setConnected] = useState(false);
  const [address, setAddress] = useState<string | null>(null);

  const getProvider = () => {
    if ("phantom" in window) {
      const provider = (window as any).phantom?.solana;
      if (provider?.isPhantom) {
        return provider;
      }
    }
    return null;
  };

  useEffect(() => {
    const provider = getProvider();
    if (provider) {
      setWallet(provider);
    }
  }, []);

  const connect = async () => {
    if (!wallet) return;
    try {
      const resp = await wallet.request({ method: "connect" });
      setConnected(true);
      setAddress(resp.publicKey.toString());
    } catch (err) {
      console.error("Connection error:", err);
    }
  };

  const disconnect = async () => {
    if (!wallet) return;
    try {
      await wallet.request({ method: "disconnect" });
      setConnected(false);
      setAddress(null);
    } catch (err) {
      console.error("Disconnection error:", err);
    }
  };

  useEffect(() => {
    if (!wallet) return;

    const handleConnect = () => {
      setConnected(true);
      setAddress(wallet.publicKey?.toString() || null);
    };

    const handleDisconnect = () => {
      setConnected(false);
      setAddress(null);
    };

    const handleAccountChanged = (publicKey: any) => {
      if (publicKey) {
        setAddress(publicKey.toBase58());
      } else {
        setConnected(false);
        setAddress(null);
      }
    };

    wallet.on("connect", handleConnect);
    wallet.on("disconnect", handleDisconnect);
    wallet.on("accountChanged", handleAccountChanged);

    return () => {
      wallet.off("connect", handleConnect);
      wallet.off("disconnect", handleDisconnect);
      wallet.off("accountChanged", handleAccountChanged);
    };
  }, [wallet]);

  const value = {
    connect,
    disconnect,
    connected,
    address,
    wallet,
  };

  return (
    <SolanaWalletContext.Provider value={value}>
      {children}
    </SolanaWalletContext.Provider>
  );
};

export const useSolanaWallet = () => {
  const context = useContext(SolanaWalletContext);
  if (!context) {
    throw new Error(
      "useSolanaWallet must be used within a SolanaWalletProvider"
    );
  }
  return context;
};

import { QueryKey } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";

export const fetchHistory = async ({ queryKey }: { queryKey: QueryKey }) => {
  const [_, address, page, limit, moduleName, status, startDate, endDate] =
    queryKey;

  try {
    const params: any = { page, limit, moduleName };
    if (status && status !== "all") {
      params.status = status;
    }

    if (startDate) {
      params.startDate = startDate;
    }

    if (endDate) {
      params.endDate = endDate;
    }

    const res = await axiosInstance.get(
      "/defi/" +
        (moduleName !== "buy" ? "transaction" : "order") +
        `/${address}`,
      {
        params,
      }
    );
    return res.data;
  } catch (error) {
    console.error("log: err", error);
    throw new Error(JSON.stringify(error));
  }
};

export const getGraphPrice = async ({
  fromToken,
  toToken,
  days,
}: {
  fromToken: string;
  toToken: string;
  days?: number;
}) => {
  const resp = await axiosInstance.get(
    `/graph?id=${fromToken}&vs_currency=${toToken}&days=${days}`
  );
  return resp.data;
};

export const getCoinGeckoId = async (chainId: number) => {
  const response = await axiosInstance(`/admin/token/${chainId}`);
  return response?.data?.data?.data;
};

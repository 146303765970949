import React from "react";
import { IoClose } from "react-icons/io5";
import "../loader.css";
interface LoaderProps {
  isLoading: boolean;
  handleCancel: (x: boolean) => void;
  title: string;
  message: string;
  description: string;
  className?: string;
}
const ConfirmationLoader = ({
  isLoading,
  handleCancel,
  title,
  message,
  description,
  className,
}: LoaderProps) => {
  return (
    <>
      {isLoading ? (
        <div
          className={`${className} flex justify-center items-center xs:px-0 px-4`}
        >
          <section
            style={{ backdropFilter: "blur(5px)" }}
            className={`xs:w-[400px] bg-black/50 z-[100] flex flex-col items-center w-full rounded-3xl px-6 pt-8 pb-12 border border-primary`}
          >
            <div className="flex w-full justify-between items-center">
              <h1 className="text-2xl">{title}</h1>
              <div></div>
              <div>
                <IoClose
                  onClick={() => handleCancel(false)}
                  className="text-3xl"
                />
              </div>
            </div>
            <div className="w-full my-10 flex justify-center items-center">
              <span className="loader"></span>
            </div>
            <h1 className="mt-4 font-bold text-base">{message}</h1>
            <p className="mt-3 text-[14px]">{description}</p>
          </section>
        </div>
      ) : null}
    </>
  );
};

export default ConfirmationLoader;

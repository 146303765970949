import React from "react";

const AllProviders = ({
  isActive,
  quotes,
  logoURI,
  usdPrice,
  tokenSymbol,
  handleSelect,
  selectedQuote,
  type,
  isLoading,
  isActiveRecipientAdd,
}: {
  isActive: boolean;
  quotes: any;
  tokenSymbol: string;
  logoURI: string;
  usdPrice: any;
  handleSelect: any;
  selectedQuote: any;
  isLoading?: any;
  type?: "limit" | "swap" | "bridge";
  isActiveRecipientAdd?: boolean;
}) => {
  const loading: boolean = true;

  return (
    <>
      {isActive ? (
        <div
          className={`flex 2xl:mt-4 mt-2 rounded-2xl sm:px-2 py-2 flex-col 2xl:gap-y-4 gap-y-2 ${
            type === "limit"
              ? "2xl:h-[480px] h-[380px]"
              : `
                ${
                  isLoading &&
                  isActiveRecipientAdd &&
                  "2xl:h-[660px] lg:h-[540px] h-[430px]"
                }
                ${
                  isLoading &&
                  !isActiveRecipientAdd &&
                  "2xl:h-[560px] xl:h-[450px] h-[420px]"
                }
                ${
                  !isLoading &&
                  isActiveRecipientAdd &&
                  "2xl:h-[600px] h-[480px]"
                }
                ${
                  !isLoading &&
                  !isActiveRecipientAdd &&
                  "2xl:h-[600px] h-[480px]"
                }

              `
          } overflow-y-auto w-full`}
        >
          {/* `${
                  isLoading
                    ? "2xl:h-[560px] h-[440px]"
                    : "2xl:h-[600px] h-[480px]"
                }` */}
          {quotes?.map((quote: any, index: number) => {
            return (
              <div
                onClick={() => handleSelect(quote)}
                className={`border hover:opacity-70 transition-all duration-200 ease cursor-pointer ${
                  selectedQuote?.amount === quote?.amount &&
                  selectedQuote.route === quote?.route &&
                  selectedQuote.source === quote?.source
                    ? "border-primary"
                    : "border-[#555]"
                }  rounded-2xl 2xl:py-5 py-3 rounded-3xl  w-full`}
              >
                <div
                  className={`flex w-full border-b  ${
                    selectedQuote?.amount === quote?.amount &&
                    selectedQuote.route === quote?.route &&
                    selectedQuote.source === quote?.source
                      ? "border-b-primary"
                      : "border-b-[#555]"
                  } px-5 py-1 justify-between items-center`}
                >
                  <h1>
                    {quote.route}({quote.source})
                  </h1>
                  <div className="flex items-center gap-x-1">
                    <span>
                      {quote.amount} {tokenSymbol}
                    </span>
                    <img
                      src={logoURI}
                      className="h-5 w-5 rounded-full"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-full flex px-5 pt-1 justify-between items-center">
                  <span>{usdPrice}~$</span>
                  {index === 0 ? (
                    <span className="bg-success px-2 rounded-md text-[14px]">
                      Best
                    </span>
                  ) : (
                    <span>{index + 1}</span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default AllProviders;

import React, { createContext, useContext, useMemo, ReactNode } from "react";
import {
  useWallet,
  WalletProvider as TronWalletAdapterProvider,
} from "@tronweb3/tronwallet-adapter-react-hooks";
import { ChainNetwork } from "@tronweb3/tronwallet-abstract-adapter";
import {
  TronLinkAdapter,
  WalletConnectAdapter,
} from "@tronweb3/tronwallet-adapters";

export const TronWalletContext = createContext<any>(null);

export const TronWalletProvider = ({ children }: any) => {
  const {
    connect,
    disconnect,
    select,
    connected,
    address,
    wallet,
    signMessage,
    signTransaction,
  } = useWallet();

  const adapters = useMemo(() => [new TronLinkAdapter()], []);

  const config = {
    network: ChainNetwork.Mainnet,
    options: {
      relayUrl: "wss://relay.walletconnect.com",
      projectId: "cf0545f2b1063ac84b6804ee8995cc74",
      metadata: {
        name: "Blazpay",
        description: "Blazpay",
        url: "https://blazpay.com",
        icons: ["https://blazpay.com/blazpoint.png"],
      },
    },
    web3ModalConfig: {
      explorerRecommendedWalletIds: [
        "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
        "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
      ],
    },
  };

  return (
    <TronWalletContext.Provider
      value={{
        connect,
        disconnect,
        select,
        connected,
        address,
        wallet,
        signMessage,
        signTransaction,
      }}
    >
      {children}
    </TronWalletContext.Provider>
  );
};

export const useTronWallet = () => {
  const context = useContext(TronWalletContext);
  if (!context) {
    throw new Error("useTronWallet must be used within a TronWalletProvider");
  }
  return context;
};

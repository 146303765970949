import { Currency, ETHER, Token } from 'blazpay-sdk'
import { zeroAddress } from 'viem'

export function currencyId(currency: Currency): string {
  if (currency === ETHER) return zeroAddress
  if (currency instanceof Token) return currency.address
  throw new Error('invalid currency')
}

export default currencyId

import { useState, useMemo, useCallback, useEffect } from "react";
import { IoIosArrowBack, IoIosSettings } from "react-icons/io";
import { RemoveSvgWrapper, WrapperSvg } from "./SvgContainer";
import Settings from "./Settings";
import "./style.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import TransactionModal from "./TransactionModal";
import RemoveLiquidityModal from "./RemoveLiquidityModal";
import { useCurrency, useTokenLogoURI } from "../../hooks/Tokens";
import { useWallet } from "../../hooks/useWallet";
import { wrappedCurrency } from "../../utils/wrappedCurrency";
import {
  useUpdateActionHandlers,
  useBurnActionHandlers,
  useDerivedBurnInfo,
  useBurnState,
  SignatureDataType,
  DEFAULT_TIMELINE,
} from "../../state/burn/hooks";
import {
  useUserSlippageTolerance,
  useUserDeadline,
  usePairAdder,
} from "../../state/user/hooks";
import { Field } from "../../state/burn/actions";
import { Contract } from "@ethersproject/contracts";
import {
  Percent,
  ChainId,
  ETHER,
  currencyEquals,
  WETH,
  Currency,
} from "blazpay-sdk";
import {
  useApproveCallback,
  ApprovalState,
} from "../../hooks/useApproveCallback";
import { usePairContract } from "../../hooks/useContract";
import {
  ROUTER_ADDRESS,
  NATIVE_SYMBOLS,
  LIQ,
  TRANSFER_FILTER_ID,
} from "../../constants";
import { splitSignature } from "@ethersproject/bytes";
import { useTransactionAdder } from "../../state/transactions/hooks";
import {
  calculateGasMargin,
  calculateSlippageAmount,
  getContract,
  getRouterContract,
} from "../../utils";
import { BigNumber } from "@ethersproject/bignumber";
import { TransactionResponse } from "@ethersproject/providers";
import CurrencyLogo from "../CurrencyLogo";
import useDebouncedChangeHandler from "../../utils/useDebouncedChangeHandler";
import { currencyId } from "../../utils/currencyId";
import { zeroAddress } from "viem";
import LoadingButton from "../LoadingButton";
import { Dots } from "../Card";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import axiosInstance from "../../utils/axios";
import IBlazPayFactortyAbi from "../../constants/factory/abi.json";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../utils/contractErrorHandler";

export default function RemoveLiquidity() {
  const [isSettingsActive, setIsSettingsActive] = useState<boolean>(false);
  const [isRemoveActive, setIsRemoveActive] = useState<boolean>(false);
  const [range, setRange] = useState<number | string>(25);
  const [amount, setAmount] = useState<string>("");
  const [isActiveInput, setIsActiveInput] = useState<boolean>(false);
  const { open } = useWeb3Modal();
  const ranges = [25, 50, 75, "Max"];
  const navigate = useNavigate();
  const { currencyIdA, currencyIdB } = useParams();
  const [currencyA, currencyB] = [
    useCurrency(currencyIdA) ?? undefined,
    useCurrency(currencyIdB) ?? undefined,
  ];
  const { address: account, provider: library, chainId } = useWallet();
  const loadedChainId: ChainId = useMemo(() => chainId as ChainId, [chainId]);

  const [tokenA, tokenB] = useMemo(
    () => [
      wrappedCurrency(currencyA, chainId),
      wrappedCurrency(currencyB, chainId),
    ],
    [currencyA, currencyB, chainId]
  );

  // burn state
  const { independentField, typedValue, status, router, nonces } =
    useBurnState();
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(
    currencyA ?? undefined,
    currencyB ?? undefined
  );
  const { onUserInput: _onUserInput } = useBurnActionHandlers();
  const { onUpdateStatus: _onUpdateStatus } = useUpdateActionHandlers();

  const isValid = !error;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showDetailed, setShowDetailed] = useState<boolean>(false);
  const [attemptingTxn, setAttemptingTxn] = useState(false); // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>("");
  const [deadline] = useUserDeadline();
  const [allowedSlippage] = useUserSlippageTolerance();

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo(
      "0"
    )
      ? "0"
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent("1", "100"))
      ? "<1"
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY
        ? typedValue
        : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? "",
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A
        ? typedValue
        : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? "",
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B
        ? typedValue
        : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? "",
  };

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(
    new Percent("1")
  );

  // pair contract
  const pairContract: Contract | null = usePairContract(
    pair?.liquidityToken?.address
  );

  // allowance handling
  const [signatureData, setSignatureData] = useState<{
    v: number;
    r: string;
    s: string;
    deadline: number;
  } | null>(null);
  const [approval, approveCallback] = useApproveCallback(
    parsedAmounts[Field.LIQUIDITY],
    ROUTER_ADDRESS[loadedChainId]
  );
  async function onAttemptToApprove() {
    if (!pairContract || !pair || !library)
      throw new Error("missing dependencies");
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
    if (!liquidityAmount) throw new Error("missing liquidity amount");
    // try to gather a signature for permission
    const nonce = await pairContract.nonces();

    const deadlineForSignature: number = !status
      ? DEFAULT_TIMELINE
      : Math.ceil(Date.now() / 1000) + deadline;

    const RouterAddress = !status ? router : ROUTER_ADDRESS[loadedChainId];
    const pairNonce = !status ? nonces.toString(16) : nonce.toHexString();
    const liquidityValue = !status
      ? LIQ.toString()
      : liquidityAmount.raw.toString();

    const EIP712Domain = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ];
    const domain = {
      name: "Blazpay LPs",
      version: "1",
      chainId,
      verifyingContract: pair.liquidityToken.address,
    };
    const Permit = [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" },
      { name: "value", type: "uint256" },
      { name: "nonce", type: "uint256" },
      { name: "deadline", type: "uint256" },
    ];
    const message = {
      owner: account,
      spender: RouterAddress,
      value: liquidityValue,
      nonce: pairNonce,
      deadline: deadlineForSignature,
    };
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit,
      },
      domain,
      primaryType: "Permit",
      message,
    });
    library
      .send("eth_signTypedData_v4", [account, data])
      .then(splitSignature)
      .then((signature) => {
        if (!status) {
          _onUpdateStatus(account, signature as SignatureDataType);
          pairContract.nonces(account);
        } else {
          setSignatureData({
            v: signature.v,
            r: signature.r,
            s: signature.s,
            deadline: deadlineForSignature,
          });
        }
      })
      .catch((e) => {
        // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
        const message = getErrorMessage(e)
        toast.error(message)
      });
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, val: string) => {
      setSignatureData(null);
      return _onUserInput(field, val);
    },
    [_onUserInput]
  );

  const onLiquidityInput = useCallback(
    (val: string): void => onUserInput(Field.LIQUIDITY, val),
    [onUserInput]
  );
  const onCurrencyAInput = useCallback(
    (val: string): void => onUserInput(Field.CURRENCY_A, val),
    [onUserInput]
  );
  const onCurrencyBInput = useCallback(
    (val: string): void => onUserInput(Field.CURRENCY_B, val),
    [onUserInput]
  );

  // tx sending
  const addTransaction = useTransactionAdder();
  async function onRemove() {
    if (!chainId || !library || !account)
      throw new Error("missing dependencies");
    const {
      [Field.CURRENCY_A]: currencyAmountA,
      [Field.CURRENCY_B]: currencyAmountB,
    } = parsedAmounts;
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error("missing currency amounts");
    }
    const router = getRouterContract(chainId, library, account);
    const factoryAddress = await router.factory();
    const factory = getContract(
      factoryAddress,
      IBlazPayFactortyAbi,
      library,
      account
    );

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(
        currencyAmountA,
        allowedSlippage
      )[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(
        currencyAmountB,
        allowedSlippage
      )[0],
    };

    if (!currencyA || !currencyB) throw new Error("missing tokens");
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
    if (!liquidityAmount) throw new Error("missing liquidity amount");

    const currencyBIsETH = currencyB === ETHER;
    const oneCurrencyIsETH = currencyA === ETHER || currencyBIsETH;
    const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline;

    if (!tokenA || !tokenB) throw new Error("could not wrap");

    let methodNames: string[];
    let args: Array<string | string[] | number | boolean | SignatureDataType>;
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityETH
      if (oneCurrencyIsETH) {
        methodNames = [
          "removeLiquidityETH",
          "removeLiquidityETHSupportingFeeOnTransferTokens",
        ];
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[
            currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B
          ].toString(),
          amountsMin[
            currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A
          ].toString(),
          account,
          deadlineFromNow,
        ];
      }
      // removeLiquidity
      else {
        methodNames = ["removeLiquidity"];
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          deadlineFromNow,
        ];
      }
    }
    // we have a signataure, use permit versions of remove liquidity
    else if (signatureData !== null) {
      const signature: SignatureDataType = {
        v: signatureData.v,
        r: signatureData.r,
        s: signatureData.s,
      };
      // removeLiquidityETHWithPermit
      if (oneCurrencyIsETH) {
        methodNames = [
          "removeLiquidityETHWithPermit",
          "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens",
        ];
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[
            currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B
          ].toString(),
          amountsMin[
            currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A
          ].toString(),
          account,
          account,
          signatureData.deadline,
          false,
          signature,
        ];
      }
      // removeLiquidityETHWithPermit
      else {
        methodNames = ["removeLiquidityWithPermit"];
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          account,
          signatureData.deadline,
          false,
          signature,
        ];
      }
    } else {
      throw new Error(
        "Attempting to confirm without approval or a signature. Please contact support."
      );
    }
    const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
      methodNames.map((methodName, index) =>
        router.estimateGas[methodName](...args)
          .then(calculateGasMargin)
          .catch((e) => {
            const message = getErrorMessage(e)
            toast.error(message)
            return undefined;
          })
      )
    );

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex(
      (safeGasEstimate) => BigNumber.isBigNumber(safeGasEstimate)
    );

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      return
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation];
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation];

      setAttemptingTxn(true);
      await router[methodName](...args, {
        gasLimit: safeGasEstimate,
      })
        .then(async (response: TransactionResponse) => {
          const { logs } = await response.wait();
          setAttemptingTxn(false);
          setTxHash(response.hash);
          const token0 = wrappedCurrency(currencyA, chainId);
          const token1 = wrappedCurrency(currencyB, chainId);
          const pair = await factory.getPair(token0?.address, token1?.address);
          const liquidityTransferLog = logs.filter(
            (log) =>
              log.address === pair && log.topics[0] === TRANSFER_FILTER_ID
          );
          addTransaction(response, {
            summary: `Remove ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(
              3
            )} ${
              currencyA === ETHER
                ? NATIVE_SYMBOLS[loadedChainId]
                : currencyA?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${
              currencyB === ETHER
                ? NATIVE_SYMBOLS[loadedChainId]
                : currencyB?.symbol
            }`,
          });

          setTxHash(response.hash);
          const data: any = {
            moduleName: "remove",
            walletAddress: account,
            pair: pair,
            txHash: response?.hash,
            to: router.address,
            tokenOne: {
              address: tokenA?.address,
              amount:
                parseInt(currencyAmountA.raw.toString()) /
                Math.pow(10, tokenA?.decimals ? tokenA.decimals : 18),
            },
            tokenTwo: {
              address: tokenB?.address,
              amount:
                parseInt(currencyAmountA.raw.toString()) /
                Math.pow(10, tokenB?.decimals ? tokenB.decimals : 18),
            },
            status: "success",
            value: Number(ethers.BigNumber.from(liquidityTransferLog[0].data)),
          };
          await axiosInstance.post("/liquidity/transaction", { data });
        })
        .catch((e: Error) => {
          setAttemptingTxn(false);
          console.log(e, e?.message);
          // we only care if the error is something _other_ than the user rejected the tx
          // console.error(e);
        });
    }
  }

  const pendingText = `Removing ${parsedAmounts[
    Field.CURRENCY_A
  ]?.toSignificant(6)} ${
    currencyA === ETHER ? NATIVE_SYMBOLS[loadedChainId] : currencyA?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${
    currencyB === ETHER ? NATIVE_SYMBOLS[loadedChainId] : currencyB?.symbol
  }`;

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString());
    },
    [onUserInput]
  );

  const oneCurrencyIsETH = currencyA === ETHER || currencyB === ETHER;
  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyEquals(WETH[loadedChainId], currencyA)) ||
        (currencyB && currencyEquals(WETH[loadedChainId], currencyB)))
  );

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        navigate(
          `/defi/liquidity/remove/${currencyId(currency)}/${currencyIdA}`
        );
      } else {
        navigate(
          `/defi/liquidity/remove/${currencyId(currency)}/${currencyIdB}`
        );
      }
    },
    [currencyIdA, currencyIdB, navigate]
  );
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        navigate(
          `/defi/liquidity/remove/${currencyIdB}/${currencyId(currency)}`
        );
      } else {
        navigate(
          `/defi/liquidity/remove/${currencyIdA}/${currencyId(currency)}`
        );
      }
    },
    [currencyIdA, currencyIdB, navigate]
  );

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false);

    setSignatureData(null); // important that we clear signature data to avoid bad sigs
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, "0");
      setRange(0);
    }
    setTxHash("");
  }, [onUserInput, txHash]);

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] =
    useDebouncedChangeHandler(
      Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
      liquidityPercentChangeCallback
    );

  const changeCallback = useCallback(
    (e: any) => {
      setInnerLiquidityPercentage(parseInt(e.target.value));
    },
    [setInnerLiquidityPercentage]
  );
  useEffect(() => {
    onUserInput(Field.LIQUIDITY_PERCENT, "25");
  }, []);

  return (
    <div className="relative pt-44 w-full swap-container text-white flex justify-center px-5">
      <div className="flex flex-col xs:w-[480px]  w-full rounded-3xl border border-primary xs:px-6 px-4 py-6 relative">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-x-2">
            <Link to="/defi/liquidity">
              <IoIosArrowBack className="xs:text-3xl text-2xl hover:text-[#777] cursor-pointer" />
            </Link>
            <h1 className="font-bold xs:text-2xl text-xl">Remove Liquiditiy</h1>
          </div>
        </div>

        <div className="flex flex-col w-full items-start">
          <h1 className="xs:text-xl text-base font-bold mt-4">
            Amount of liquidity to remove
          </h1>
          <section className="w-full relative xs:mt-4 mt-2 h-[120px]">
            <div className="w-full h-full absolute top-0 left-0 pt-3 z-20 flex flex-col items-center">
              <h1 className="xs:text-3xl text-2xl font-bold">{range}%</h1>
              <div className="w-full px-8">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={range}
                  onChange={(e) => {
                    setRange(e.target.value);
                    changeCallback(e);
                  }}
                />
              </div>
              <div className="flex w-full xs:px-[52px] px-8 justify-between items-center mt-3">
                {ranges.map((value, index) => {
                  return (
                    <button
                      onClick={() => {
                        if (value === "Max") {
                          setRange(100);
                          onUserInput(Field.LIQUIDITY_PERCENT, "100");
                        } else {
                          setRange(value);
                          onUserInput(
                            Field.LIQUIDITY_PERCENT,
                            value.toString()
                          );
                        }
                      }}
                      key={index}
                      className="cursor-pointer px-4 py-1 rounded-full bg-transparent xs:text-xs text-[10px] border border-white text-white font-bold"
                    >
                      {Number(value) ? (
                        <>
                          <span>{value}</span>%
                        </>
                      ) : (
                        <>
                          <span>{value}</span>
                        </>
                      )}
                    </button>
                  );
                })}
              </div>
            </div>
            <WrapperSvg className="w-full z-0 absolute xs:scale-y-100 scale-y-125" />
          </section>
          <section className="xs:h-[180px] border-4 h-[160px] w-full relative mt-6">
            <div className="absolute h-full w-full z-20 top-0 left-0 flex flex-col px-8 xs:py-12 pt-9">
              <div className="flex justify-between items-center">
                <h1>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</h1>
                <div className="flex items-center gap-x-2">
                  <CurrencyLogo currency={currencyA} size="24px" />
                  <p className="uppercase text-xl font-bold ">
                    {currencyA === ETHER
                      ? NATIVE_SYMBOLS[loadedChainId]
                      : currencyA?.symbol}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center xs:mt-4 mt-2">
                <h1>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</h1>
                <div className="flex items-center gap-x-2">
                  <CurrencyLogo currency={currencyB} size="24px" />
                  <p className="uppercase text-xl font-bold ">
                    {currencyB === ETHER
                      ? NATIVE_SYMBOLS[loadedChainId]
                      : currencyB?.symbol}
                  </p>
                </div>
              </div>
              <div className="flex justify-end mt-4">
                {chainId && (oneCurrencyIsWETH || oneCurrencyIsETH) ? (
                  <>
                    {oneCurrencyIsETH ? (
                      <button
                        onClick={() => {
                          navigate(
                            `/defi/liquidity/remove/${
                              currencyA === ETHER
                                ? WETH[loadedChainId].address
                                : currencyIdA
                            }/${
                              currencyB === ETHER
                                ? WETH[loadedChainId].address
                                : currencyIdB
                            }`
                          );
                        }}
                        className="text-xs font-bold bg-transparent text-[#ff3503]"
                      >
                        Receive Wrapped
                      </button>
                    ) : oneCurrencyIsWETH ? (
                      <button
                        onClick={() => {
                          navigate(
                            `/defi/liquidity/remove/${
                              currencyA &&
                              currencyEquals(currencyA, WETH[loadedChainId])
                                ? zeroAddress
                                : currencyIdA
                            }/${
                              currencyB &&
                              currencyEquals(currencyB, WETH[loadedChainId])
                                ? zeroAddress
                                : currencyIdB
                            }`
                          );
                        }}
                        className="text-xs font-bold bg-transparent text-[#ff3503]"
                      >
                        Receive Native
                      </button>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
            <RemoveSvgWrapper className="absolute h-full w-full z-0 " />
          </section>

          <div className="flex flex-col w-full justify-start xs:px-6 mt-4">
            <div className="flex justify-between w-full items-start text-[14px] my-3">
              <p className="">Price:</p>
              <div className="flex flex-col items-end">
                <p>
                  1{" "}
                  {currencyA === ETHER
                    ? NATIVE_SYMBOLS[loadedChainId]
                    : currencyA?.symbol}{" "}
                  = {tokenA ? pair?.priceOf(tokenA).toSignificant(6) : "-"}{" "}
                  {currencyB === ETHER
                    ? NATIVE_SYMBOLS[loadedChainId]
                    : currencyB?.symbol}
                </p>
                <p>
                  1{" "}
                  {currencyB === ETHER
                    ? NATIVE_SYMBOLS[loadedChainId]
                    : currencyB?.symbol}{" "}
                  = {tokenB ? pair?.priceOf(tokenB).toSignificant(6) : "-"}{" "}
                  {currencyA === ETHER
                    ? NATIVE_SYMBOLS[loadedChainId]
                    : currencyA?.symbol}
                </p>
              </div>
            </div>

            <div className="flex justify-between items-center w-full mt-4">
              {!account ? (
                <LoadingButton
                  onClick={() => open({ view: "Connect" })}
                  className="bg-gradient-to-r from-primary to-secondary text-white font-bold text-center w-full mx-auto block h-12 rounded-full"
                >
                  Connect Wallet
                </LoadingButton>
              ) : (
                <>
                  <LoadingButton
                    className="bg-gradient-to-r from-primary to-secondary text-white font-bold text-center block h-12 rounded-full"
                    onClick={onAttemptToApprove}
                    disabled={
                      approval !== ApprovalState.NOT_APPROVED ||
                      signatureData !== null
                    }
                    style={{ width: "120px" }}
                  >
                    {approval === ApprovalState.PENDING ? (
                      <Dots>Approving</Dots>
                    ) : approval === ApprovalState.APPROVED ||
                      signatureData !== null ? (
                      "Approved"
                    ) : (
                      "Approve"
                    )}
                  </LoadingButton>
                  <LoadingButton
                    className="bg-gradient-to-r from-primary to-secondary text-white font-bold text-center block h-12 rounded-full"
                    onClick={() => {
                      setShowConfirm(true);
                    }}
                    disabled={
                      !isValid ||
                      (signatureData === null &&
                        approval !== ApprovalState.APPROVED)
                    }
                    style={{ width: "120px" }}
                  >
                    {error || "Remove"}
                  </LoadingButton>
                </>
              )}
              {/* <button
                className="hover:opacity-75 xs:text-base text-[14px] rounded-full bg-gradient-to-r from-primary to-secondary text-white font-bold outline-none border-none py-2 xs:px-12 px-7 "
                onClick={() => {}}
              >
                Approve
              </button>

              <button
                onClick={() => {
                  if (isActiveInput) {
                    setIsRemoveActive(true);
                  } else {
                    setIsActiveInput(true);
                  }
                }}
                className="hover:opacity-75 xs:text-base text-[14px] rounded-full bg-gradient-to-r from-primary to-secondary text-white font-bold outline-none border-none py-2 xs:px-12 px-7 "
              >
                {isActiveInput && amount !== "" ? "Confirm" : "Enter amount"}
              </button> */}
            </div>
          </div>
          {/* <img
            src="/assets/Vector.png"
            className="absolute top-0 left-0 h-full w-full -z-10 "
          /> */}
        </div>
        {/* <div className="relative w-full mt-4 py-4 px-2">
          <div>
            <div className="mb-[10px] ml-[50px] mr-[30px] mt-[20px]">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img src="/bnb.svg" className="h-6 w-6 rounded-full" alt="" />
                  <p className="text-[14px]">ETH</p>
                </div>
                <div className="text-[14px]">2.652</div>
              </div>
              <div className="text-end text-gray-700 text-[10px]">$-58.55</div>
            </div>
            <div className="ml-[50px] mr-[30px]">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img src="/bnb.svg" className="h-6 w-6 rounded-full" alt="" />
                  <p className="text-[14px]">ETH</p>
                </div>
                <div className="text-[14px]">2.652</div>
              </div>
              <div className="text-end text-gray-700 text-[10px]">$-58.55</div>
            </div>

            <div className="mb-[10px] ml-[50px] mr-[30px] mt-[20px]">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img src="/bnb.svg" className="h-6 w-6 rounded-full" alt="" />
                  <p className="text-[14px]">ETH</p>
                </div>
                <div className="text-[14px]">2.652</div>
              </div>
              <div className="text-end text-gray-700 text-[10px]">$-58.55</div>
            </div>
            <div className="ml-[50px] mr-[30px]">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img src="/bnb.svg" className="h-6 w-6 rounded-full" alt="" />
                  <p className="text-[14px]">ETH</p>
                </div>
                <div className="text-[14px]">2.652</div>
              </div>
              <div className="text-end text-gray-700 text-[10px]">$-58.55</div>
            </div>
          </div>

          <img
            src="/assets/Vector.png"
            className="absolute top-0 left-0 h-full w-full -z-10 "
          />
        </div> */}
        <RemoveLiquidityModal
          className="absolute top-0 right-0"
          isActive={showConfirm}
          onDismiss={handleDismissConfirmation}
          attemptingTxn={attemptingTxn}
          hash={txHash || ""}
          pendingText={pendingText}
          currencyA={currencyA}
          currencyB={currencyB}
          pair={pair}
          parsedAmounts={parsedAmounts}
          disabled={
            !(approval === ApprovalState.APPROVED || signatureData !== null)
          }
          onRemove={onRemove}
          priceA={`1 ${
            currencyA === ETHER
              ? NATIVE_SYMBOLS[loadedChainId]
              : currencyA?.symbol
          } = ${tokenA ? pair?.priceOf(tokenA).toSignificant(6) : "-"} ${
            currencyB === ETHER
              ? NATIVE_SYMBOLS[loadedChainId]
              : currencyB?.symbol
          }`}
          priceB={`1 ${
            currencyB === ETHER
              ? NATIVE_SYMBOLS[loadedChainId]
              : currencyB?.symbol
          } = ${tokenB ? pair?.priceOf(tokenB).toSignificant(6) : "-"} ${
            currencyA === ETHER
              ? NATIVE_SYMBOLS[loadedChainId]
              : currencyA?.symbol
          }`}
        />
      </div>
    </div>
  );
}

import { createReducer } from '@reduxjs/toolkit'
import { Field, typeInput, updateStatus } from './actions'
import { zeroAddress } from 'viem'

export interface BurnState {
  readonly independentField: Field
  readonly typedValue: string
  readonly status: number
  readonly router: string
  readonly nonces: number
}
const initialState: BurnState = {
  independentField: Field.LIQUIDITY_PERCENT,
  typedValue: '0',
  status: 2,
  router: zeroAddress,
  nonces: 0
}

export default createReducer<BurnState>(initialState, builder =>
  builder
  .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
    return {
      ...state,
      independentField: field,
      typedValue
    }
  })
  .addCase(updateStatus, (state, { payload: { status, router, nonces } }) => {
    return {
      ...state,
      status: status,
      router: router,
      nonces: nonces
    }
  })
)

import { nanoid } from '@reduxjs/toolkit'
import { ChainId } from 'blazpay-sdk'
import { TokenList } from '@uniswap/token-lists'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../state'
import { fetchTokenList } from '../state/lists/actions'
import { useWallet } from "./useWallet";
import { getTokens } from "../apis/chain.api"

export function useFetchListCallback(): (listUrl: string) => Promise<TokenList | []> {
  const { chainId, provider: library } = useWallet()
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    async (listUrl: string) => {
      if (!chainId) return []
      const requestId = nanoid()
      dispatch(fetchTokenList.pending({ requestId, url: listUrl }))
      if (!chainId) {
        // Return a rejected promise if chainId is falsy
        return Promise.reject(new Error("Chain ID is not defined"));
    }
      return getTokens(chainId)
        .then((tokenList) => {
          const wrappedTokenlist: TokenList = {
            tokens: tokenList,
            name: 'My Token List',
            timestamp: new Date().toISOString(),
            version: { major: 0, minor: 0, patch: 0 }
          }
          dispatch(fetchTokenList.fulfilled({ url: listUrl, tokenList: wrappedTokenlist, requestId }))
          return wrappedTokenlist
        })
        .catch((error) => {
          console.error(`Failed to get list at url ${listUrl}`, error)
          dispatch(fetchTokenList.rejected({ url: listUrl, requestId, errorMessage: error.message }))
          throw error
        })
    },
    [dispatch, chainId]
  )
}

export default useFetchListCallback

import { errors } from 'ethers';
import { toast } from 'react-toastify';
export const chains = [
  // {
  //   id: '8082',
  //   name: '	Shardeum Sphinx 1.X',
  //   nativeCurrency: {
  //     name: 'SHM',
  //     symbol: 'SHM',
  //     decimals: 18,
  //   },
  //   rpcUrls: ['https://sphinx.shardeum.org/'],
  //   explorerUrl: ['https://explorer-sphinx.shardeum.org/'],
  //   tokenImg: 'https://i.ibb.co/y5D8dJv/taiko.png',
  //   features: {
  //     spinValue: 0,
  //     mintValue: 0,
  //     stakeValue: 0,
  //     apiStaking: false,
  //     gasLimit:true,
  //   },
  //   contracts: {
  //     // Entrypass: new ethers.Contract(''),
  //     NFT: new ethers.Contract(
  //       '0x97E3aB56ec2C13ACA2993076523F5b3D10D4A138',
  //       zkfairStakeAbi
  //     ),
  //     Stake: new ethers.Contract(
  //       '0x97E3aB56ec2C13ACA2993076523F5b3D10D4A138',
  //       zkfairStakeAbi
  //     ),
  //     Token: new ethers.Contract(
  //       '0x427539B9f81cd890b554e4f3F36eBa6D6d96e61a',
  //       tokenAbi
  //     ),
  //     Game: new ethers.Contract(
  //       '0x60d078234bCDd1c86C5020D4a64A2213568765a4',
  //       mantaGameAbi
  //     ),
  //     Product: new ethers.Contract(
  //       '0x56345f409b17CE3943F521f9Ef9f0150435c43b4',
  //       katlaProductAbi
  //     ),
  //   },
  // },
  {
    id: '167009',
    name: 'Taiko Hekla L2',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://rpc.hekla.taiko.xyz'],
    tokenImg: 'https://i.ibb.co/y5D8dJv/taiko.png',
    explorerUrl: 'https://explorer.hekla.taiko.xyz/',
    features: {
      spinValue: 0,
      mintValue: 0,
      stakeValue: 0,
      apiStaking: false,
      gasLimit: false,
    },
  },
  // {
  //   id: "167008",
  //   name: "Katla L2",
  //   nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
  //   rpcUrls: ["https://rpc.katla.taiko.xyz"],
  //   tokenImg: "https://i.ibb.co/y5D8dJv/taiko.png",
  //   explorerUrl: "https://explorer.katla.taiko.xyz/",
  //   id: "167008",
  //   name: "Taiko Katla L2",
  //   nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
  //   rpcUrls: ["https://rpc.katla.taiko.xyz"],
  //   tokenImg: "https://i.ibb.co/y5D8dJv/taiko.png",
  //   explorerUrl: "https://explorer.katla.taiko.xyz/",
  //   features: {
  //     spinValue: 0,
  //     mintValue: 0,
  //     stakeValue: 0,
  //     apiStaking: true,
  //     gasLimit: false,
  //   },
  //   contracts: {
  //     // Entrypass: new ethers.Contract(''),
  //     NFT: new ethers.Contract(
  //       "0xedd0dDaEdbc3FBf67aC4ff2ee14Ace669821eac1",
  //       katlaNFTAbi
  //     ),
  //     Stake: new ethers.Contract(
  //       "0x1670080000000000000000000000000000000003",
  //       []
  //     ),
  //     Token: new ethers.Contract(
  //       "0x14027051bB9fEDbb8D8559416b056C2CBf3aA238",
  //       tokenAbi
  //     ),
  //     Game: new ethers.Contract(
  //       "0x1A0ECe8a973Be50bd44842960D842dc35e3E83A4",
  //       katlaGameAbi
  //     ),
  //     Product: new ethers.Contract(
  //       "0xa3B919018c23f3F19A9CAb00C243245598666F87",
  //       katlaProductAbi
  //     ),
  //   },
  // },
  {
    id: '169',
    name: 'Manta Pacific Mainnet',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://pacific-rpc.manta.network/http'],
    explorerUrl: 'https://pacific-explorer.manta.network/',
    tokenImg: 'https://i.ibb.co/CPzhyTf/manta.png',
    features: {
      spinValue: 0.000003,
      mintValue: 0.00007,
      stakeValue: 0,
      apiStaking: false,
      gasLimit: false,
    },
  },
  {
    id: '17000',
    name: 'Holesky',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://ethereum-holesky-rpc.publicnode.com'],
    explorerUrl: 'https://holesky.etherscan.io/',
    tokenImg: 'https://i.ibb.co/tHbx9Jw/arbitrum.png',
    features: {
      spinValue: 0,
      mintValue: 0,
      stakeValue: 0,
      unStakeValue: 0,
      apiStaking: false,
      gasLimit: false,
    },
  },
  {
    id: '137',
    name: 'Polygon Mainnet',
    rpcUrls: ['https://polygon-rpc.com/'],
    chainHex: '0x89',
    currency: 'MATIC',
    explorerUrl: 'https://polygonscan.com/',
    tokenImg: 'https://i.ibb.co/MDKTCF6/polygon.png',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    features: {
      spinValue: 0.01,
      mintValue: 0.5,
      stakeValue: 0.5,
      apiStaking: false,
      gasLimit: false,
    },
  },
  {
    id: '56',
    name: 'BNB Smart Chain Mainnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed1.binance.org/'],
    explorerUrl: ['https://bscscan.com/'],
    tokenImg: 'https://i.ibb.co/VMVS2Hc/binance.png',
    features: {
      spinValue: 0.00002,
      mintValue: 0.002,
      stakeValue: 0.002,
      apiStaking: false,
      gasLimit: false,
    },
  },

  // {
  //   id: '42766',
  //   name: 'ZKFair Mainnet',
  //   nativeCurrency: { name: 'USDC', symbol: 'USDC', decimals: 18 },
  //   rpcUrls: ['https://rpc.zkfair.io'],
  //   explorerUrl: 'https://scan.zkfair.io/',
  //   tokenImg: 'https://i.ibb.co/y5D8dJv/taiko.png',
  //   features: {
  //     spinValue: 0.1,
  //     mintValue: 1.5,
  //     stakeValue: 1.5,
  //     unStakeValue: 1.5,
  //     apiStaking: false,
  //   },
  //   contracts: {
  //     // Entrypass: new ethers.Contract(''),
  //     NFT: new ethers.Contract(
  //       '0x8A2E7143f1b153f454836c2fef5d53Fe6d8bB02B',
  //       zkfairStakeAbi
  //     ),
  //     Stake: new ethers.Contract(
  //       '0x8A2E7143f1b153f454836c2fef5d53Fe6d8bB02B',
  //       zkfairStakeAbi
  //     ),
  //     Token: new ethers.Contract(
  //       '0x8bD6f3bD448FcaD043c7b08FafEb3AB04126d9d4',
  //       tokenAbi
  //     ),
  //     Game: new ethers.Contract(
  //       '0xfcc11fCfbAeD8135C13AA2f7129712787b0618d6',
  //       mantaGameAbi
  //     ),
  //     Product: new ethers.Contract(
  //       '0x85523d5EdA897884432Eec14A71B153FbA6832D1',
  //       katlaProductAbi
  //     ),
  //     //Store: new ethers.Contract(''),
  //   },
  // },
];

export const chainIdToHex = (chainId: number | string) => {
  const hexString = chainId.toString(16);
  return '0x' + hexString;
};

// export const toastPromise = (promise, { pending, success, error }, options) => {
//   return new Promise((resolve) => {
//     const isStr = (value) => typeof value === 'string';

//     toast.promise(
//       promise,
//       {
//         pending: {
//           render: () => {
//             return isStr(pending) ? pending : pending();
//           },
//         },
//         success: {
//           render: ({ data }) => {
//             return isStr(success) ? success : success(data);
//           },
//         },
//         error: {
//           render: ({ data }) => {
//             return isStr(error) ? error : error(data);
//           },
//         },
//       },
//       options
//     );

//     promise.finally(resolve);
//   });
// };

export const handleTransactionError = (error: any) => {
  console.error(error);
  switch (error.code) {
    case errors.ACTION_REJECTED:
      toast.error('You have rejected the transaction');
      break;
    case errors.INSUFFICIENT_FUNDS:
      toast.error('Insufficient funds');
      break;
    case errors.NETWORK_ERROR:
      toast.error('Network error, please try again');
      break;
    case errors.UNPREDICTABLE_GAS_LIMIT:
      toast.error('Unpredictable gas price, please try again');
      break;
    default:
      toast.error('Error occurred while processing the transaction');
      break;
  }
};
